import { LoomToolTip } from "../../util/helper";

export const Prefix = ({title, loomLink, loomEmbededLink, loomButtonText, component}) => {
    return <div style={{display: 'flex', flexDirection: 'row'}}>
        <h2 style={{marginRight: 15}}> {title || 'No Title'} </h2>
        {!loomLink || !loomEmbededLink ? null : <LoomToolTip 
            title={loomButtonText || 'In depth use'}
            loomLink={loomLink}
            loomEmbededLink={loomEmbededLink}
        />}
        {component || null}
    </div>
};