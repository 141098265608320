export const addLocalItems = (localArr, serverArr) => {
    const serverIds = serverArr.map(item2=>item2.id);
    // const localIds = localArr.map(item=>item.id);
    localArr.filter(item=>!serverIds.includes(item.id)).forEach(item=>{
        if(!item.deleted) {
            serverArr.push(item);
        }
    });
    localArr.filter(item=>serverIds.includes(item.id)).forEach(item=>{
        const serverItem = serverArr.find(item2=>item2.id===item.id);
        const serverItemIndex = serverArr.findIndex(item2=>item2.id===item.id);
        if(item.lastUpdated > serverItem.lastUpdated) {
            if(item.deleted) {
                // serverArr = serverArr.filter(item2=>item2.id!==item.id);
                const index = serverArr.findIndex(item2=>item2.id===item.id);
                if(index !== -1) {
                    serverArr.splice(index, 1);
                }
            } else {
                serverArr[serverItemIndex] = item;
            }
        } else {
            if(serverItem.deleted) {
                // serverArr = serverArr.filter(item2=>item2.id!==item.id);
                const index = serverArr.findIndex(item2=>item2.id===item.id);
                if(index !== -1) {
                    serverArr.splice(index, 1);
                }
            }
        }
    });
}

export const forceArr = arr => !Array.isArray(arr) ? [] : arr;

export const commitMerge = (serverKey, keys) => {
    return [serverKey, undefined, undefined, true, undefined, 1, (local, server)=>{
        keys.forEach(key=>{
            local[key] = forceArr(local[key]);
            server[key] = forceArr(server[key]);
            addLocalItems(local[key], server[key]);
            server[key] = server[key].filter(item=>!item.deleted);
        });
        return server;
    }]
};

export const tryStringify = j => {
    try{return JSON.stringify(j, undefined, 2);}catch(e){}
    return `${j}`;
}

export const typeConvert = {string: 'Text', bool: 'Checkbox', number: 'Decimal Number', integer: 'Whole Number', percent: 'Percent', money: 'Money'};
