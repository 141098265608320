import { useState, useEffect, useMemo } from 'react'
import { getKey, serverStorage, useLocalAndServerCommitState, useLocalState, useSessionState } from '../../../../util/storage';
import { newID } from '../../../../util/util';

import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-markup';
import 'prismjs/components/prism-python';
import 'prismjs/themes/prism.css';
import SingleLineOfCode, { CodeDisplay } from '../../../../util/SingleLineOfCode/SingleLineOfCode';
import ClosableWrapper from '../../../ClosableWrapper/ClosableWrapper';

// Tables
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import { AllCommunityModule, ModuleRegistry } from 'ag-grid-community'; 

// Register all Community features
ModuleRegistry.registerModules([AllCommunityModule]);

const tryStringify = j => {
    console.log('tryStringify', j);
    try {
        return JSON.stringify(j);
    } catch (e) {
        return `${j}`;
    }
}

const copy = j => JSON.parse(JSON.stringify(j));

const getDataKey = (value, data) => {
    let key = null;
    Object.entries(data).forEach(([k, v]) => {
        if (v === value) key = k;
    });
    return key;
}

const typeCast = {
    make: 'string',
    model: 'string',
    price: 'number',
    electric: 'boolean',
    button: 'string',
};

const removeText = (txt, chars) => {
    let t = txt;
    chars.forEach(c => {
        t = t.replaceAll(c, '');
    });
    return t;
}

const forceNumber = n => {
    if (typeof n === 'number') return n;
    if (typeof n === 'string') {
        n = parseFloat(removeText(n, [' ', '$', ',']));
        if (isNaN(n)) return 0;
    }
    return n;
}
const forceString = s => {
    if (typeof s === 'string') return s;
    return `${s}`;
}
const forceBoolean = b => {
    if (typeof b === 'boolean') return b;
    if (typeof b === 'string') {
        if (b === 'true') return true;
        if (b === 'false') return false;
    }
    return !!b;
}

const GridExample = () => {
    // Row Data: The data to be displayed.
    const [rowData, setRowData] = useLocalState([
        { make: "Tesla", model: "Model Y", price: 64950, electric: true },
        { make: "Ford", model: "F-Series", price: 33850, electric: false },
        { make: "Toyota", model: "Corolla", price: 29600, electric: false },
    ], 'test-key-1');

    const CustomButtonComponent = (props) => {
        return <button onClick={() => window.alert('clicked') }>Push Me!</button>;
    };

    // Column Definitions: Defines the columns to be displayed.
    const [colDefs, setColDefs] = useState([
        { field: "make", filter: true, floatingFilter: true, editable: true, cellEditor: 'agSelectCellEditor', cellEditorParams: { values: ['Tesla', 'Ford', 'Toyota'], }, },
        { field: "model", editable: true },
        { field: "price", valueFormatter: p => '£' + p.value.toLocaleString(), editable: true },
        { headerName: "Make & Model", valueGetter: p => p?.data?.make + ' ' + p?.data?.model},
        { field: "electric", editable: true },
        { field: "button", cellRenderer: CustomButtonComponent },
    ]);

    // const rowSelection = useMemo(() => { 
    //     return {
    //         mode: 'multiRow',
    //     };
    // }, []);

    return (
        <div
            // define a height because the Data Grid will fill the size of the parent container
            style={{ height: 500 }}
        >
            <AgGridReact
                rowData={rowData}
                columnDefs={colDefs}
                // rowSelection={rowSelection}
                onCellValueChanged={(e, ...args)=>{
                    console.log('onCellValueChanged', e, args);
                    const {newValue} = e;
                    const key = getDataKey(e?.value, e?.data);
                    if(typeCast.hasOwnProperty(key)) {
                        if (typeCast[key] === 'number') e.data[key] = forceNumber(newValue);
                        if (typeCast[key] === 'integer') e.data[key] = parseInt(forceNumber(newValue));
                        if (typeCast[key] === 'boolean') e.data[key] = forceBoolean(newValue);
                        if (typeCast[key] === 'string') e.data[key] = forceString(newValue);
                        setRowData(prev=>{
                            prev[e?.rowIndex] = e?.data;
                            return copy(prev);
                        });
                        return;
                    }
                }}
            />
        </div>
    )
}


const alphebet = 'abcdefghijklmnopqrstuvwxyz';
const getNextLetter = letter => {
    const index = alphebet.indexOf(letter);
    if (index === -1 || index === alphebet.length - 1) return alphebet[0];
    return alphebet[index + 1];
}


const Test = ({refreshPermissions}) => {
    const [v, setV, commit, loading] = useLocalAndServerCommitState({v: 0, updated: Date.now()}, 'testing-v', undefined, undefined, false, undefined, 1, (local, server)=>{
        console.log('merge', local, server);
        if(local.updated > server.updated) {
            server.v = local.v;
            server.updated = local.updated;
        }
        return server;
    });
    return <div style={{}}>
        <GridExample/>
        {/* <ClosableWrapper 
            topRight={()=>console.log('top right')}
            topLeft={()=>console.log('top left')}
            bottomRight={()=>console.log('bottom right')}
            bottomLeft={()=>console.log('bottom left')}

            topLeftContent={<>&uarr;</>}
            topRightContent={<>&darr;</>}
            bottomLeftContent={<>&#8679;</>}
            bottomRightContent={<>&#8681;</>}
        >
            <div style={{backgroundColor: 'red', borderRadius: '5px', width: '500px', height: '500px'}}></div>
        </ClosableWrapper> */}
        <button onClick={()=>{
            setV(prev=>{
                prev.v++;
                prev.updated = Date.now();
                return copy(prev);
            });
        }}>Click me. {v.v}</button>
        <button onClick={()=>{
            commit();
        }}>commit</button>
    </div>
}

export default Test;





