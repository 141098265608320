import { useState, useEffect } from 'react';
import { v1 as uuidv1, v4 as uuidv4 } from 'uuid'

export const newID = () => `${uuidv4()}-${uuidv1()}`;

export const isAsync = func => func.toString().startsWith('async ');

export const isObject = (val) => (typeof val === 'object' && !Array.isArray(val) && val !== null)

export const uuid = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
};

export const forceNumber = (s, whole) => {
    console.log('forceNumber', whole, s);
    if(s === '') return s;
    if(s === '0') return s;
    try {
        while(s.split('.').length > 2) s = s.replace('.', '');
        const n = parseFloat(s.split('').filter(c=>('123456789' + (whole ? '' : '.')).includes(c)).join(''));
        console.log(n, s.split('').filter(c=>('123456789' + (whole ? '' : '.')).includes(c)).join(''))
        if(isNaN(n)) return '';
        return s;
    } catch(e) {
        console.log(e);
        return '';
    }
};

export const forceBool = s => {
    s = `${s}`.toLowerCase();
    if(s.includes('true')) return 'true';
    if(s.includes('false')) return 'false';
    return '';
}

export const serverStorage = {
    getItem: async key => {
        try {
            const response = await fetch('/get', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({key})
            });

            const res = await response.json();
            const value = res?.value;

            return value === undefined ? null : value;
        } catch (e) {
            return null;
        }
    },
    setItem: async (key, value) => {
        const response = await fetch('/set', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({key, value})
        });
        return await response.json();
    }
}

const merge = (o1, o2) => {
    Object.keys(o2)
    .forEach(k => {
        if(!(k in o1)) {
            console.log('merging', k);
            o1[k] = o2[k];
        }
    })
}


const useStorageState = (defaultValue, key, encode, decode, storage, mergeDefault, onLoad) => {
    const [value, setValue] = useState(() => {
        if(!isAsync(storage.getItem)) {
            const storedValue = storage.getItem(key);
            if (storedValue !== null) {
                const r = decode ? decode(storedValue) : JSON.parse(storedValue);
                if(mergeDefault) merge(r, defaultValue);
                return r;
            }
            return defaultValue;
        } else {
            storage.getItem(key).then(storedValue => {
                if (storedValue !== null) {
                    const value = decode ? decode(storedValue) : JSON.parse(storedValue);
                    if(mergeDefault) merge(value, defaultValue);
                    setValue(value);
                    if(onLoad) onLoad();
                }
            });
            return defaultValue;
        }
    });

    const overrideSetValue = newValue => {
        if(typeof newValue === 'function') newValue = newValue(value)
        setValue(newValue);
        // storage.setItem(key, encode ? encode(value) : JSON.stringify(value));
    };

    useEffect(() => {
        const func = () => {
            if(isAsync(storage.setItem)) {
                storage.setItem(key, encode ? encode(value) : JSON.stringify(value)).then(() => null);
            }
            else {
                storage.setItem(key, encode ? encode(value) : JSON.stringify(value));
            }
        }

        const t = setTimeout(func, 1000);

        return () => clearTimeout(t);
    }, [key, value, encode, storage]);

    return [value, overrideSetValue];
};


const useSessionState = (defaultValue, key, encode, decode) => {
    return useStorageState(defaultValue, key, encode, decode, sessionStorage);
};


const useLocalState = (defaultValue, key, encode, decode) => {
    return useStorageState(defaultValue, key, encode, decode, localStorage);
};


const useServerState = (defaultValue, key, encode, decode, mergeDefault, onLoad) => {
    return useStorageState(defaultValue, key, encode, decode, serverStorage, mergeDefault, onLoad);
};


export {useSessionState, useLocalState, useServerState};

