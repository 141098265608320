import { useState } from 'react';
import { TextField, Switch, FormGroup, FormControl, FormControlLabel, Box, Modal } from '@mui/material';



export const LoomToolTip = ({title='How to use the tool', loomLink, loomEmbededLink}) => {
    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const openLoom = () => {
        window.open(
            loomLink,
            '_blank'
        );
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '75%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    return <>
        {/* <button className='btn' onClick={handleFetchAccounts}>
            Go Through Accounts
        </button> */}
        <button style={{margin: 15}} className='btn' onClick={handleOpen}>
            {title}
        </button>
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
        <Box sx={style}>
            <div style={{position: 'relative', 'paddingBottom': '62.5%', height: 0}}>
                <iframe 
                    src={loomEmbededLink} 
                    frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen 
                    style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}}>
                </iframe>
            </div>
            <button style={{margin: 15}} className='btn' onClick={openLoom}>
                or Open in Loom
            </button>
        </Box>
        </Modal>
    </>
}