import React, { useState } from 'react';
import './ClosableWrapper.css'; // Make sure to import the CSS file

function ClosableWrapper({ 
  children, onClose, topRight, topLeft, bottomLeft, bottomRight,

  topRightContent, topLeftContent, bottomLeftContent, bottomRightContent,
}) {
  const [isHovered, setIsHovered] = useState(false);
  const [on, setOn] = useState([false, false, false, false]);

  const handleClick = (func, i) => {
    if (on[i]) {
      setOn(false);
      if(func) func()
      else if(onClose) onClose();
    } else {
      // setOn(true);
      const newOn = [...on];
      newOn[i] = true;
      setOn(newOn);
      // setTimeout(() => setOn(false), 1500);
      setTimeout(() => {
        const newOn = [...on];
        newOn[i] = false;
        setOn(newOn);
      }, 1500);
    }
  };

  return (
    <div 
      className="closable-wrapper" 
      onMouseEnter={() => setIsHovered(true)} 
      onMouseLeave={() => setIsHovered(false)}
    >
      {isHovered && (onClose || topRight) && (
        <button className={on[0] ? 'close-button-red' : 'close-button'} onClick={() => handleClick(onClose || topRight, 0)}>
          {topRightContent ? topRightContent : <>&times;</>}
        </button>
      )}
      {isHovered && topLeft && (
        <button className={on[1] ? 'close-button-red-top-left' : 'close-button-top-left'} onClick={() => handleClick(topLeft, 1)}>
          {topLeftContent ? topLeftContent : <>&times;</>}
        </button>
      )}
      {isHovered && bottomLeft && (
        <button className={on[2] ? 'close-button-red-bottom-left' : 'close-button-bottom-left'} onClick={() => handleClick(bottomLeft, 2)}>
          {bottomLeftContent ? bottomLeftContent : <>&times;</>}
        </button>
      )}
      {isHovered && bottomRight && (
        <button className={on[3] ? 'close-button-red-bottom-right' : 'close-button-bottom-right'} onClick={() => handleClick(bottomRight, 3)}>
          {bottomRightContent ? bottomRightContent : <>&times;</>}
        </button>
      )}
      {/* <div className="content"> */}
        {children}
      {/* </div> */}
    </div>
  );
}

export default ClosableWrapper;
