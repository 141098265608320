import { useState, useEffect, useRef } from 'react';
import { isObject, newID } from './util';
import { listen, read, write } from './Firebase/Firestore';

export const isAsync = func => func.toString().startsWith('async ');

const isInt = n => {
    try {
        return !isNaN(parseInt(n)) && isFinite(n);
    } catch(e) {
        return false;
    }
}

export const url = 'http://5.161.234.184:5430';

export const isFile = input => 'File' in window && input instanceof File;
export const isBlob = input => 'Blob' in window && input instanceof Blob;


const DEVELOPMENT = window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1" || window.location.hostname === "";

const BUCKET_URL = !DEVELOPMENT ? '' : '';//'https://dashboard.junglr.app'

const persistantFetch = async (...args) => {
    let tries = 3;
    let response
    while (tries > 0) {
        response = await fetch(...args);
        if(response.status === 405) {
            tries--;
            await new Promise(resolve => setTimeout(resolve, 500));
        } else break;
    }
    return response;
}

export const api = async (endpoint, body) => {
    const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(body),
    });
    const json = await response.json();
    return json;
}

export const bucket = {
    set: async (key, file) => {
        if(typeof file === 'string') {
            file = new File([file], 'file.txt', { type: 'text/plain' });
        } else if(isObject(file) && !isFile(file)) {
            console.log('file is object', file);
            file = new File([JSON.stringify(file)], 'file.json', { type: 'application/json' });
        }
        const formData = new FormData();
        formData.append('file', file);
        const response = await persistantFetch(`${BUCKET_URL}/upload/${key}`, {
            method: 'POST',
            body: formData
        });
        return await response.json();
    },
    get: async key => {
        const response = await persistantFetch(`${BUCKET_URL}/download/${key}`);
        const blob = await response.blob();
        if(blob.type === 'text/plain') {
            return await blob.text();
        } else if(blob.type === 'application/json') {
            return JSON.parse(await blob.text());
        }
        return blob;
    },
    del: async key => {
        const response = await persistantFetch(`${BUCKET_URL}/delete/${key}`, {
            method: 'DELETE'
        });
        return await response.json();
    },
    uploadForProcess: async (file) => {
        // if(typeof file === 'string') {
        //     file = new File([file], 'file.txt', { type: 'text/plain' });
        // } else if(isObject(file) && !isFile(file)) {
        //     console.log('file is object', file);
        //     file = new File([JSON.stringify(file)], 'file.json', { type: 'application/json' });
        // }
        if(!isFile(file)) throw new Error('File is not a file');
        const formData = new FormData();
        formData.append('file', file);
        const response = await persistantFetch(`/bucket/upload`, {
            method: 'POST',
            body: formData
        });
        const r = await response.json();
        if(r?.error) {
            console.error(r?.error);
            return null;
        }
        return r?.key;
    },
    fileToChunks: async (file, chunkSize=1024*1024*10) => {
        const chunks = [];
        const totalChunks = Math.ceil(file.size / chunkSize);
        for(let i = 0; i < totalChunks; i++) {
            const start = i * chunkSize;
            const end = Math.min(start + chunkSize, file.size);
            const chunk = file.slice(start, end);
            chunks.push(chunk);
        }
        return chunks;
    },
    uploadFileForProcess: async (file, onProgress) => {
        const NILL_TOKEN = '__nothing__';
        let key = NILL_TOKEN;
        let finished = false;
        try {
            const chunks = await bucket.fileToChunks(file);
            const totalChunks = chunks.length;
            let uploadedChunks = 0;
            for(let i = 0; i < totalChunks; i++) {
                const chunk = chunks[i];
                const formData = new FormData();
                formData.append('file', chunk);
                let r;
                if(i === 0) {
                    const r = await api(`/bucket/append/start`, {fileName: file.name});
                    key = r?.key;
                    if(!key) {
                        console.error(r?.error);
                        return null;
                    }
                }
                const endpoint = `/bucket/append/append?key=${key.replace('/', '%2F')}`;
                const response = await fetch(endpoint, {method: 'POST', body: formData, credentials: 'include'});
                r = await response.json();
                
                if(r?.error) {
                    console.error(r?.error);
                    return null;
                }
                uploadedChunks++;
                if(onProgress) onProgress(uploadedChunks / totalChunks);
            }
            const endpoint = `/bucket/append/finish?key=${key.replace('/', '%2F')}`;
            await api(endpoint, {});
            // r = await response.json();
            finished = true;
            return key;
        } finally {
            if(!finished) {
                const endpoint = `/bucket/append/fail?key=${key.replace('/', '%2F')}`;
                await api(endpoint, {});
            }
        }
    }
}


export const serverStorage = {
    rateLimitSeconds: 2,
    lastTime: Date.now(),
    rateLimit: async seconds => {
        const now = Date.now();
        const diff = now - serverStorage.lastTime;
        if(diff < seconds * 1000) {
            await new Promise(resolve=>setTimeout(resolve, seconds * 1000 - diff));
        }
        serverStorage.lastTime = Date.now();
    },
    getItem: async key => {
        await serverStorage.rateLimit(serverStorage.rateLimitSeconds);

        return await read(key);
        // try {
            const response = await persistantFetch(`${BUCKET_URL}/bucket/get`, {  // `${url}/get`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({key})
            });

            if(response.status !== 200) throw new Error(await response.text());

            const res = await response.json();
            const value = res?.value;

            return value === undefined ? null : value;
        // } catch (e) {
        //     return null;
        // }
    },
    setItem: async (key, value) => {
        await serverStorage.rateLimit(serverStorage.rateLimitSeconds);

        return await write(key, value);
        const response = await persistantFetch(`${BUCKET_URL}/bucket/set`, {  // `${url}/set`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({key, value})
        });
        return await response.json();
    },
    listen: (key, callback) => {
        // console.log('listen', JSON.stringify(key))
        serverStorage.getItem(key).then(v=>callback ? callback(v) : null);

        return listen(key, callback);
        
        const evtSource = new EventSource(`${BUCKET_URL}/bucket/listen/${key}`);  // (`${url}/listen/${key}`);

        evtSource.onmessage = (event) => {
            console.log('event', event);
            try {
                const data = JSON.parse(event.data);
                if(data?.value) {
                    console.log('dat', data)
                    callback(data.value);
                } else {
                    // callback(null);
                    console.error('No value', data);
                }
            } catch(e) {
                console.error(e);
            }
        };

        return ()=>{
            evtSource.close();
        }
    },
}

const merge = (o1, o2, deep) => {
    if(deep) {
        if(Array.isArray(o1) && Array.isArray(o2)) {
            let isDeep = o1.some(v=>typeof v === 'object' && v !== null) && o2.some(v=>typeof v === 'object' && v !== null);
            if(!isDeep) return merge(o1, o2, false);
            const additions = [];
            for (let i = 0; i < Math.max(o1.length, o2.length); i++) {
                if(i >= o2.length) break;
                if(i >= o1.length) o1.push(o2[i]);
                else {
                    if(JSON.stringify(o1[i]) !== JSON.stringify(o2[i])) {
                        additions.push(merge(o1[i], o2[i], true));
                    }
                }
            }
            additions.forEach(v=>{
                o1.push(v);
            })
        } 
        if(isObject(o1) && isObject(o2)) {
            Object.keys(o2).forEach(k=>{
                if(!(k in o1)) {
                    o1[k] = o2[k];
                } else {
                    o1[k] = merge(o1[k], o2[k], true);
                }
            })
        };
        return o1;
    }
    if (Array.isArray(o1) && Array.isArray(o2)) {
        try{
            const clone = o1.map(v=>JSON.stringify(v));
            o2.forEach(v=>{
                if(!clone.includes(JSON.stringify(v))) {
                    o1.push(v);
                }
            })
        }
        catch(e){}
        return o1;
    }
    Object.keys(o2)
    .forEach(k => {
        if(!(k in o1)) {
            o1[k] = o2[k];
        }
    })
}


export const getKey = (key, version) => `${key}-v${version}`;


const useStorageState = (defaultValue, key, encode, decode, storage, mergeDefault, onLoad, version) => {
    const reversable = isInt(version);
    const ogKey = key;

    const [value, setValue] = useState(() => {
        if(!isAsync(storage.getItem)) {
            let storedValue = storage.getItem(getKey(key, version));
            if (storedValue === null) {
                if(reversable && version && mergeDefault) {
                    try{storedValue = storage.getItem(`${ogKey}-v${parseInt(version) - 1}`);}
                    catch(e){}
                }
            }
            if (storedValue !== null) {
                const r = decode ? decode(storedValue) : JSON.parse(storedValue);
                if(mergeDefault) merge(r, defaultValue);
                if(onLoad) onLoad(r);
                return r;
            }
            if(onLoad) onLoad(defaultValue);
            return defaultValue;
        } else {
            storage.getItem(getKey(key, version)).then(async storedValue => {
                if (storedValue === null) {
                    if(reversable && version && mergeDefault) {
                        try{storedValue = await storage.getItem(`${ogKey}-v${parseInt(version) - 1}`);}
                        catch(e){}
                    }
                }
                if (storedValue !== null) {
                    const value = decode ? decode(storedValue) : JSON.parse(storedValue);
                    if(mergeDefault) merge(value, defaultValue);
                    setValue(value);
                    if(onLoad) onLoad(value);
                }
            });
            if(onLoad) onLoad(defaultValue);
            return defaultValue;
        }
    });

    const overrideSetValue = newValue => {
        if(typeof newValue === 'function') newValue = newValue(value);
        setValue(newValue);
        // storage.setItem(getKey(key, version), encode ? encode(value) : JSON.stringify(value));
    };

    useEffect(() => {
        const func = () => {
            if(isAsync(storage.setItem)) {
                storage.setItem(getKey(key, version), encode ? encode(value) : JSON.stringify(value)).then(() => null);
            }
            else {
                storage.setItem(getKey(key, version), encode ? encode(value) : JSON.stringify(value));
            }
        }

        const t = setTimeout(func, 3000);

        return () => clearTimeout(t);
    }, [key, value, encode, storage]);

    return [value, overrideSetValue];
};


const useLayeredStorageState = (asyncStorage, defaultValue, key, encode, decode, storage, mergeDefault, onLoad, version) => {
    const [value, setValue] = useStorageState(defaultValue, key, encode, decode, storage, mergeDefault, onLoad, version);
    const firstLoadRef = useRef(false);

    useEffect(() => {
        const callback = async storedValue=> {
            if (storedValue === null) {
                if(isInt(version) && version && mergeDefault) {
                    try{storedValue = await asyncStorage.getItem(getKey(key, version - 1));}
                    catch(e){}
                }
            }
            if (storedValue !== null) {
                const value = decode ? decode(storedValue) : JSON.parse(storedValue);
                if(mergeDefault) merge(value, defaultValue);
                setValue(value);
                if(onLoad) onLoad();
            }
            firstLoadRef.current = true;
        };
        const unsub = asyncStorage.listen(getKey(key, version), callback)
        
        return () => unsub();
    }, []);

    useEffect(() => {
        if(firstLoadRef.current) {
            const func = () => {
                asyncStorage.setItem(getKey(key, version), encode ? encode(value) : JSON.stringify(value));
            }

            const t = setTimeout(func, 5000);

            return () => clearTimeout(t);
        }
    }, [value]);

    return [value, setValue];
}


const useLayeredCommitState = (asyncStorage, defaultValue, key, encode, decode, storage, mergeDefault, onLoad, version, specialMerge) => {
    const [value, setValue] = useStorageState(defaultValue, key, encode, decode, storage, mergeDefault, onLoad, version);
    const currentValue = useRef(value);
    const overrideSetValue = (newValue, commitValue) => {
        if(typeof newValue === 'function') {
            newValue = newValue(value);
        }
        if(commitValue) commit(newValue);
        setValue(newValue);
        currentValue.current = newValue;
    };
    const firstLoadRef = useRef(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        const callback = async storedValue=> {
            if (storedValue === null) {
                if(isInt(version) && version && mergeDefault) {
                    try{storedValue = await asyncStorage.getItem(getKey(key, version - 1));}
                    catch(e){}
                }
            }
            if (storedValue !== null) {
                const value = decode ? decode(storedValue) : JSON.parse(storedValue);
                if(mergeDefault) {
                    merge(value, defaultValue);
                } 
                if(specialMerge) currentValue.current = specialMerge(currentValue.current, value);
                setValue(value);
                if(onLoad) onLoad();
            }
            firstLoadRef.current = true;
            setLoading(false);
        };

        const unsub = asyncStorage.listen(getKey(key, version), callback)
        
        return () => unsub();
    }, []);

    const commit = async (overrideValue) => {
        if(firstLoadRef.current) {
            if(!overrideValue) overrideValue = value;
            await asyncStorage.setItem(getKey(key, version), encode ? encode(overrideValue) : JSON.stringify(overrideValue));
        }
    };

    return [value, overrideSetValue, commit, loading];
}


const useSessionState = (defaultValue, key, encode, decode) => {
    return useStorageState(defaultValue, key, encode, decode, sessionStorage);
};


const useLocalState = (defaultValue, key, encode, decode, mergeDefault, onLoad, version) => {
    return useStorageState(defaultValue, key, encode, decode, localStorage, mergeDefault, onLoad, version);
};


const useServerState = (defaultValue, key, encode, decode, mergeDefault, onLoad, version) => {
    return useStorageState(defaultValue, key, encode, decode, serverStorage, mergeDefault, onLoad, version);
};

const useLocalAndServerState = (defaultValue, key, encode, decode, mergeDefault, onLoad, version) => {
    return useLayeredStorageState(serverStorage, defaultValue, key, encode, decode, localStorage, mergeDefault, onLoad, version);
}

const useLocalAndServerCommitState = (defaultValue, key, encode, decode, mergeDefault, onLoad, version, specialMerge) => {
    return useLayeredCommitState(serverStorage, defaultValue, key, encode, decode, localStorage, mergeDefault, onLoad, version, specialMerge);
}

export {useSessionState, useLocalState, useServerState, useLocalAndServerState, useLocalAndServerCommitState};

export const standardType = v => ['string', 'number', 'boolean'].includes(typeof v);

export const hundredYearsAgo = () => {
    const now = Date.now();
    const decade = 10 * 365 * 24 * 60 * 60 * 1000;
    return now - (decade * 10);
}

export const objectOperations = {
    create: (key, value) => ({id: newID(), updated: 0, deleted: false, [key]: value, __key: key, __value: value, value}),
    multiCreate: obj => {
        const o = Object.keys(obj).reduce((acc, key) => ({...acc, [key]: obj[key]}), {id: newID(), updated: 0, deleted: false});
        o.__obj = obj;
        return o;
    },
    update: (obj, key, value) => ({...obj, updated: 0, [key]: value, __value: value}),
    delete: obj => ({...obj, updated: 0, deleted: true}),
    isObject: obj => obj?.id && obj?.updated && obj?.deleted !== undefined,
    addValue: (obj, key, value) => {obj[key] = objectOperations.create(key, value);},
    append: (arr, key, value) => arr.push(objectOperations.create(key, value)),
    
    remove: (arr, index) => arr.splice(index, 1),
    removeValue: (arr, value) => arr.splice(arr.findIndex(obj => obj.value === value), 1),

    mergeObjects: (returnObj, otherObj) => {
        const keys = getUniqueValues(Object.keys(returnObj), Object.keys(otherObj));
        const best = getBest(returnObj, otherObj);
        const notBest = best === otherObj ? returnObj : otherObj;
        for(let key of keys) {
            if(key in best) returnObj[key] = best[key];
            else returnObj = notBest[key];
        }
        returnObj.updated = best.updated;
        return returnObj;
    },
    updateObject: (returnObj, otherObj) => {
        if(standardType(returnObj) && standardType(otherObj)) return returnObj;
        if(Array.isArray(returnObj) && Array.isArray(otherObj)) return mergeArrs(returnObj, otherObj);
        if(!objectOperations.isObject(returnObj) || !objectOperations.isObject(otherObj)) return returnObj;
        const keys = getUniqueValues(Object.keys(returnObj), Object.keys(otherObj));
        const best = getBest(returnObj, otherObj);
        const notBest = best === otherObj ? returnObj : otherObj;
        for(let key of keys) {
            if(key in best && key in notBest) {
                if(objectOperations.isObject(best[key]) && objectOperations.isObject(notBest[key])) {
                    returnObj[key] = objectOperations.updateObject(returnObj[key], otherObj[key])
                }
                else if(standardType(best[key]) && standardType(notBest[key])) returnObj[key] = best[key];
                else if(Array.isArray(best[key]) && Array.isArray(notBest[key])) returnObj[key] = mergeArrs(best[key], notBest[key]);
            } else returnObj[key] = best[key] || notBest[key];
        }
        if(best.updated) returnObj.updated = best.updated;
        return returnObj;
    }
}

export const getBest = (a, b) => {
    if (!a) return b;
    if (!b) return a;
    return a.updated > b.updated ? a : b;
}

export const forceArr = arr => !Array.isArray(arr) ? [] : arr;
export const filterArr = item => !item?.deleted;

export const getUniqueValues = (arr1, arr2) => {
    const arr = [...arr1, ...arr2];
    const unique = arr.filter((item, index) => arr.indexOf(item) === index);
    return unique;
}
export const mergeArrs = (arr1, arr2) => {
    arr1 = forceArr(arr1);
    arr2 = forceArr(arr2);
    const ids = getUniqueValues(arr1.map(o=>o?.id), arr2.map(o=>o?.id));
    return ids.map(id => {
        const a = arr1.find(o => o.id === id);
        const b = arr2.find(o => o.id === id);
        if(standardType(a) && standardType(b)) return a;
        return getBest(a, b);
    }).filter(filterArr);
}

export const copy = j => JSON.parse(JSON.stringify(j));


const DARK_MODE_EVENT = 'darkModeChange';
const STORAGE_KEY = 'darkMode';

export const useDarkMode = () => {
  const [isDark, setIsDark] = useState(() => {
    const saved = localStorage.getItem(STORAGE_KEY);
    return saved ? JSON.parse(saved) : false;
  });

  useEffect(() => {
    const handleDarkModeChange = (e) => {
      setIsDark(e.detail);
    };

    window.addEventListener(DARK_MODE_EVENT, handleDarkModeChange);
    return () => window.removeEventListener(DARK_MODE_EVENT, handleDarkModeChange);
  }, []);

  const updateDarkMode = (value) => {
    if(typeof value === 'function') value = value(isDark);
    if(typeof value !== 'boolean') value = Boolean(value);
    setIsDark(value);
    localStorage.setItem(STORAGE_KEY, JSON.stringify(value));
    window.dispatchEvent(new CustomEvent(DARK_MODE_EVENT, { detail: value }));
    document.documentElement.classList.toggle('dark', value);
  };

  return [isDark, updateDarkMode];
};