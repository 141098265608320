import { useState, useEffect, useRef } from "react";

import { Autocomplete, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fab, IconButton, Paper, TextField, Typography } from "@mui/material";
import { api, bucket, copy, getBest, isFile, mergeArrs, objectOperations, useDarkMode, useLocalAndServerCommitState, useLocalState, useSessionState } from "../../../../util/storage"
import { MultiSelectButton } from "./MultiSelectButton";
import { Table } from "./Table"
import { addLocalItems, commitMerge, forceArr, tryStringify, typeConvert } from "./util";

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { toast } from "react-toastify";
// import { typeConvert } from "./BidSheet";
import { forceBool, forceNumber, newID } from "../../../../util/util";
import { CloudDone, CloudSync, PendingActions, WorkHistory } from "@mui/icons-material";
import { Plus } from "lucide-react";
import { FileUpload } from "../../../FileUpload/FileUpload";
import { getSheets } from "../JunglrBulk/JunglrBulk";
import { info } from "sass";

const URL = '';//'http://localhost:8080';

// const api = async (endpoint, body) => {
//     const response = await fetch(endpoint, {
//         method: 'POST',
//         headers: {
//             'Content-Type': 'application/json'
//         },
//         body: JSON.stringify(body),
//     });
//     const json = await response.json();
//     return json;
// }

const clamp = (n, min, max) => Math.min(Math.max(n, min), max);

const columns = [
    { value: 'make', header: 'Make', type: 'string', editable: true },
];
const table = [
    { make: "Tesla", model: "Model Y", price: 64950, electric: true },
    { make: "Ford", model: "F-Series", price: 33850, electric: false },
    { make: "Toyota", model: "Corolla", price: 29600, electric: false },
];

const configTableOptions = {
    toggle: '🧮',
    downloadExcel: 'Download Excel',
    uploadExcel: 'Upload Excel',
    createGoogleSheet: 'Create Google Sheet',
    pullGoogleSheet: 'Pull Google Sheet',
};

const bidTableOptions = {
    inertBids: 'Insert Bids',
    downloadExcel: 'Download Excel',
    uploadExcel: 'Upload Excel',
    createGoogleSheet: 'Create Google Sheet',
    pullGoogleSheet: 'Pull Google Sheet',
    getFromAmazonApi: 'Get from Amazon API',
    getFromDatabase: 'Get from Database',
};

const finishedOptions = {
    uploadToAmazon: 'Upload to Amazon',
    downloadBulkExcel: 'Download Bulk Excel',
    createGoogleSheet: 'Create Bulk Google Sheets',
};


const notNumber = (s, whole) => {
    if(typeof s === 'number') return false;
    if(typeof s !== 'string') return true;
    return isNaN((whole ? parseInt : parseFloat)(s));
}
const formatNumber = (s, formater, whole) => {
    if(notNumber(s, whole)) return '';
    if(typeof s === 'number') return formater(s);
    if(typeof s !== 'string') return '';
    return formater((whole ? parseInt : parseFloat)(s));
}
const formaters = {
    [typeConvert.percent]: p=>formatNumber(p.value, n=>`${(n * 100.).toLocaleString()}%`),
    [typeConvert.money]: p=>formatNumber(p.value, n=>('$' + n.toLocaleString())),
    [typeConvert.integer]: p=>formatNumber(p.value, n=>n.toLocaleString()),
    [typeConvert.number]: p=>formatNumber(p.value, n=>n.toLocaleString()),
};

const ConfigTable = ({sessionId, session}) => {
    const tableRef = useRef();

    const [cols, setCols] = useState({ value: 'placeholder', header: 'Placeholder', type: 'string', editable: true });
    const [data, setData] = useState([]);
    const dataRef = useRef({
        data: null,
        loadingTable: false,
        savingTimeout: null,
    }).current;
    
    const [info, setInfo] = useState({
        loading: true,
        saving: false,
    })

    useEffect(() => {
        if(!dataRef.loadingTable) toast.promise(getTable(), {
            pending: 'Loading...',
            success: 'Loaded',
            error: 'Error',
        })
    }, []);

    
    const getTable = async () => {
        dataRef.loadingTable = true;
        setInfo(prev=>({...prev, loading: true}));
        try {
            const newTable = (await api('/get-session-config-table', {sessionId}))?.table;
            const newColumns = session.config.configMap.map(col=>({
                field: col.value,
                headerName: (()=>{console.log('h', col.header);return col.header})(),
                type: {'Text': 'string', 'Checkbox': 'boolean', 'Decimal Number': 'number', 'Whole Number': 'number', 'Percent': 'number', 'Money': 'number'}[col.type || 'Decimal Number'],
                editable: !['entity_level', 'entity_id'].includes(col.value) ? true : (params => params.data.entity_level !== 'Account'),
                // valueParser: params => (col.type === typeConvert.integer && params.newValue ? parseInt(params.newValue) : params.newValue)
                ...(!(col.type in formaters) ? {} : {valueFormatter: formaters[col.type]}),
            }));
            console.log(1, newTable, newColumns)
            setCols(newColumns);
            dataRef.data = newTable;
            setData(newTable);
        }
        catch(e) {
            console.error(e);
            throw e;
        }
        finally {
            dataRef.loadingTable = false;
            setInfo(prev=>({...prev, loading: false}));
        }
    }

    const _onChange = ({index, key, value, data, rawValue}) => setData(prev=>{prev[index] = data; dataRef.data = copy(prev); return dataRef.data;});
    const onChange = (...args) => {_onChange(...args);save();}

    const savePromise = async (overrideData) => {
        const table = overrideData ? overrideData : dataRef.data;
        const res = await api('/upload-session-config-table', {sessionId, table});
        if(res?.status !== 'error') return;
        console.error(res?.error);
        // throw new Error(res?.error);
        toast.error(res?.error);
    };

    const save = (overrideData) =>{
        const _save = () => {
            dataRef.savingTimeout = null;
            savePromise(overrideData)
            .finally(()=>{if(!dataRef.savingTimeout) setInfo(prev=>({...prev, saving: false}));})
        }
        dataRef.savingTimeout = setTimeout(_save, 1500);
        setInfo(prev=>({...prev, saving: true}));
        // toast.promise(promise(), {
        //     pending: 'Saving...',
        //     success: 'Saved',
        //     error: 'Save Failed'
        // });
    }

    if(info.loading) return <div>Loading...</div>;

    return <>
        <br/>
        <Fab style={{marginTop: 15, marginBottom: 15, marginRight: 15, zIndex: 2}} size='small' onClick={()=>{
            const row = copy(data[0]);
            row.id = `a${Date.now()}`;
            row.entity_level = '';
            row.entity_id = '';
            setData(prev=>{
                const n = [...prev, row];
                dataRef.data = n;
                return n;
            });
            // setTimeout(()=>save(), 500);
            save();
            // tableRef.current.add([row])
        }}> <Plus/> </Fab>
        {/* <button onClick={save}>save</button> */}
        <IconButton onClick={()=>toast.promise(savePromise(), {
            pending: 'Saving...',
            success: 'Saved',
            error: 'Save Failed'
        })}>{info.saving ? <CloudSync/> : <CloudDone/>}</IconButton>
        <Table ref={tableRef} rawColumns columns={cols} table={data} onChange={onChange}/>
    </>;
}


const BidTable = ({sessionId, session}) => {
    const [cols, setCols] = useState({ value: 'placeholder', header: 'Placeholder', type: 'string', editable: true });
    const [data, setData] = useState([]);

    const [info, setInfo] = useState({
        loaded: false,
        saving: false,
    })

    const centerStyle = {overflow: 'auto', margin: 0,  padding: 0, width: '100%', height: '100%', whiteSpace: 'pre-wrap', display: 'flex', justifyContent: 'center', alignItems: 'center'};
    
    const columns = [
        { field: 'make', header: 'Make', type: 'string', editable: true, cellRenderer: ({value, data, ...rest}) => <pre style={centerStyle}>{tryStringify(value)}</pre> },
    ];

    const table = [
        { make: "Tesla", model: "Model Y", price: 64950, electric: true },
        { make: "Ford", model: "F-Series", price: 33850, electric: false },
        { make: "Toyota", model: "Corolla", price: 29600, electric: false },
    ];
    
    // const typeConvert = {string: 'Text', bool: 'Checkbox', number: 'Decimal Number', integer: 'Whole Number'};
    // 'text', 'number', 'boolean', 'date', 'dateString' and 'object'

    const fetchTable = async () => {
        
        // dataRef.loadingTable = true;
        // setInfo(prev=>({...prev, loading: true}));
        // try {
        const promise = async () => {
            setInfo(prev=>({...prev, loaded: false}));
            try {
                const tableConfig = session.tables.sort((a, b)=>b.epoch - a.epoch)[0];
                if(!tableConfig?.id) throw new Error('No table config found');
                const res = await api('/get-session-bid-table', {sessionId, tableId: tableConfig.id});
                if(res?.status === 'error') {
                    console.error(res?.error);
                    throw new Error(res?.error);
                }
                const newTable = res?.table;
                console.log('bid table', session.config.tableMap, session.config, session)
                const getHeader = col => {
                    const headers = col.headers;
                    if(Array.isArray(headers) && headers.length > 0) {
                        if(headers.length > 1) return headers[1];
                        return headers[0];
                    }
                    return col.value;
                };
                const getType = col => typeConvert[col?.type || col?._type];
                const newColumns = (session.config.tableMap || session.config.table_map).map(col=>({
                    field: col.value,
                    headerName: (()=>{console.log('h', col.headers);return getHeader(col)})(),
                    type: {'Text': 'string', 'Checkbox': 'boolean', 'Decimal Number': 'number', 'Whole Number': 'number', 'Percent': 'number', 'Money': 'number'}[getType(col) || 'Decimal Number'],
                    editable: false,  // !['entity_level', 'entity_id'].includes(col.value) ? true : (params => params.data.entity_level !== 'Account'),
                    filter: true, floatingFilter: true,
                    // valueParser: params => (col.type === typeConvert.integer && params.newValue ? parseInt(params.newValue) : params.newValue)
                    ...(!(getType(col) in formaters) ? {} : {valueFormatter: formaters[getType(col)]}),
                }));
                if(session?.config?.usePossibleBid) {
                    newColumns.push({
                        field: 'possible_bid',
                        headerName: 'Possible Bid',
                        type: 'number',
                        editable: false, filter: true, floatingFilter: true,
                        valueFormatter: formaters[typeConvert.money],
                    });
                }
                if(session?.config?.includeAsins) {
                    newColumns.unshift({
                        field: 'asins',
                        headerName: 'ASINs',
                        type: 'string',
                        editable: false, filter: true, floatingFilter: true,
                    });
                }
                if(session?.config?.getEntityId) {
                    newColumns.unshift({//.splice(2, 0, {
                        field: 'entity_id',
                        headerName: 'Entity ID',
                        type: 'string',
                        editable: false, filter: true, floatingFilter: true,
                    });
                }
                
                console.log('bid table', newTable, newColumns)
                setCols(newColumns);
                // dataRef.data = newTable;
                setData(newTable);
            } catch(e) {
                console.error(e);
                throw e;
            }
            finally {
                // dataRef.loadingTable = false;
                setInfo(prev=>({...prev, loaded: true}));
            }
        };
        // }
        // catch(e) {
        //     console.error(e);
        //     throw e;
        // }
        // finally {
        //     dataRef.loadingTable = false;
        //     setInfo(prev=>({...prev, loading: false}));
        // }
        toast.promise(promise(), {
            pending: 'Loading...',
            success: 'Loaded',
            error: 'Error',
        });
    }

    if(!session?.tables?.length) return null;

    if(!info.loaded) return <button onClick={fetchTable}>fetch</button>;
    
    return <>
        <button onClick={fetchTable}>fetch</button>
        {/* <Table rawColumns columns={columns} table={table}/> */}
        <Table enableCellTextSelection={true} rawColumns columns={cols} table={data}/>
        {/* <pre>
            {tryStringify({cols, data})}
        </pre> */}
    </>;
}

function CircularProgressWithLabel(props) {
    return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress variant="determinate" {...props} />
        <Box
        sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        }}
        >
        <Typography
            variant="caption"
            component="div"
            sx={{ color: 'text.secondary' }}
        >
            {`${Math.round(props.value)}%`}
        </Typography>
        </Box>
    </Box>
    );
}

const StatusTable = ({sessionId, session}) => {
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    
    const centerStyle = {overflow: 'auto', margin: 0,  padding: 0, width: '100%', height: '100%', whiteSpace: 'pre-wrap', display: 'flex', justifyContent: 'center', alignItems: 'center'};
    
    const columns = [
        { field: 'title', header: 'Title' },
        // { field: 'desc', header: 'Desc.', cellRenderer: ({value, data, ...rest}) => <pre style={centerStyle}>{tryStringify(value)}</pre> },
        { field: 'status', header: 'Status' },
        { field: 'progress', header: 'Progress', cellRenderer: ({value, data, ...rest}) => <CircularProgressWithLabel variant="determinate" value={typeof value !== 'number' ? 0 : clamp(value * 100., 0., 100.)} /> },
        { field: 'url', headerName: 'Action', type: 'string', cellRenderer: ({value, data, ...rest}) => !value ? <br/> : <button onClick={()=>{
            window.open(value, '_blank');
        }}>{data?.xlsx ? 'Download' : 'Open'}</button> },
        // { field: 'type', header: 'Type', type: 'string' },
    ];

    const table = [
        { make: "Tesla", model: "Model Y", price: 64950, electric: true },
        { make: "Ford", model: "F-Series", price: 33850, electric: false },
        { make: "Toyota", model: "Corolla", price: 29600, electric: false },
    ];

    if(!session?.statuses?.length || !Array.isArray(session?.statuses)) return null;
    
    return <>
        <Fab color="primary" aria-label="dark-mode" onClick={handleClickOpen} style={{marginLeft: 15}}>
            <WorkHistory/>
            {/* <PendingActions/> */}
        </Fab>
        <Dialog
            fullWidth={true}
            maxWidth='xl'
            open={open}
            onClose={handleClose}
        >
            <DialogTitle>Status Table</DialogTitle>
            <DialogContent>
                {/* <DialogContentText>
                    Statuses
                </DialogContentText> */}
                {/* <Box
                    noValidate
                    component="form"
                    sx={{display: 'flex', flexDirection: 'column', m: 'auto', width: 'fit-content'}}
                > */}
                    <Table rawColumns columns={columns} table={session?.statuses}/>
                    {/* <pre>
                        {tryStringify({columns, v: session?.statuses})}
                    </pre> */}
                    <br/>
                {/* </Box> */}
            </DialogContent>
            <DialogActions>
            <Button onClick={handleClose}>Close</Button>
            </DialogActions>
        </Dialog>
    </>;
};

const ConfigTableOption = ({sessionId, session, info: infoSession}) => {
    const progressRef = useRef({values: []});
    const [info, setInfo] = useSessionState({
        fileProgress: [],
        spreadsheetId: '',
        sheets: [],
        sheet: '',
    }, 'test-junglr-config-table-file-progress-3');

    const updateProgress = (id, value) => {
        setInfo(prev=>{
            const index = progressRef.current.values.findIndex(item=>item.id===id);
            if(index !== -1) progressRef.current.values[index].value = value;
            prev.fileProgress = progressRef.current.values;
            return copy(prev);
        });
    }
    const deleteProgress = (id) => {
        setInfo(prev=>{
            const index = progressRef.current.values.findIndex(item=>item.id===id);
            if(index !== -1) progressRef.current.values[index].deleted = true;
            prev.fileProgress = progressRef.current.values;
            return copy(prev);
        });
    }
    
    const uploadFile = file => {
        let toastId = newID();
        const promise = async () => {
            try {
                const ext = file.name.split('.').pop().toLowerCase();
                const allowedExtensions = ['xlsx', 'csv'];
                console.log(ext, file.name);
                if(!allowedExtensions.includes(ext)) {
                    toast.error(`Invalid file extension: ${ext}`);
                    throw new Error(`Invalid file extension: ${ext}`);
                } 
                const key = await bucket.uploadFileForProcess(file, (...args)=>{
                    // console.log('progress', args);
                    // setFileProgress(args[0]);
                    // toast.update(toastId, { progress: args[0] });
                    updateProgress(toastId, args[0]);
                });
                if(!key) {
                    toast.error('Upload Failed');
                    throw new Error('Upload Failed');
                }
                
                return key;
            } finally {
                deleteProgress(toastId);
            }
        }
        progressRef.current.values = forceArr(progressRef.current.values);
        setInfo(prev=>{
            progressRef.current.values.push({id: toastId, value: 0., deleted: false});
            prev.fileProgress = progressRef.current.values;
            return copy(prev);
        });
        toast.promise(promise(), {
            pending: 'Uploading...',
            success: 'Uploaded',
            error: 'Upload Failed',
            progress: 0.0,
            toastId,
        })
        .then(key => {
            const promise = async () => {
                console.log(`file key: '${key}'`)
                const res = await api('/upload-excel-to-config-table', {sessionId, key});
                if(res?.status !== 'error') return;
                console.error(res?.error);
                throw new Error(res?.error);
            }
            toast.promise(promise(), {
                pending: 'Requesting Changes...',
                success: 'Requested',
                error: 'Request Failed'
            })
        })
        .catch(e=>console.error(e));
    }

    const requestGoogleSheetPull = () => {
        const promise = async () => {
            const {spreadsheetId, sheet} = info;
            const res = await api('/pull-google-sheet-to-config-table', {sessionId, spreadsheetId, sheet});
            if(res?.status !== 'error') return;
            console.error(res?.error);
            throw new Error(res?.error);
        }
        toast.promise(promise(), {
            pending: 'Requesting...',
            success: 'Requested',
            error: 'Request Failed'
        });
    }

    return <>
        {/* <pre>
            {tryStringify(infoSession)}
        </pre> */}
        {/* {!infoSession.configTableOpen ? null : <ConfigTable sessionId={sessionId} session={session}/>} */}
        {infoSession.currentConfigTableOption === configTableOptions.toggle 
            ? (!infoSession.configTableOpen ? null : <ConfigTable sessionId={sessionId} session={session}/>)//<ConfigTable sessionId={sessionId} session={session}/>
        : infoSession.currentConfigTableOption === configTableOptions.downloadExcel 
            ? null
        : infoSession.currentConfigTableOption === configTableOptions.uploadExcel
            ? <>
                <div style={{margin: 15}}/>
                <FileUpload 
                    accept='.xlsx,.csv'
                    onChange={e=> {
                        if (e.target.files !== null && e.target?.files?.length > 0) uploadFile(e.target.files[0]);
                    }}
                    onDrop={e=>uploadFile(e.dataTransfer.files[0])}
                />
                {/* <CircularProgressWithLabel variant="determinate" value={typeof fileProgress !== 'number' ? 0 : clamp(fileProgress * 100., 0., 100.)} /> */}
                {info.fileProgress.filter(p=>!p?.deleted).map(p=><CircularProgressWithLabel variant="determinate" value={typeof p?.value !== 'number' ? 0 : clamp(p?.value * 100., 0., 100.)} />)}
            </>
        : infoSession.currentConfigTableOption === configTableOptions.createGoogleSheet
            ? null
        : infoSession.currentConfigTableOption === configTableOptions.pullGoogleSheet
            ? <>
                <div style={{margin: 15}}/>
                <TextField label='Spreadsheet Url' value={info.spreadsheetId} onChange={e=>setInfo(prev=>({...prev, spreadsheetId: e.target.value || ''}))} style={{marginBottom: 15}}/>
                <br/>
                {!info.spreadsheetId 
                    ? null 
                    : <>
                        <Button variant='outlined' onClick={()=>getSheets(info.spreadsheetId, sheets=>setInfo(prev=>({...prev, sheets})))} style={{marginBottom: 15}}>Get Sheets</Button>
                        <br/>
                    </>
                }
                {info.sheets.length > 0 && <Autocomplete
                    disablePortal
                    options={info.sheets}
                    value={info.sheet}
                    onChange={(event, newValue) => {
                        if(newValue) setInfo(prev=>({...prev, sheet: newValue}))
                        else setInfo(prev=>({...prev, sheet: ''}));
                    }}
                    sx={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} label="Sheets" />}
                />}
                {!info.sheet 
                    ? null 
                    : <>
                        <br/>
                        <Button variant='outlined' style={{marginBottom: 15}} onClick={requestGoogleSheetPull}>Pull Sheet</Button>
                    </>
                }
            </>
        : null }
    </>
}

export const BidTableOption = ({sessionId, session, info: infoSession}) => {
    const progressRef = useRef({values: []});
    const [info, setInfo] = useSessionState({
        fileProgress: [],
        spreadsheetId: '',
        sheets: [],
        sheet: [],
    }, 'test-junglr-config-table-file-progress-5');

    const updateProgress = (id, value) => {
        setInfo(prev=>{
            const index = progressRef.current.values.findIndex(item=>item.id===id);
            if(index !== -1) progressRef.current.values[index].value = value;
            prev.fileProgress = progressRef.current.values;
            return copy(prev);
        });
    }
    const deleteProgress = (id) => {
        setInfo(prev=>{
            const index = progressRef.current.values.findIndex(item=>item.id===id);
            if(index !== -1) progressRef.current.values[index].deleted = true;
            prev.fileProgress = progressRef.current.values;
            return copy(prev);
        });
    }

    const uploadFile = file => {
        let toastId = newID();
        const promise = async () => {
            try {
                const ext = file.name.split('.').pop().toLowerCase();
                const allowedExtensions = ['xlsx', 'csv'];
                console.log(ext, file.name);
                if(!allowedExtensions.includes(ext)) {
                    toast.error(`Invalid file extension: ${ext}`);
                    throw new Error(`Invalid file extension: ${ext}`);
                } 
                const key = await bucket.uploadFileForProcess(file, (...args)=>{
                    // console.log('progress', args);
                    // setFileProgress(args[0]);
                    // toast.update(toastId, { progress: args[0] });
                    updateProgress(toastId, args[0]);
                });
                if(!key) {
                    toast.error('Upload Failed');
                    throw new Error('Upload Failed');
                }
                
                return key;
            } finally {
                deleteProgress(toastId);
            }
        }
        progressRef.current.values = forceArr(progressRef.current.values);
        setInfo(prev=>{
            progressRef.current.values.push({id: toastId, value: 0., deleted: false});
            prev.fileProgress = progressRef.current.values;
            return copy(prev);
        });
        toast.promise(promise(), {
            pending: 'Uploading...',
            success: 'Uploaded',
            error: 'Upload Failed',
            progress: 0.0,
            toastId,
        })
        .then(key => {
            const promise = async () => {
                console.log(`file key: '${key}'`)
                const res = await api('/upload-excel-to-bid-table', {sessionId, key});
                if(res?.status !== 'error') return;
                console.error(res?.error);
                throw new Error(res?.error);
            }
            toast.promise(promise(), {
                pending: 'Requesting Changes...',
                success: 'Requested',
                error: 'Request Failed'
            })
        })
        .catch(e=>console.error(e));
    };

    const requestGoogleSheetPull = () => {
        const promise = async () => {
            const {spreadsheetId, sheet} = info;
            const res = await api('/pull-google-sheet-to-bid-table', {sessionId, spreadsheetId, sheet});
            if(res?.status !== 'error') return;
            console.error(res?.error);
            throw new Error(res?.error);
        }
        toast.promise(promise(), {
            pending: 'Requesting...',
            success: 'Requested',
            error: 'Request Failed'
        });
    };
    
    return <>
        {infoSession.currentBidTableOption === bidTableOptions.inertBids
            ? null
        : infoSession.currentBidTableOption === bidTableOptions.downloadExcel
            ? null
        : infoSession.currentBidTableOption === bidTableOptions.uploadExcel
            ? <>
                <div style={{margin: 15}}/>
                <FileUpload 
                    accept='.xlsx,.csv'
                    onChange={e=> {
                        if (e.target.files !== null && e.target?.files?.length > 0) uploadFile(e.target.files[0]);
                    }}
                    onDrop={e=>uploadFile(e.dataTransfer.files[0])}
                />
                {info.fileProgress.filter(p=>!p?.deleted).map(p=><CircularProgressWithLabel variant="determinate" value={typeof p?.value !== 'number' ? 0 : clamp(p?.value * 100., 0., 100.)} />)}
            </>
        : infoSession.currentBidTableOption === bidTableOptions.createGoogleSheet
            ? null
        : infoSession.currentBidTableOption === bidTableOptions.pullGoogleSheet
            ? <>
                <div style={{margin: 15}}/>
                <TextField label='Spreadsheet Url' value={info.spreadsheetId} onChange={e=>setInfo(prev=>({...prev, spreadsheetId: e.target.value || ''}))} style={{marginBottom: 15}}/>
                <br/>
                {!info.spreadsheetId 
                    ? null 
                    : <>
                        <Button variant='outlined' onClick={()=>getSheets(info.spreadsheetId, sheets=>setInfo(prev=>({...prev, sheets})))} style={{marginBottom: 15}}>Get Sheets</Button>
                        <br/>
                    </>
                }
                {info.sheets.length > 0 && <Autocomplete
                    multiple
                    disablePortal
                    options={info.sheets}
                    value={info.sheet}
                    onChange={(event, newValue) => {
                        if(newValue) setInfo(prev=>({...prev, sheet: newValue}))
                        else setInfo(prev=>({...prev, sheet: []}));
                    }}
                    sx={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} label="Sheets" />}
                />}
                {!info.sheet 
                    ? null 
                    : <>
                        <br/>
                        <Button variant='outlined' style={{marginBottom: 15}} onClick={requestGoogleSheetPull}>Pull Sheet</Button>
                    </>
                }
            </>
        : infoSession.currentBidTableOption === bidTableOptions.getFromAmazonApi
            ? null
        : infoSession.currentBidTableOption === bidTableOptions.getFromDatabase
            ? null
        : null}

        <BidTable sessionId={sessionId} session={session}/>
    </>;
}


export const BidSheetSession = ({sessionId, close, setSessions}) => {
    const [session, setSession, commit, loading] = useLocalAndServerCommitState(null, `session-${sessionId}`, undefined, undefined, false, undefined, 1, (local, server)=>{
        if(!local) return server;
        if(!server) return local;
        ['amazonApiUploads', 'excelBulkDownloads', 'googleSheetBulkCreations', 'statuses', 'tables'].forEach(key=>{
            local[key] = forceArr(local[key]);
            server[key] = forceArr(server[key]);
            addLocalItems(local[key], server[key]);
            server[key] = server[key].filter(item=>!item.deleted);
        });

        server.name = getBest(local, server)?.name || 'New Session';

        return server;
    });

    const updateSessions = () => {
        return setTimeout( async () => {
            setSessions(prev=>{
                if(!prev.some(s=>s?.id === sessionId)) {
                    const obj = objectOperations.create('name', session['name'])
                    obj.id = sessionId;
                    return mergeArrs(prev, [obj]);
                }
                return prev;
            }, true)
        }, 1000);
    }

    useEffect(() => {
        if(session) {
            const t = updateSessions();
            return ()=>clearTimeout(t);
        }
    }, [session]);

    const [info, setInfo] = useState({
        configTableOpen: false,
        currentConfigTableOption: configTableOptions.toggle,
        currentBidTableOption: session?.tables?.length ? bidTableOptions.inertBids : bidTableOptions.uploadExcel,
        currentFinishedTableOption: finishedOptions.uploadToAmazon,
    });

    const configTableButtons = {
        [configTableOptions.toggle]: ()=>setInfo(prev=>({...prev, configTableOpen: !prev.configTableOpen})),
        [configTableOptions.downloadExcel]: ()=>{
            const downloadLink = `${URL}/download-config-table?sessionId=${sessionId}`;
            window.open(downloadLink, '_blank');
        },
        [configTableOptions.uploadExcel]: ()=>alert('Please click the image below. The "Upload Excel" button currently doesn nothing.'),
        [configTableOptions.createGoogleSheet]: ()=>{
            const promise = async () => {
                const res = await api('/create-config-table-google-sheet', {sessionId});
                // if(res?.status !== 'error') return;
                
                console.error(res);
                if(res?.error) throw new Error(res?.error);
            }
            toast.promise(promise(), {
                pending: 'Starting Google Sheet Request...',
                success: 'Google Sheet Request Started',
                error: 'Google Sheet Request Failed'
            });
        },
        [configTableOptions.pullGoogleSheet]: ()=>alert('Please paste a Google sheet link, press "Get Sheets", select sheet a sheet, then click "Pull Sheet". Clicking "Pull Google Sheet" currently does nothing.'),
    };

    const bidTableButtons = {
        [bidTableOptions.inertBids]: ()=>alert('"Insert Bids" has yet to be added'),
        [bidTableOptions.downloadExcel]: ()=>{
            const downloadLink = `${URL}/download-bid-table?sessionId=${sessionId}`;
            window.open(downloadLink, '_blank');
        },
        [bidTableOptions.uploadExcel]: ()=>alert('"Upload Excel" has yet to be added'),
        [bidTableOptions.createGoogleSheet]: ()=>{
            const promise = async () => {
                const res = await api('/create-bid-table-google-sheet', {sessionId});
                // if(res?.status !== 'error') return;
                
                console.error(res);
                if(res?.error) throw new Error(res?.error);
            }
            toast.promise(promise(), {
                pending: 'Starting Google Sheet Request...',
                success: 'Google Sheet Request Started',
                error: 'Google Sheet Request Failed'
            });
        },
        [bidTableOptions.pullGoogleSheet]: ()=>alert('"Pull Google Sheet" has yet to be added'),
        [bidTableOptions.getFromAmazonApi]: ()=>alert('"Get from Amazon API" has yet to be added'),
        [bidTableOptions.getFromDatabase]: ()=>alert('"Get from Database" has yet to be added'),
    };

    const bidDisableButtons = [
        bidTableOptions.inertBids,
        bidTableOptions.downloadExcel,
        bidTableOptions.createGoogleSheet
    ];

    const finishedButtons = {
        [finishedOptions.uploadToAmazon]: ()=>alert('"Upload to Amazon" has yet to be added'),
        [finishedOptions.downloadBulkExcel]: ()=>alert('"Download Bulk Excel" has yet to be added'),
        [finishedOptions.createGoogleSheet]: ()=>alert('"Create Bulk Google Sheets" has yet to be added'),
    };

    const saveName = ()=>{
        const promise = async () => {
            await commit();
            // await setSessions(prev=>copy(prev));
            setSessions(prev=>{
                const index = prev.findIndex(s=>s.id===session.id);
                if(index !== -1) {prev[index].name = session.name;prev[index].updated = Date.now();}
                return copy(prev);
            }, true)
        }
        toast.promise(promise(), {
            pending: 'Saving...',
            success: 'Saved',
            error: 'Save Failed'
        });
    }

    if(!sessionId || !session) return <>
        {!close ? null : <Fab size='small' onClick={()=>close()}> <ArrowBackIcon/> </Fab>}
        <br/>
        <div style={{margin: 15}}/>
        <h3> No Session Selected </h3>
    </>;

    return <>
        {!close ? null : <Fab size='small' onClick={()=>close()}> <ArrowBackIcon/> </Fab>}
        <StatusTable sessionId={sessionId} session={session}/>
        <br/>
        
        <div style={{margin: 15}}/>
        <TextField 
            label='Session Name' value={session?.name || ''} 
            style={{margin: 25}}
            onChange={e=>setSession(prev=>({...prev, name: e.target.value, updated: Date.now()}))}
            onBlur={saveName}
            onKeyDown={e=>{
                if(e.key==='Enter') {
                    // saveName();
                    // execute blur
                    e.target.blur();
                }}}
        />
        <br/>
        <MultiSelectButton 
            defaultValue={info.currentConfigTableOption} options={configTableButtons} disabled={[]}
            onChange={opt=>setInfo(prev=>({...prev, currentConfigTableOption: opt}))}
        />
        {/* {!info.configTableOpen ? null : <ConfigTable sessionId={sessionId} session={session}/>} */}
        <ConfigTableOption sessionId={sessionId} session={session} info={info}/>
        <div style={{margin: 15}}/>
        <MultiSelectButton 
            defaultValue={info.currentBidTableOption} 
            options={bidTableButtons}
            disabled={[
                bidTableOptions.inertBids,
                // bidTableOptions.downloadExcel,
                // bidTableOptions.uploadExcel,
                // bidTableOptions.createGoogleSheet,
                // bidTableOptions.pullGoogleSheet,
                bidTableOptions.getFromAmazonApi,
                bidTableOptions.getFromDatabase,
            ]}//{!session?.tables?.length ? bidDisableButtons : undefined}
            onChange={opt=>setInfo(prev=>({...prev, currentBidTableOption: opt}))}
        />
        <BidTableOption sessionId={sessionId} session={session} info={info}/>
        {/* <div style={{margin: 15}}/> */}
        
        {/* <BidTable sessionId={sessionId} session={session}/> */}
        <div style={{margin: 15}}/>
        <MultiSelectButton defaultValue={info.currentFinishedTableOption} options={finishedButtons} onChange={opt=>setInfo(prev=>({...prev, currentFinishedTableOption: opt}))}/>
    </>;
}