import { useState, useEffect } from "react";
// import { LineChart } from '@mui/x-charts/LineChart';
// import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts';
import QueryTable from "../../../DatabaseTable";
import { toast } from "react-toastify";
import { Autocomplete, TextField } from "@mui/material";
import { useLocalState } from "../../../../util/util";
import { LoomToolTip } from "../../../../util/helper";

const  toNumber = value => {
    if (!value) return 0;
    const v = parseFloat(value.toString().replace(/,/g, ''));
    return isNaN(v) ? 0 : v;
}


const CustomizedAxisTick = (...args)=>{
    const {  x, y, stroke, payload } = args[0];
    return (
        <g transform={`translate(${x},${y})`}>
          <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)">
            {payload.value}
          </text>
        </g>
      );
  };


const stateOptions = ['Non-Archived (Enabled & Paused)', 'All', 'Enabled', 'Paused', 'Archived'];


const TargetAsinMultiDate = ({reportName, profile, start, end}) => {
    const [showDetails, setShowDetails] = useState(true);
    // const [lineData, setLineData] = useState([]);
    const [state, setState] = useLocalState(stateOptions[2], 'jungler-target-asin-multi-date-state');

    useEffect(() => {
        setTimeout(() => {
            setShowDetails(false);
        }, 10000);
    }, []);

    // const getCPCData = target_id => {
    //     const promise = async () => {
    //         const options = {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json'
    //             },
    //             body: JSON.stringify({
    //                 query_id: 'target-cpc',
    //                 profile, start, end, 
    //                 args: {target_id: `${target_id}`}})
    //         }
    //         const response = await fetch('/query-table', options);
    //         const data = await response.json();
    //         console.log('cpc data', !!data?.table?.length, data?.table?.length, data);
    //         if(data?.table?.length) {
    //             console.log('table', data.table.map(row=>({...row, date: new Date(row.date)})))
    //             setLineData(data.table.map(row=>({...row, date: (new Date(row.date).toLocaleDateString('en-US', { 
    //                 month: 'short', 
    //                 day: 'numeric', 
    //                 year: 'numeric' 
    //               }))})));//.map(row=>({...row, date: new Date(row.date)})));
    //         }
    //     }

    //     toast.promise(
    //         promise(), {
    //             pending: 'Fetching',
    //             success: 'Fetched',
    //             error: 'Failed to fetch'
    //         }
    //     )
    // }

    return <div>
        <div style={{display: 'flex', flexDirection: 'row'}}>
            <h2 style={{marginRight: 15}}>Target ASINs Multi-Date Range</h2>
            <LoomToolTip 
                title='In depth use' 
                loomLink='https://www.loom.com/share/052d3f1b52c14f4ba3d2dbf3dbf2e9b3?sid=e915d78f-8745-4b27-97e1-39002f235896' 
                loomEmbededLink='https://www.loom.com/embed/052d3f1b52c14f4ba3d2dbf3dbf2e9b3?sid=07b5eea4-c149-41e8-bf62-94a2090dd372'
            />
        </div>
        {!showDetails ? null : <>
            {/* <p>Note: This does not use the date range on the top right. </p>
            <p>Even though all tables currently need a daterange to communicate with the server.</p> */}
            <p>Recommened: Use the "Fetch for Later" then "Past fetches" combination</p>
        </>}
        <Autocomplete
            options={stateOptions}
            value={state}
            onChange={(e, v)=>setState(v)}
            renderInput={(params) => <TextField {...params} label="State Filter" />}
        />
        <br/>
        <div style={{maxWidth: '75vw'}}>
        <QueryTable 
            name={reportName || 'Target ASINs Multi-Date Range'}
            queryId='asin-target-multi-date-range'
            profile={profile}
            start={start}
            end={end}
            args={{state: state.split('(').slice(0)[0].trim().toLowerCase()}}
            // extra={[{
            //     field: 'details', headerName: 'Details', width: 150, 
            //     renderCell: params=>(<button onClick={()=>getCPCData(params?.row?.['Target ID'])}> Details </button>)
            // }]}
            showFetchForLater
            showCancel
            noDates
        />
        </div>
        <br/>
        {/* {false
        ? null : 
        <div style={{width: '75wv', height: 400, overflowX: 'scroll'}}>
            <LineChart width={window.screen.width * .75} height={300} data={lineData.length ? lineData : [{cpc: 0.50, date: '2024-05-01'}]} margin={{ top: 5, right: 20, bottom: 50, left: 0 }}>
                <Line type="monotone" dataKey="cpc" stroke="#8884d8" />
                <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                <XAxis dataKey="date" tick={<CustomizedAxisTick />} />
                <YAxis />
                <Tooltip />
            </LineChart>
        </div>} */}
        <div style={{maxWidth: '75vw'}}>
        <QueryTable 
            queryId='asin-campaign-multi-date-range'
            profile={profile}
            start={start}
            end={end}
            // extra={[{
            //     field: 'details', headerName: 'Details', width: 150, 
            //     renderCell: params=>(<button onClick={()=>getCPCData(params?.row?.['Target ID'])}> Details </button>)
            // }]}
            showFetchForLater
            showCancel
        />
        </div>
    </div>
}


export default TargetAsinMultiDate;