import { useState, useEffect, useMemo, useRef, useImperativeHandle, useCallback, forwardRef } from 'react';
import { copy, useDarkMode, useLocalState } from '../../../../util/storage';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-markup';
import 'prismjs/components/prism-python';
import 'prismjs/themes/prism.css';

import { themeBalham, themeAlpine, themeQuartz, colorSchemeDark } from 'ag-grid-community';



// Tables
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import { AllCommunityModule, ModuleRegistry } from 'ag-grid-community'; 
import { toast } from 'react-toastify';
const gridOptions = {
    theme: themeBalham,
};
// Register all Community features
ModuleRegistry.registerModules([AllCommunityModule]);

const tryStringify = j => {
    console.log('tryStringify', j);
    try {
        return JSON.stringify(j);
    } catch (e) {
        return `${j}`;
    }
}

const getDataKey = (value, data) => {
    let key = null;
    Object.entries(data).forEach(([k, v]) => {
        if (v === value) key = k;
    });
    return key;
}

const typeCast = {
    make: 'string',
    model: 'string',
    price: 'number',
    electric: 'boolean',
    button: 'string',
};

const removeText = (txt, chars) => {
    let t = txt;
    chars.forEach(c => {
        t = t.replaceAll(c, '');
    });
    return t;
}

const forceNumber = n => {
    if (typeof n === 'number') return n;
    if (typeof n === 'string') {
        n = parseFloat(removeText(n, [' ', '$', ',']));
        if (isNaN(n)) return 0;
    }
    return n;
}
const forceString = s => {
    if (typeof s === 'string') return s;
    return `${s}`;
}
const forceBoolean = b => {
    if (typeof b === 'boolean') return b;
    if (typeof b === 'string') {
        if (b === 'true') return true;
        if (b === 'false') return false;
    }
    return !!b;
}

const alphabet = 'abcdefghijklmnopqrstuvwxyz';
const chars = ('_-' + alphabet).toUpperCase().split('');

const varNameToHeaderName = name => {
    const splitAll = (arr, op) => arr.reduce((_arr, word)=>[..._arr, ...op(word)], []);
    let words = [name];
    
    chars.forEach((letter, i) => {
        words = splitAll(words, w=>{
            const ws = w.split(letter);
            if(ws.length > 1 && alphabet.includes(letter.toLowerCase())) {
                return [ws[0]].concat(ws.slice(1).map(_w=>letter + _w))
            }
            return ws
        });
    });
    return words.map( w => w[0].toUpperCase() + w.slice(1).toLowerCase()).join(' ')
}

const GridExample = () => {
    // Row Data: The data to be displayed.
    const [rowData, setRowData] = useLocalState([
        { make: "Tesla", model: "Model Y", price: 64950, electric: true },
        { make: "Ford", model: "F-Series", price: 33850, electric: false },
        { make: "Toyota", model: "Corolla", price: 29600, electric: false },
    ], 'test-key-1');

    const CustomButtonComponent = (props) => {
        return <button onClick={() => window.alert('clicked') }>Push Me!</button>;
    };

    // Column Definitions: Defines the columns to be displayed.
    const [colDefs, setColDefs] = useState([
        { field: "make", filter: true, floatingFilter: true, editable: true, cellEditor: 'agSelectCellEditor', cellEditorParams: { values: ['Tesla', 'Ford', 'Toyota'], }, },
        { field: "model", editable: true },
        { field: "price", valueFormatter: p => '£' + p.value.toLocaleString(), editable: true },
        { headerName: "Make & Model", valueGetter: p => p?.data?.make + ' ' + p?.data?.model},
        { field: "electric", editable: true },
        { field: "button", cellRenderer: CustomButtonComponent },
    ]);

    // const rowSelection = useMemo(() => { 
    //     return {
    //         mode: 'multiRow',
    //     };
    // }, []);

    return (
        <div
            // define a height because the Data Grid will fill the size of the parent container
            style={{ height: 500 }}
        >
            <AgGridReact
                rowData={rowData}
                columnDefs={colDefs}
                // rowSelection={rowSelection}
                onCellValueChanged={(e, ...args)=>{
                    console.log('onCellValueChanged', e, args);
                    const {newValue} = e;
                    const key = getDataKey(e?.value, e?.data);
                    if(typeCast.hasOwnProperty(key)) {
                        if (typeCast[key] === 'number') e.data[key] = forceNumber(newValue);
                        if (typeCast[key] === 'integer') e.data[key] = parseInt(forceNumber(newValue));
                        if (typeCast[key] === 'boolean') e.data[key] = forceBoolean(newValue);
                        if (typeCast[key] === 'string') e.data[key] = forceString(newValue);
                        setRowData(prev=>{
                            prev[e?.rowIndex] = e?.data;
                            return copy(prev);
                        });
                        return;
                    }
                }}
            />
        </div>
    )
}





// const columns = [
//     { value: 'make', header: 'Make', type: 'string', editable: true },
// ]
// const table = [
//     { make: "Tesla", model: "Model Y", price: 64950, electric: true },
//     { make: "Ford", model: "F-Series", price: 33850, electric: false },
//     { make: "Toyota", model: "Corolla", price: 29600, electric: false },
// ]


const typeCastFunctions = {
    convert: (type, v) => {
        if(typeCastFunctions.hasOwnProperty(type)) return typeCastFunctions[type](v);
        else console.error(v, tryStringify(v), typeof v);
        
        return typeCastFunctions.default(v);
    },
    string: v => forceString(v),
    number: v => forceNumber(v),
    integer: v => parseInt(forceNumber(v)),
    boolean: v => forceBoolean(v),
    default: v => v
};


export const Table = forwardRef(({columns, rawColumns, table, onChange, ...rest}, ref) => {
    const gridRef = useRef();
    const [darkMode, setDarkMode] = useDarkMode();
    
    

    useEffect(()=>{
        // setting theme: https://www.ag-grid.com/javascript-data-grid/themes/
        // darkmode: https://www.ag-grid.com/javascript-data-grid/theming-colors/
        const setTheme = (timeoutOnFail, waitTime, {t}) => {
            console.log('setting theme', darkMode, timeoutOnFail, waitTime, t);
            if(gridRef?.current?.api?.setGridOption) gridRef.current.api.setGridOption("theme", darkMode ? themeQuartz.withPart(colorSchemeDark) : themeQuartz);
            else if(timeoutOnFail) (t || []).push(setTimeout(()=>setTheme(waitTime === 50, 1000, {t}), waitTime));
        }
        const timeout = {t: []}
        setTheme(true, 50, timeout);
        return ()=>timeout.t.forEach(t=>clearTimeout(t));
    }, [darkMode, gridRef?.current?.api?.setGridOption]);

    // const addItems = useCallback((addIndex) => {
    //     const newItems = [
    //       createNewRowData(),
    //       createNewRowData(),
    //       createNewRowData(),
    //     ];
    //     const res = gridRef.current.api.applyTransaction({
    //       add: newItems,
    //       addIndex: addIndex,
    //     });
    //     printResult(res);
    //   }, []);

    useImperativeHandle(ref, () => ({
        add: (rows, addIndex) => gridRef?.current?.api?.applyTransaction({add: rows, addIndex: addIndex}),
        gridRef,
    }), [gridRef]);

    const height = 500;

    if(!Array.isArray(columns)) columns = [];
    if(!Array.isArray(table)) table = [];

    const typeCast = columns.reduce((o, col)=>({...o, [rawColumns ? col.field : col.value]: col.type}), {});

    const onCellChange = (e, ...args)=>{
        console.log('onCellValueChanged', e, args);
        const {newValue} = e;
        const key = getDataKey(e?.value, e?.data);
        console.log(key, typeCast.hasOwnProperty(key), e?.value, e?.data, typeCast);
        if(typeCast.hasOwnProperty(key)) {
            const type = typeCast[key];
            
            e.data[key] = typeCastFunctions.convert(type, newValue);

            onChange({index: e?.rowIndex, key, value: e.data[key], data: e?.data, rawValue: newValue});
            // setRowData(prev=>{
            //     prev[e?.rowIndex] = e?.data;
            //     return copy(prev);
            // });
        }
    };


    if(!columns.length || !table.length) return (
        <div style={{height, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <h3>Table is empty</h3>
        </div>
    );

    const removeKeys = (obj, keys) => {
        const o = copy(obj);
        keys.forEach(key=>(key in o ? delete o[key] : null));
        return o;
    }

    const keywdown = (params) => {
        const {event, value, column, rowInex, data, colDef, ...rest} = params;
        // console.log('keywdown', event, value, column, rowInex, data, rest);
        // return;
        const {key, ctrlKey, metaKey} = event;
        if(key === 'c' && (ctrlKey || metaKey)) {
            navigator.clipboard.writeText(`${value}`);
        } else if(key === 'v' && (ctrlKey || metaKey) && onChange && colDef?.editable) {
            if(typeof colDef?.editable === 'boolean' || (typeof colDef?.editable === 'function' && colDef?.editable(params))) {
                navigator.clipboard.readText().then(text=>{
                    // const data = copy(table[rowInex]);
                    data[column.colId] = text;
                    onChange({index: rowInex, key: column.colId, value: data[column.colId], data, rawValue: text});
                    // gridRef.current.api.redrawRows({rowNodes: [gridRef.current.api.getRowNode(rowInex)]});
                }).catch(e=>toast.error('Failed to read clipboard'));
            }
        }
    }

    return (
        <div
            style={{ height }}
        >
            <AgGridReact
                ref={gridRef}
                rowData={table}
                columnDefs={rawColumns ? columns : columns.map(col=>({field: col.value, header: col.header, editable: col.editable}))}
                onCellValueChanged={typeof onChange !== 'function' ? null : onCellChange}
                {...rest}
                ensureDomOrder={true}
                enableCellTextSelection={true}
                // onCellFocused={e=>console.log('focused', e)}
                onCellKeyDown={keywdown}
            />
        </div>
    );
});




