import React, { useState } from 'react';
import { TextField, Switch, FormGroup, FormControl, FormControlLabel, Tabs, Tab, Box } from '@mui/material';
import QueryTable from '../../../DatabaseTable';
import {toast} from 'react-toastify';
import { useSessionState } from '../../../../util/util';
import { DateRange } from '../../../react-date-range';
import { formatDate } from '../..';


const encode = v => {
  return JSON.stringify({
    start: !v.start ? null : v.start.toISOString(),
    end: !v.end ? null : v.end.toISOString(),
  });
}
const decode = v => {
  v = JSON.parse(v);
  if(v.start) v.start = new Date(v.start);
  if(v.end) v.end = new Date(v.end);
  return v;
}

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  
function BasicTabs() {
    const [value, setValue] = React.useState(0);
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
  
    return (
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="Item One" {...a11yProps(0)} />
            <Tab label="Item Two" {...a11yProps(1)} />
            <Tab label="Item Three" {...a11yProps(2)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          Item One
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          Item Two
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          Item Three
        </CustomTabPanel>
      </Box>
    );
  }

const fetchQuery = async (profile, start, end, type, args, setLoading) => {
  const queryId = `negative-search-${type}`
  
  if(!profile || !start || !end) {
      console.log(`${!profile} || ${!start} || ${!end}`);
      console.log(`${profile} || ${start} || ${end}`);
      toast.error('Please select an account and date range');
      return;
  }

  const controller = new AbortController();

  setLoading(true);
  try {
      const options = {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({
              query_id: queryId,
              profile, 
              start, 
              end, 
              args: args || {},
          }),
          signal: controller.signal
      }
      const response = await fetch('/query-table', options);
      console.log('response', response);
      let data = await response.json();
      console.log('/query-table', data);
      if(data.table) {
          data = data.table;
          setLoading(false);
          return data;
      }
  } catch (e) { console.log(e); }
  setLoading(false);
  return [];
}

const Negatives = ({profile, start, end, settings}) => {
  const [searchForm, setSearchForm] = useState({
    asin: settings.asin || '', 
    query: settings.query || '',
    loading: false,
  });
  const setForm = (key, value) => setSearchForm(prev=>({...prev, [key]: value}));
  const [searchTable, setSearchTable] = useState([]);
  const search = async (type, localType) => {
    const args = {};
    if(localType === 'exact-term') {
      if(searchForm.asin) args.asin = searchForm.asin;
      if(searchForm.query) args.query = searchForm.query;
    } else if(localType === 'like-term') {
      if(searchForm.asin) args.asin = searchForm.asin;
      if(searchForm.query) args.like_query = searchForm.query;
    } else if(['similarity', 'token-match', 'cosin-vector'].includes(localType)) {
      if(searchForm.asin) args.asin = searchForm.asin;
      if(searchForm.query) args.query = searchForm.query;
    }
    console.log('args', args);
    const data = await fetchQuery(profile, start, end, type, args, v=>setForm('loading', v));
    setSearchTable(data);
  }

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

  return <div>
    {/* <Box sx={{ width: '100%' }}></Box> */}
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
        <Tab label="Search" {...a11yProps(0)} />
        <Tab label="SP Bulk" {...a11yProps(1)} />
        <Tab label="Item Three" {...a11yProps(2)} />
        </Tabs>
    </Box>
    <CustomTabPanel value={value} index={0}>
        <div>
            <TextField id="outlined-basic" label="Search Query" variant="outlined" 
                value={searchForm.query} onChange={e=>setForm('query', e.target.value)}
            />
            <TextField id="outlined-asin" label="ASIN" variant="outlined" value={searchForm.asin} onChange={e=>setForm('asin', e.target.value)}/>
            <br/>
            <button className='btn' onClick={()=>search('asin-search-terms', 'exact-term')}>Exact Search</button>
            <button className='btn' onClick={()=>search('asin-search-terms', 'like-term')}>Like Search</button>
            <button className='btn' onClick={()=>search('token-match', 'token-match')}>Token Match</button>
            <button className='btn' onClick={()=>search('similarity', 'similarity')}>Similarity</button>
            <button className='btn' onClick={()=>search('cosin-vector', 'cosin-vector')}>Cosin Vector</button>
            <br/>
            <div style={{maxWidth: '75vw'}}>
            <QueryTable name='Negative Search' displayName rows={searchTable} loading={searchForm.loading} autoFormat/>
            </div>
        </div>
    </CustomTabPanel>
    <CustomTabPanel value={value} index={1}>
        <div style={{maxWidth: '75vw'}}>
            <QueryTable
                queryId='sp-negatives-from-no-sales'
                name='SP No Sale Negatives Bulk'
                profile={profile}
                start={start}
                end={end}
                showCancel
                showFetchForLater
            />
        </div>
    </CustomTabPanel>
    <CustomTabPanel value={value} index={2}>
        Item Three
    </CustomTabPanel>
  </div>
}

export default Negatives;
