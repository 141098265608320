import { doc, setDoc, getDoc, onSnapshot } from "firebase/firestore";
import { db } from "./Config";


const getDocRef = key => doc(db, `bucket`, key);


export const write = (key, value) => {
    const docRef = getDocRef(key);
    return setDoc(docRef, {value});
};


export const read = async key => {
    const docRef = getDocRef(key);
    const docSnap = await getDoc(docRef);
    if(!docSnap.exists()) return null;
    return docSnap.data()?.value || null;
};


export const listen = (key, callback) => {
    const docRef = getDocRef(key);
    const unsub = onSnapshot(docRef, (doc) => {
        if(!doc.exists()) return callback(null);
        const data = doc.data();
        console.log("Current data: ", data);
        callback(data?.value || null);
    });
    return unsub;
};


