import { useState, useEffect } from "react";
import QueryTable from "../../../DatabaseTable";

const Campaigns = ({profile, start, end}) => {

    return <div>
        <h2> Campaigns </h2>
        <div style={{maxWidth: '75vw'}}>
        <QueryTable
            queryId='campaigns'
            profile={profile}
            start={start}
            end={end}
            showFetchForLater
            showCancel
        />
        </div>
        
    </div>
}


export default Campaigns;