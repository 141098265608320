import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Fab, List, ListItem, ListItemText, Paper, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, TextField, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useEffect, useState } from 'react'
import { Prefix } from '../../Prefix';
import { toast } from 'react-toastify';
import { OpenInNew } from '@mui/icons-material';
import { copy, objectOperations, useLocalAndServerCommitState, useLocalState } from '../../../../util/storage';
import { RotateCcw } from 'lucide-react';
import { isObject } from '../../../../util/util';



const marketplaces = [
    // NA: North America
	{id: 'A2Q3Y263D00KWC', country: 'Brazil', code: 'BR', region: 'NA'},
	{id: 'A2EUQ1WTGCTBG2', country: 'Canada', code: 'CA', region: 'NA'},
	{id: 'A1AM78C64UM0Y8', country: 'Mexico', code: 'MX', region: 'NA'},
	{id: 'ATVPDKIKX0DER', country: 'United States of America', code: 'US', region: 'NA'},
    // EU: Europe
	{id: 'A1PA6795UKMFR9', country: 'Germany', code: 'DE', region: 'EU'},
	{id: 'A1RKKUPIHCS9HS', country: 'Spain', code: 'ES', region: 'EU'},
	{id: 'A13V1IB3VIYZZH', country: 'France', code: 'FR', region: 'EU'},
	{id: 'APJ6JRA9NG5V4', country: 'Italy', code: 'IT', region: 'EU'},
	{id: 'A1805IZSGTT6HS', country: 'Netherlands', code: 'NL', region: 'EU'},
	{id: 'A2NODRKZP88ZB9', country: 'Sweden', code: 'SE', region: 'EU'},
	{id: 'A33AVAJ2PDY3EV', country: 'Turkey', code: 'TR', region: 'EU'},
	{id: 'A1F83G8C2ARO7P', country: 'United Kingdom', code: 'UK', region: 'EU'},
	{id: 'A17E79C6D8DWNP', country: 'Saudi Arabia', code: 'SA', region: 'EU'},
	{id: 'A2VIGQ35RCS4UG', country: 'United Arab Emirates', code: 'AE', region: 'EU'},
    // FE: APAC
	{id: 'A39IBJ37TRP1C6', country: 'Australia', code: 'AU', region: 'FE'},
	{id: 'A1VC38T7YXB528', country: 'Japan', code: 'JP', region: 'FE'},
	{id: 'A21TJRUUN4KGV', country: 'India', code: 'IN', region: 'FE'}
];


const normalToUpdateableObj = obj => {
    const newObj = {};
    for(const key in obj) {
        objectOperations.addValue(newObj, key, obj[key]);
    }
    return newObj; //objectOperations.multiCreate(obj);
    
}

const UpdateableObjToNormal = obj => {
    return Object.keys(obj).reduce((acc, key) => {
        acc[key] = obj[key][key];
        return acc;
    }, {})
}

const tryStringify = j => {
    try{return JSON.stringify(j, undefined, 2);}catch(e){}
    return `${j}`;
}


function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
    <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
    >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
    );
};


function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
};


const spacingStyle = {marginBottom: '10px'};


const CreateInstanceSection = ({temp, setTemp, config, setConfigKey, getConfigKey, save, silentSave, fetchAccounts}) => {
    

    const handleOpen = () => {setTemp(prev=>({...prev, dialogOpen: true}));
        // setConfigKey('dialogOpen', true);silentSave();
    }
    
    const handleClose = () => {setTemp(prev=>({...prev, dialogOpen: false}));
        //setConfigKey('dialogOpen', false);silentSave()
    }

    const keyBlur = silentSave;
    const keydownSave = e => e.key==='Enter' ? e.target.blur() : null;

    const create = async () => {
        // toast('creating: ' + JSON.stringify({
        //     instanceName: temp?.instanceName,  // getConfigKey('instanceName'),
        //     accountId: temp?.accountId,  // getConfigKey('accountId'),
        //     marketplace: temp?.marketplace,  // getConfigKey('marketplace'),
        //     amcId: getConfigKey('amcId'),
        // }));
        // return;
        const promise = async () => {
            const r = await fetch('/amc/create-instance', {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    instanceName: temp?.instanceName,  // getConfigKey('instanceName'),
                    accountId: temp?.accountId,  // getConfigKey('accountId'),
                    marketplace: temp?.marketplace,  // getConfigKey('marketplace'),
                    amcId: getConfigKey('amcId'),
                })
            });
            const data = await r.json();
            if(data?.error) {
                console.error(tryStringify(data))
                throw new Error(data.error);
            }
            
            // setConfigKey('instanceName', '')
            setTemp(prev=>({...prev, instanceName: ''}))
            return data?.data || null;
        };

        toast.promise(promise(), {
            pending: 'Creating instance',
            success: 'Instance created',
            error: 'Error creating instance'
        });
    };

    const ready = () => temp?.instanceName && temp?.accountId && temp?.marketplace?.country && getConfigKey('amcId', '') ? true : false;

    return <>
        <TextField 
            style={spacingStyle} label='Instance Name' 
            value={temp?.instanceName || ''}  // getConfigKey('instanceName', '')} 
            onChange={e=>setTemp(prev=>({...prev, instanceName: e.target.value || ''}))}  // setConfigKey('instanceName', e.target.value || '')}
            onBlur={keyBlur}
            onKeyDown={keydownSave}
        />
        <br/>
        <Button style={spacingStyle} variant='contained' onClick={handleOpen} disabled={!ready()}
        >Create Instance</Button>
        <br/>
        {ready() ? null : <Typography variant='caption' style={{color: 'gray'}}>You may need to open settings</Typography>}
        
        <Dialog
            open={temp?.dialogOpen}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title" style={{fontWeight: 900}}>
                {"Create Instance"}
            </DialogTitle>
            <DialogContent>
                <div>
                {/* <DialogContentText id="alert-dialog-description" component="pre"> */}
                <b>You are about to create an AMC Instance:</b>
                <br/>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Value</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {`Instance Name: ${temp?.instanceName || ''}
                    Account ID: ${temp?.accountId || ''}
                    Marketplace: ${temp?.marketplace?.country || 'Not selected'}
                    AMC ID: ${getConfigKey('amcId', '')}`
                    .split('\n').filter(l=>!!l).map(line=><TableRow><TableCell>{line.split(':')[0].trim()}</TableCell><TableCell>{line.split(':')[1].trim()}</TableCell></TableRow>)} 
                        </TableBody>
                    </Table>
                </TableContainer>
                <br/>
                <span style={{fontWeight: 300, float: 'right'}}>Are you sure?</span>
                {/* </DialogContentText> */}
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} autoFocus>No</Button>
                <Button onClick={()=>{create();handleClose()}}> Yes </Button>
            </DialogActions>
        </Dialog>
        {/* <pre>{tryStringify(config?.dialogOpen?.dialogOpen)}</pre>
        <pre>
            {tryStringify(config)}
        </pre> */}
    </>
};


const AdvertisersSection = ({temp, setTemp, config, setConfigKey, getConfigKey, save, silentSave, fetchAccounts}) => {
    const ready = () => temp?.accountId && temp?.marketplace?.country && getConfigKey('amcId', '') ? true : false;

    useEffect(() => {
        if(ready()) {
            fetchInstances();
        }
    }, [temp?.accountId, temp?.marketplace?.country, config?.amcId?.amcId]);

    // useEffect(() => {
    //     if(ready() && temp?.instance?.instanceId) {
    //         fetchAdvertisrs().then(()=>fetchAdvertisersUpdates())
    //     }
    // }, [temp?.accountId, temp?.marketplace?.country, config?.amcId?.amcId, temp?.instance?.instanceId]);

    useEffect(() => {
        if(temp?.accountId) {
            fetchAdAccounts();
        }
    }, [temp?.accountId]);

    const fetchInstances = async () => {
        fetchEntity(
            '/amc/list-instances',
            {
                accountId: temp?.accountId,  // getConfigKey('accountId'),
                marketplace: temp?.marketplace,  // getConfigKey('marketplace'),
                amcId: getConfigKey('amcId', ''),
            },
            'instances', 'instances', 'instances', 
        )
    };

    const fetchAdvertisrs = async () => {
        fetchEntity(
            '/amc/list-advertisers',
            {
                accountId: temp?.accountId,
                marketplace: temp?.marketplace,
                amcId: getConfigKey('amcId', ''),
                instanceId: temp?.instance?.instanceId,
                isUpdate: false,
            },
            'advertisers', 'advertisers', 'advertisers', 
        )
    };

    const fetchAdvertisersUpdates = async () => {
        fetchEntity(
            '/amc/list-advertisers',
            {
                accountId: temp?.accountId,
                marketplace: temp?.marketplace,
                amcId: getConfigKey('amcId', ''),
                instanceId: temp?.instance?.instanceId,
                isUpdate: true,
            },
            'advertisers', 'advertisersUpdates', 'advertisers updates', 
        )
    };

    const fetchAdAccounts = async () => {
        fetchEntity(
            '/amc/list-ad-accounts',
            {
                accountId: temp?.accountId,
            },
            'accounts', 'adAccounts', 'ad accounts', 
        )
    };

    const fetchEntity = async (endpoint, body, dataKey, storeKey, displayKey) => {
        const promise = async () => {
            const r = await fetch(endpoint, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(body)
            });
            const data = await r.json();

            if(data?.error) {
                console.error(tryStringify(data))
                throw new Error(data.error);
            }
            console.log(data)
            if(Array.isArray(data?.[dataKey])) setTemp(prev=>({...prev, [storeKey]: data[dataKey]}));
            else console.error(tryStringify(data));
        };

        const capitilizeFirstLetter = word => {
            return word.charAt(0).toUpperCase() + word.slice(1);
        }

        toast.promise(promise(), {
            pending: `Fetching ${displayKey}`,
            success: `${capitilizeFirstLetter(displayKey)} fetched`,
            error: `Error fetching ${displayKey}`
        });
    };

    const readyToAddAdvertiser = () => temp?.accountId && temp?.marketplace && getConfigKey('amcId', '') && temp?.instance?.instanceId && temp?.advertiserToCreate?.advertiser?.id && temp?.advertiserToCreate?.type ? true : false;
    
    const addAdvertiser = async () => {
        if(!readyToAddAdvertiser()) return toast('Please select all fields');

        const promise = async () => {
            const r = await fetch('/amc/add-advertiser-to-instance', {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    accountId: temp?.accountId,
                    marketplace: temp?.marketplace,
                    amcId: getConfigKey('amcId', ''),
                    instanceId: temp?.instance?.instanceId,
                    accountEntityId: temp?.advertiserToCreate?.advertiser?.id,
                    advertiserType: temp?.advertiserToCreate?.type,
                })
            });
            const data = await r.json();
            console.log('r', data)
            if(data?.error) {
                console.error(tryStringify(data))
                throw new Error(data.error);
            }
            fetchAdvertisersUpdates();
            return data?.data || null;
        };

        toast.promise(promise(), {
            pending: 'Adding advertiser',
            success: 'Advertiser added',
            error: 'Error adding advertiser'
        });
    }

    if(!ready()) return <Typography variant='caption' style={{color: 'gray'}}>You may need to open settings</Typography>;
    
    if(!Array.isArray(temp?.instances) || temp?.instances.length === 0) return <Typography variant='caption' style={{color: 'gray'}}>No instances found</Typography>;

    // statuses: COMPLETED, PENDING
    const advertiserStatuses = !Array.isArray(temp?.advertisersUpdates) ? [] : temp?.advertisersUpdates.filter(adv=>!['COMPLETED'].includes(adv.status))

    return <>
        <div style={{display: 'flex', flexDirection: 'row'}}>
            <Autocomplete 
                disablePortal
                options={temp?.instances}
                value={temp?.instance}  // getConfigKey('accountId', null)}
                getOptionLabel={option=>`${option?.instanceName} | ${option?.instanceType}`}  // instanceId, instanceType
                onChange={(event, newValue) => {
                    if(newValue) setTemp(prev=>({...prev, instance: newValue}));  // setConfigKey('accountId', newValue)
                    else setTemp(prev=>({...prev, instance: null}));  // setConfigKey('accountId', null);
                }}
                // sx={{ width: 300 }}
                renderInput={(params) => <TextField {...params} label="Instance"/>}
                onClose={()=>{}}
                fullWidth
            />
            <Fab style={{marginTop: '10px', marginBottom: '10px', marginLeft: '10px'}} size='small' onClick={fetchInstances}>
                <RotateCcw/>
            </Fab>
        </div>
        <br/>
        <div style={{display: 'flex', flexDirection: 'row'}}>
            <Autocomplete 
                disablePortal
                options={temp?.adAccounts.reduce((arr, a)=>{ 
                    const name = a?.accountName;
                    if(Array.isArray(a.alternateIds)) a.alternateIds.filter(o=>'entityId' in o && 'countryCode' in o).forEach(o=>{
                        const id = o?.entityId;
                        if(!temp?.marketplace || temp?.marketplace?.code === o.countryCode) arr.push({id, display: `${name} ~ ${o.countryCode}`, ...o});//, account: a});
                    })
                    return arr;
                }, [])}
                value={temp?.advertiserToCreate?.advertiser}
                getOptionLabel={option=>`${option?.display}`}
                onChange={(event, newValue) => {
                    if(newValue) setTemp(prev=>({...prev, advertiserToCreate: {...prev.advertiserToCreate, advertiser: newValue}}));
                    else setTemp(prev=>({...prev, advertiserToCreate: {...prev.advertiserToCreate, advertiser: null}}));
                }}
                // sx={{ width: 300 }}
                renderInput={(params) => <TextField {...params} label="Advertiser"/>}
                onClose={()=>{}}
                fullWidth
            />
            <Fab style={{marginTop: '10px', marginBottom: '10px', marginLeft: '10px'}} size='small' onClick={fetchAdAccounts}>
                <RotateCcw/>
            </Fab>
        </div>
        <br/>
        <Autocomplete 
            disablePortal
            options={["DISPLAY", "SPONSORED_ADS", "SAS"]}
            value={temp?.advertiserToCreate?.type}
            getOptionLabel={option=>`${option}`}
            onChange={(event, newValue) => {
                if(newValue) setTemp(prev=>({...prev, advertiserToCreate: {...prev.advertiserToCreate, type: newValue}}));
                else setTemp(prev=>({...prev, advertiserToCreate: {...prev.advertiserToCreate, type: null}}));
            }}
            // sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="Advertiser Type"/>}
            onClose={()=>{}}
            fullWidth
        />
        <br/>
        <Button variant='contained' onClick={addAdvertiser} disabled={!readyToAddAdvertiser()}>Add Advertiser to Instance</Button>
        <br/>
        
        <br/>
        <div style={{display: 'flex', flexDirection: 'row'}}>
            <div style={{flex: 1}}>
                <div>
                    <Typography variant='caption' style={{color: 'gray'}}>Advertiser Statuses</Typography>
                </div>
                <Fab style={{marginTop: '10px', marginBottom: '10px'}} size='small' onClick={fetchAdvertisersUpdates}>
                    <RotateCcw/>
                </Fab>
                <List>
                    {advertiserStatuses.map((adv, i)=><ListItem key={i}>
                        <ListItemText primary={adv?.status} secondary={'Unknown'} />
                    </ListItem>)}
                </List>
            </div>
            <Divider sx={{marginLeft: 5, marginRight: 5}} flexItem={true} orientation='vertical' variant='fullWidth'/>
            <div style={{flex: 1}}>
                <div>
                    <Typography variant='caption' style={{color: 'gray'}}>Instance Advertisers</Typography>
                </div>
                <Fab style={{marginTop: '10px', marginBottom: '10px'}} size='small' onClick={fetchAdvertisrs}>
                    <RotateCcw/>
                </Fab>
                <List>
                    {(temp?.advertisers || []).map((adv, i)=><ListItem key={i}>
                        <ListItemText primary={adv?.name} secondary={adv?.id} />
                    </ListItem>)}
                </List>
            </div>
        </div>
            
        {/* <pre>
            {tryStringify(temp)}
        </pre> */}
    </>;
};


const InstanceSelection = ({temp, setTemp, getConfigKey, silentSave, fetchAccounts, setConfigKey}) => {
    const keyBlur = silentSave;
    const keydownSave = e => e.key==='Enter' ? e.target.blur() : null;
    return <>
        <Accordion style={spacingStyle}>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            >
            <Typography component="span">Settings</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <div style={{...spacingStyle, display: 'flex', flexDirection: 'row'}}>
                    <Autocomplete 
                        disablePortal
                        options={getConfigKey('accounts', [])}
                        value={temp?.accountId}  // getConfigKey('accountId', null)}
                        getOptionLabel={option=>`${option}`}
                        onChange={(event, newValue) => {
                            if(newValue) setTemp(prev=>({...prev, accountId: newValue}));  // setConfigKey('accountId', newValue)
                            else setTemp(prev=>({...prev, accountId: null}));  // setConfigKey('accountId', null);
                        }}
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="Account"/>}
                        onClose={silentSave}
                    />
                    <Fab style={{marginLeft: '10px'}} size='small' onClick={()=>toast.promise(fetchAccounts(), {
                        pending: 'Fetching accounts',
                        success: 'Accounts fetched',
                        error: 'Error fetching accounts'
                    })}>
                        <RotateCcw/>
                    </Fab>
                </div>
                <Autocomplete 
                    style={spacingStyle}
                    disablePortal
                    options={marketplaces}
                    value={temp?.marketplace}  // getConfigKey('marketplace', null)}
                    getOptionLabel={option=>`${option?.country}`}
                    onChange={(event, newValue) => {
                        if(newValue) setTemp(prev=>({...prev, marketplace: newValue}));  // setConfigKey('marketplace', newValue)
                        else setTemp(prev=>({...prev, marketplace: null}));  // setConfigKey('marketplace', null);
                    }}
                    sx={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} label="Marketplace"/>}
                    onClose={silentSave}
                />
                {/* <Button variant='contained' style={spacingStyle} onClick={save}>Save Settings</Button> */}
                {/* <br/> */}
                <div style={{...spacingStyle, display: 'flex', flexDirection: 'row'}}>
                    <TextField style={spacingStyle} label='AMC Account ID' value={getConfigKey('amcId', '')} onChange={e=>setConfigKey('amcId', e.target.value || '')}
                        onBlur={keyBlur}
                        onKeyDown={keydownSave}
                    />
                    <Fab style={{marginLeft: '10px'}} size='small' onClick={()=>{setConfigKey('amcId', 'ENTITY2CLM116Q37VMR');silentSave();}}>
                        <RotateCcw/>
                    </Fab>
                </div>
                {/* <TextField label='API Client ID' value={getConfigKey('instanceName') || ''} onChange={e=>setConfigKey('clientId', e.target.value || '')}/> */}
                {/* <TextField label='Advertiser Name' value={getConfigKey('instanceName') || ''} onChange={e=>setConfigKey('clientId', e.target.value || '')}/> */}
            </AccordionDetails>
        </Accordion>
    </>;
}


export const Instances = ({}) => {
    const notUsedValues = {awsAccountId: null, s3BucketName: null, acrBacked: null, idempotencyToken: null, advertiserName: null};
    const [temp, setTemp] = useLocalState({
        tab: 0,
        dialogOpen: false,

        instanceName: '', 
        accountId: null,
        marketplace: null,
        instances: [],
        instance: null,
        advertisers: [],
        advertisersUpdates: [],
        advertiser: null,
        advertiserUpdate: null,
        adAccounts: [],

        advertiserToCreate: {
            advertiser: null, marketplace: null, type: 'SPONSORED_ADS'
        },
    }, 'amc-instance-edit-1');

    // const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setTemp(prev=>({...prev, tab: newValue}));
    };

    const [config, setConfig, commit, loading] = useLocalAndServerCommitState(normalToUpdateableObj({
        accounts: [], 
        accountId: null,
        instanceName: '', 
        clientId: '',
        amcId: 'ENTITY2CLM116Q37VMR',
        marketplace: null,
        instances: [],
        dialogOpen: false,
        ...notUsedValues,
    }), `junglr-amc-instance-config`, undefined, undefined, undefined, undefined, 1, (local, server) => {
        const [l, s, obj] = [
            UpdateableObjToNormal(copy(local)), UpdateableObjToNormal(copy(server)), objectOperations.updateObject(server, local)
        ];
        console.log('commiting', l, s, obj);
        return obj;
    });
    const setConfigKey = (key, value, ...args) => setConfig(prev=>{
        if(!objectOperations.isObject(prev[key])) prev[key] = objectOperations.create(key, value);
        // if(isObject(prev[key]) && objectOperations.isObject(prev[key])) 
        prev[key][key] = value;
        // else prev[key] = value;
        return copy(prev);
    }, ...args);
    const getConfigKey = (key, defaultvalue) => config?.[key]?.[key] === undefined 
        ? (defaultvalue === undefined ? '' : defaultvalue) 
        : config[key][key];

    const save = async () => toast.promise(commit(), {
        pending: 'Saving',
        success: 'Saved',
        error: 'Error saving'
    });

    const silentSave = async () => {
        setTimeout(async () => {
            await commit();
        }, 250)
    };

    

    const openLink = async () => {
        if(sessionStorage.getItem('amc-intances-open-link') !== 'true') {
            sessionStorage.setItem('amc-intances-open-link', 'true');
            toast('Make sure to login with support@junglr.com');
            // Wait 3 seconds
            await new Promise(resolve => setTimeout(resolve, 3000));
        }
        const url = getConfigKey('amcId', null)
            ? `https://advertising.amazon.com/marketing-cloud?entityId=${getConfigKey('amcId')}`
            : 'https://advertising.amazon.com/marketing-cloud';
        window.open(url, '_blank');
    };

    const fetchAccounts = async () => {
        const r = await fetch('/accounts', {credentials: 'include'});
        const accounts = (await r.json())?.accounts;
        if(Array.isArray(accounts)) setConfigKey('accounts', accounts, true);
    }

    useEffect(()=>{
        fetchAccounts();
        if(!objectOperations.isObject(config?.dialogOpen)) setConfig(prev=>({...prev, dialogOpen: objectOperations.create('dialogOpen', false)}));
    }, []);


    
    return <>
    
        <Prefix title='AMC Instances' loomLink='https://www.loom.com/share/c590575e802145d3819e4bcc2a897e3e' loomEmbededLink='https://www.loom.com/embed/c590575e802145d3819e4bcc2a897e3e?sid=cdbfd9a4-4615-474f-aee8-ff1c0e47c401' component={<>
            <Fab size='small' onClick={openLink}><OpenInNew/></Fab>
        </>}/>
        <InstanceSelection temp={temp} setTemp={setTemp} getConfigKey={getConfigKey} 
            silentSave={silentSave} fetchAccounts={fetchAccounts} setConfigKey={setConfigKey}/>
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={temp.tab} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Create" {...a11yProps(0)} />
                    <Tab label="Advertisers" {...a11yProps(1)} />
                    {/* <Tab label="Tab 3" {...a11yProps(2)} /> */}
                </Tabs>
            </Box>
            <CustomTabPanel value={temp.tab} index={0}>
                <CreateInstanceSection 
                    temp={temp} config={config}
                    setConfigKey={setConfigKey}
                    getConfigKey={getConfigKey}
                    setTemp={setTemp}
                    save={save} silentSave={silentSave}
                    fetchAccounts={fetchAccounts}
                />
            </CustomTabPanel>
            <CustomTabPanel value={temp.tab} index={1}>
                <AdvertisersSection
                    temp={temp} config={config}
                    setConfigKey={setConfigKey}
                    getConfigKey={getConfigKey}
                    setTemp={setTemp}
                    save={save} silentSave={silentSave}
                    fetchAccounts={fetchAccounts}
                />
            </CustomTabPanel>
            <CustomTabPanel value={temp.tab} index={2}>
                Item Three
            </CustomTabPanel>
        </Box>
        
    </>;
}




export default Instances;


