import { useState, useEffect } from "react";
import QueryTable from "../../../DatabaseTable";


const DailyMetrics = ({profile, start, end}) => {

    return <div>
        <div style={{maxWidth: '75vw'}}>
        <QueryTable 
            name='Daily Metrics'
            displayName
            queryId='daily-metrics'
            profile={profile}
            start={start}
            end={end}
            // extra={[{
            //     field: 'details', headerName: 'Details', width: 150, 
            //     renderCell: params=>(<button onClick={()=>{}}> Details </button>)
            // }]}
            showFetchForLater
            showCancel
            autoFormat
        />
        </div>
        <div style={{maxWidth: '75vw'}}>
        <QueryTable 
            name='Daily Metrics'
            displayName
            queryId='daily-metrics'
            profile={profile}
            start={start}
            end={end}
            args={{ad_types: 'true'}}
            // extra={[{
            //     field: 'details', headerName: 'Details', width: 150, 
            //     renderCell: params=>(<button onClick={()=>{}}> Details </button>)
            // }]}
            showFetchForLater
            showCancel
            autoFormat
        />
        </div>
    </div>
}


export default DailyMetrics;