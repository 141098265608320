import React, { useState } from 'react';
import { TextField, Switch, FormGroup, FormControl, FormControlLabel } from '@mui/material';
import QueryTable from '../../../DatabaseTable';
import {toast} from 'react-toastify';
import { useSessionState } from '../../../../util/util';


const AsinDayByDay = ({profile, start, end}) => {
  const [asin, setReportName] = useSessionState('', 'asin-day-by-day');

  const textFieldStyle = {marginBottom: 15}

  return <div>
    <h2>ASIN Day by Day</h2>

    <TextField label='ASIN' value={asin} onChange={e=>setReportName(e.target.value || '')} style={textFieldStyle}/>
    <br/>

    <div style={{maxWidth: '75vw'}}>
          <QueryTable 
            queryId='asin-day-by-day' 
            showFetchForLater
            autoFormat
            profile={profile} start={start} end={end}
            args={{
              'asin': `${asin}`,
            }}
          />
        </div>
  </div>
}

export default AsinDayByDay;
