import { useState, useEffect } from "react";
// import { LineChart } from '@mui/x-charts/LineChart';
// import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts';
import QueryTable from "../../../DatabaseTable";
import { toast } from "react-toastify";
import { useSessionState } from "../../../../util/util";
import { TextField } from "@mui/material";


const CustomizedAxisTick = (...args)=>{
    const {  x, y, stroke, payload } = args[0];
    return (
        <g transform={`translate(${x},${y})`}>
          <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)">
            {payload.value}
          </text>
        </g>
      );
  };


const TargetAnalysis = ({profile, start, end}) => {
    const [targetId, setTargetId] = useSessionState('', 'TargetAnalysis-targetId');
    const [lineData, setLineData] = useSessionState([], 'TargetAnalysis-cpc-data');
    const [salesData, setSalesData] = useSessionState([], 'TargetAnalysis-sales-data');

    const getData = async (target_id, query_id) => {
        console.log({
            query_id, profile, start, end, 
            args: {target_id: `${target_id}`}
        })
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                query_id, profile, start, end, 
                args: {target_id: `${target_id}`}
            })
        }
        const response = await fetch('/query-table', options);
        const data = await response.json();
        if(data?.table?.length) {
            // setLineData();
            return data.table.map(row=>({...row, date: (new Date(row.date).toLocaleDateString('en-US', { 
                month: 'short', 
                day: 'numeric', 
                year: 'numeric' 
            }))}))
        } else {
            return [];
        }
    }

    const getCPCData = target_id => {
        const promise = async () => {
            setLineData(await getData(target_id, 'target-cpc'));
            setSalesData(await getData(target_id, 'target-sales'));
            // const options = {
            //     method: 'POST',
            //     headers: {
            //         'Content-Type': 'application/json'
            //     },
            //     body: JSON.stringify({
            //         query_id: 'target-cpc',
            //         profile, start, end, 
            //         args: {target_id: `${target_id}`}})
            // }
            // const response = await fetch('/query-table', options);
            // const data = await response.json();
            // console.log('cpc data', !!data?.table?.length, data?.table?.length, data);
            // if(data?.table?.length) {
            //     console.log('table', data.table.map(row=>({...row, date: new Date(row.date)})))
            //     setLineData(data.table.map(row=>({...row, date: (new Date(row.date).toLocaleDateString('en-US', { 
            //         month: 'short', 
            //         day: 'numeric', 
            //         year: 'numeric' 
            //       }))})));//.map(row=>({...row, date: new Date(row.date)})));
            // }
        }

        toast.promise(
            promise(), {
                pending: 'Fetching',
                success: 'Fetched',
                error: 'Failed to fetch'
            }
        )
    }

    const onEnter = e => {
        if(e.key === 'Enter') {
            getCPCData(targetId);
        }
    }

    return <div>
        <h2> Target Analysis </h2>
        <TextField label='Target ID' value={targetId} onChange={e=>setTargetId(e.target.value)} onKeyDown={onEnter}/>
        <br/>
        <button className='btn' onClick={()=>getCPCData(targetId)}>Get CPC Data</button>
        {false //!lineData.length 
        ? null : 
        <div style={{width: '75wv', height: 400, overflowX: 'scroll'}}>
            <LineChart width={window.screen.width * .75} height={300} data={lineData.length ? lineData : [{cpc: 0.50, date: '2024-05-01'}]} margin={{ top: 5, right: 20, bottom: 50, left: 0 }}>
                <Line type="monotone" dataKey="cpc" stroke="#8884d8" />
                <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                <XAxis dataKey="date" tick={<CustomizedAxisTick />} />
                <YAxis />
                <Tooltip />
            </LineChart>
        </div>}
        <div style={{width: '75wv', height: 400, overflowX: 'scroll'}}>
            <LineChart width={window.screen.width * .75} height={300} data={salesData.length ? salesData : [{Sales: 0.50, Orders: 0, ACoS: 0.10, date: '2024-05-01'}]} margin={{ top: 5, right: 20, bottom: 50, left: 0 }}>
                <Line type="monotone" dataKey="Sales" stroke="#8884d8" />
                <Line type="monotone" dataKey="Orders" stroke="#8884d8" />
                <Line type="monotone" dataKey="ACoS" stroke="#8884d8" />
                <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                <XAxis dataKey="date" tick={<CustomizedAxisTick />} />
                <YAxis />
                <Tooltip />
            </LineChart>
        </div>
    </div>
}


export default TargetAnalysis;