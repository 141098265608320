import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CloudUpload } from '@mui/icons-material';
import { useDarkMode } from '../../util/storage';

const UploadRoot = styled('label')(({ theme, isDark }) => ({
  cursor: 'pointer',
  textAlign: 'center',
  display: 'flex',
  '&:hover p, &:hover svg, & img': {
    opacity: 1,
  },
  '& p, & svg': {
    opacity: 0.4,
    color: isDark ? '#fff' : '#000',
  },
  '&:hover img': {
    opacity: 0.3,
  },
}));

const NoMouseEventBox = styled(Box)(({ isDark }) => ({
  pointerEvents: 'none',
  backgroundColor: isDark ? '#333' : '#fff',
  border: `2px dashed ${isDark ? '#666' : '#ddd'}`,
  borderRadius: '8px',
}));

const IconTextBox = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  position: 'absolute',
});

const HiddenInput = styled('input')({
  display: 'none',
});

const ImageContainer = styled(Box)({
  position: 'absolute',
});

export const FileUpload = ({
  inputRef,
  accept,
  imageButton = false,
  hoverLabel = 'Click or drag to upload file',
  dropLabel = 'Drop file here',
  width = '600px',
  height = '100px',
  image: {
    url = '/favicon-32x32.png',
    imageStyle = {
      height: 'inherit',
    },
  } = {},
  onChange,
  onDrop,
}) => {
  const [darkMode] = useDarkMode();
  const [imageUrl, setImageUrl] = useState(url);
  const [labelText, setLabelText] = useState(hoverLabel);
  const [isDragOver, setIsDragOver] = useState(false);
  const [isMouseOver, setIsMouseOver] = useState(false);

  const stopDefaults = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const dragEvents = {
    onMouseEnter: () => {
      setIsMouseOver(true);
    },
    onMouseLeave: () => {
      setIsMouseOver(false);
    },
    onDragEnter: (e) => {
      stopDefaults(e);
      setIsDragOver(true);
      setLabelText(dropLabel);
    },
    onDragLeave: (e) => {
      stopDefaults(e);
      setIsDragOver(false);
      setLabelText(hoverLabel);
    },
    onDragOver: stopDefaults,
    onDrop: (e) => {
      stopDefaults(e);
      setLabelText(hoverLabel);
      setIsDragOver(false);
      if (imageButton && e.dataTransfer.files[0]) {
        setImageUrl(URL.createObjectURL(e.dataTransfer.files[0]));
      }
      onDrop(e);
    },
  };

  const handleChange = (event) => {
    if (imageButton && event.target.files[0]) {
      setImageUrl(URL.createObjectURL(event.target.files[0]));
    }
    onChange(event);
  };
  
  return (
    <>
      <HiddenInput
        ref={inputRef}
        onChange={handleChange}
        accept={accept}
        id="file-upload"
        type="file"
      />

      <UploadRoot
        htmlFor="file-upload"
        {...dragEvents}
        isDark={darkMode}
        sx={{
          '& img': {
            opacity: isDragOver ? 0.3 : 1,
          },
        }}
      >
        <NoMouseEventBox
          width={width}
          height={height}
          isDark={darkMode}
        >
          {imageButton && (
            <ImageContainer height={height} width={width}>
              <img
                alt="file upload"
                src={imageUrl}
                style={{
                  ...imageStyle,
                  filter: darkMode ? 'brightness(0.8)' : 'none',
                }}
              />
            </ImageContainer>
          )}

          {(!imageButton || isDragOver || isMouseOver) && (
            <IconTextBox height={height} width={width}>
              <CloudUpload 
                fontSize="large"
                sx={{ color: darkMode ? '#fff' : '#000' }}
              />
              <Typography color={darkMode ? '#fff' : '#000'}>
                {labelText}
              </Typography>
            </IconTextBox>
          )}
        </NoMouseEventBox>
      </UploadRoot>
    </>
  );
};

export default FileUpload;

// import React from 'react'
// import clsx from 'clsx'
// import { makeStyles, Box, Typography } from '@mui/material'
// import { styled } from '@mui/material/styles';
// import { CloudUpload } from '@mui/icons-material'

// // export type FileUploadProps = {
// //     imageButton?: boolean
// //     accept: string
// //     hoverLabel?: string
// //     dropLabel?: string
// //     width?: string
// //     height?: string
// //     backgroundColor?: string
// //     image?: {
// //         url: string
// //         imageStyle?: {
// //             width?: string
// //             height?: string
// //         }
// //     }
// //     onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
// //     onDrop: (event: React.DragEvent<HTMLElement>) => void
// // }

// const useStyle = makeStyles({
//     root: {
//         cursor: 'pointer',
//         textAlign: 'center',
//         display: 'flex',
//         '&:hover p,&:hover svg,& img': {
//             opacity: 1,
//         },
//         '& p, svg': {
//             opacity: 0.4,
//         },
//         '&:hover img': {
//             opacity: 0.3,
//         },
//     },
//     noMouseEvent: {
//         pointerEvents: 'none',
//     },
//     iconText: {
//         display: 'flex',
//         justifyContent: 'center',
//         flexDirection: 'column',
//         alignItems: 'center',
//         position: 'absolute',
//     },
//     hidden: {
//         display: 'none',
//     },
//     onDragOver: {
//         '& img': {
//             opacity: 0.3,
//         },
//         '& p, svg': {
//             opacity: 1,
//         },
//     },
// });

// export const FileUpload = ({
//     accept,
//     imageButton = false,
//     hoverLabel = 'Click or drag to upload file',
//     dropLabel = 'Drop file here',
//     width = '600px',
//     height = '100px',
//     backgroundColor = '#fff',
//     image: {
//         url = '/favicon-32x32.png',
//         imageStyle = {
//             height: 'inherit',
//         },
//     } = {},
//     onChange,
//     onDrop,
// }) => {
//     const classes = useStyle()
//     const [imageUrl, setImageUrl] = React.useState(url)
//     const [labelText, setLabelText] = React.useState(hoverLabel)
//     const [isDragOver, setIsDragOver] = React.useState(false)
//     const [isMouseOver, setIsMouseOver] = React.useState(false)
//     const stopDefaults = (e) => {
//         e.stopPropagation()
//         e.preventDefault()
//     }
//     const dragEvents = {
//         onMouseEnter: () => {
//             setIsMouseOver(true)
//         },
//         onMouseLeave: () => {
//             setIsMouseOver(false)
//         },
//         onDragEnter: (e) => {
//             stopDefaults(e)
//             setIsDragOver(true)
//             setLabelText(dropLabel)
//         },
//         onDragLeave: (e) => {
//             stopDefaults(e)
//             setIsDragOver(false)
//             setLabelText(hoverLabel)
//         },
//         onDragOver: stopDefaults,
//         onDrop: (e) => {
//             stopDefaults(e)
//             setLabelText(hoverLabel)
//             setIsDragOver(false)
//             if (imageButton && e.dataTransfer.files[0]) {
//                 setImageUrl(URL.createObjectURL(e.dataTransfer.files[0]))
//             }
//             onDrop(e)
//         },
//     }

//     const handleChange = (event) => {
//         if (imageButton && event.target.files[0]) {
//             setImageUrl(URL.createObjectURL(event.target.files[0]))
//         }

//         onChange(event)
//     }

//     return (
//         <>
//             <input
//                 onChange={handleChange}
//                 accept={accept}
//                 className={classes.hidden}
//                 id="file-upload"
//                 type="file"
//             />

//             <label
//                 htmlFor="file-upload"
//                 {...dragEvents}
//                 className={clsx(classes.root, isDragOver && classes.onDragOver)}
//             >
//                 <Box
//                     width={width}
//                     height={height}
//                     bgcolor={backgroundColor}
//                     className={classes.noMouseEvent}
//                 >
//                     {imageButton && (
//                         <Box position="absolute" height={height} width={width}>
//                             <img
//                                 alt="file upload"
//                                 src={imageUrl}
//                                 style={imageStyle}
//                             />
//                         </Box>
//                     )}

//                     {(!imageButton || isDragOver || isMouseOver) && (
//                         <>
//                             <Box
//                                 height={height}
//                                 width={width}
//                                 className={classes.iconText}
//                             >
//                                 <CloudUpload fontSize="large" />
//                                 <Typography>{labelText}</Typography>
//                             </Box>
//                         </>
//                     )}
//                 </Box>
//             </label>
//         </>
//     )
// }
