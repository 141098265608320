import React, { useEffect, useState } from 'react';
import { TextField, Switch, FormGroup, FormControl, FormControlLabel, Autocomplete, Button } from '@mui/material';
import QueryTable from '../../../DatabaseTable';
import {toast} from 'react-toastify';
import { useLocalState, useSessionState } from '../../../../util/util';
import { LoomToolTip } from '../../../../util/helper';


const encode = v => {
  return JSON.stringify({
    start: !v.start ? null : v.start.toISOString(),
    end: !v.end ? null : v.end.toISOString(),
  });
}
const decode = v => {
  v = JSON.parse(v);
  if(v.start) v.start = new Date(v.start);
  if(v.end) v.end = new Date(v.end);
  return v;
}

export const getSheets = async (sheetUrl, callback) => {
  const promise = async () => {
    try {
        console.log('urrl', sheetUrl)
        // Make the POST request to FastAPI
        const response = await fetch('/google-get-sheets', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          credentials: 'include',
          body: JSON.stringify({spreadsheet_url: sheetUrl}),
        //   signal: controller?.signal
        });
  
        if (response.ok) {
          const data = await response.json();
          console.log('data', data);
          if(Array.isArray(data?.sheets)) {
            callback(data.sheets);
          }
        } else {
          console.error(response.statusText);
          throw new Error('Fail.');
          // setUploadStatus("File upload failed.");
        }
      } catch (error) {
        console.error("Error:", error);
        // setUploadStatus("Error uploading file.");
        throw error;
      }
    }

    toast.promise(promise(), {
        pending: 'Getting Sheets...',
        success: 'Sheets Fetched',
        error: 'Sheets Fetch Failed'
    });
};


const JunglrBulk = ({profile, start, end}) => {
  const [spreadsheetId, setSpreadsheetId] = useLocalState('', 'junglr-bulk-spreadsheet-id');
  const [sheet, setSheet] = useSessionState('', 'junglr-bulk-sheet');
  const [sheets, setSheets] = useSessionState([], 'junglr-bulk-sheets');

//   useEffect(() => {
//     let controller = null;new AbortController();
//     if(spreadsheetId) {
//         controller = new AbortController();
//         getSheets(spreadsheetId, controller);
//     }
//     return () => {
//       if(controller) controller?.abort();
//     }
//   }, [spreadsheetId]);

  // Handle file selection
  const getSheets = async (sheetUrl, controller) => {
    const promise = async () => {
      try {
          console.log('urrl', sheetUrl)
          // Make the POST request to FastAPI
          const response = await fetch('/google-get-sheets', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify({spreadsheet_url: sheetUrl}),
          //   signal: controller?.signal
          });
    
          if (response.ok) {
            const data = await response.json();
            console.log('data', data);
            if(Array.isArray(data?.sheets)) {
              setSheets(data.sheets);
            }
          } else {
            console.error(response.statusText);
            throw new Error('Fail.');
            // setUploadStatus("File upload failed.");
          }
        } catch (error) {
          console.error("Error:", error);
          // setUploadStatus("Error uploading file.");
          throw error;
        }
      }

      toast.promise(promise(), {
          pending: 'Getting Sheets...',
          success: 'Sheets Fetched',
          error: 'Sheets Fetch Failed'
      });
  };

  const bulk = async () => {
    const promise = async () => {
        console.log('urrl', spreadsheetId, sheet, profile)
        // Make the POST request to FastAPI
        const response = await fetch('/junglr-bulk', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          credentials: 'include',
          body: JSON.stringify({spreadsheet_url: spreadsheetId, sheet_name: sheet, profile: profile}),
        //   signal: controller?.signal
        });

        if (response.ok) {
          const data = await response.json();
          console.log('data', data);
          if(Array.isArray(data?.sheets)) {
            setSheets(data.sheets);
          }
        } else {
          console.error(response.statusText);
          throw new Error(response.statusText);
        }
    }

    toast.promise(promise(), {
        pending: 'Starting Bulk Operation...',
        success: 'Bulk Operation Started',
        error: 'Bulk Operation Failed'
    });
  };
  
  const openGoogleSheet = row => {
    if(row?.spreadsheet_url) {
      try {
        window.open(row?.spreadsheet_url, '_blank');
      } catch(e) {
        toast.error('Invalid spreadsheet url');
      }
    } else {
        toast.error('Invalid fetch id');
    }
  }

  const textFieldStyle = {marginBottom: 15}

  return <div>
    <div style={{display: 'flex', flexDirection: 'row'}}>
      <h2>Junglr Bulk</h2>

      <button style={{margin: 15}} className='btn' onClick={()=>{
        window.open(
          'https://docs.google.com/spreadsheets/d/1nyJeFSCjjA-NcMbpCt6R5ADeLaf9sjXcsE2WnNxH1pE/edit?gid=0#gid=0', 
          '_blank'
        );
      }}>Learn by Example</button>
      <LoomToolTip 
        title='In depth use' 
        loomLink='https://www.loom.com/share/3e3a73e62e2647b5a3f8f46f8099f76d' 
        loomEmbededLink='https://www.loom.com/embed/3e3a73e62e2647b5a3f8f46f8099f76d?sid=42699065-dcf0-4e0f-9f39-f818b8daff11'
      />
    </div>
    

    <TextField label='Spreadsheet Url' value={spreadsheetId} onChange={e=>setSpreadsheetId(e.target.value || '')} style={textFieldStyle}/>
    <br/>
    <Button onClick={()=>getSheets(spreadsheetId)}>Get Sheets</Button>
    <br/>
    {sheets.length > 0 && <Autocomplete
        disablePortal
        options={sheets}
        value={sheet}
        onChange={(event, newValue) => {
            if(newValue) setSheet(newValue)
            else setSheet('');
        }}
        sx={{ width: 300 }}
        renderInput={(params) => <TextField {...params} label="Sheets" />}
    />}
    <br/>
    <Button style={{marginBottom: 15}} onClick={bulk}>Start Bulk</Button>
    <br/>
    <p>
      Note: Currently only supports updating keywords and targets (SP, SB and SD)
    </p>

    <QueryTable 
        queryId='junglr-bulk' 
        profile={profile} 
        start={start} 
        end={end}
        skip={['spreadsheet_url']}
        extra={[
          {
            field: 'open', headerName: 'Sheet Link', width: 150, 
            renderCell: params=>(<button className='btn' onClick={()=>openGoogleSheet(params.row)}> Open </button>)
          }
        ]}
    />
  </div>
}

export default JunglrBulk;
