import { useState, useEffect, forwardRef, useImperativeHandle, useRef, useCallback, useMemo } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import { newID, useLocalState, useSessionState } from "../../../../util/util";
import { Autocomplete, Button, Fab, TextField, Tabs, Tab, Box, Paper, Divider, Checkbox, FormGroup, FormControlLabel, Accordion, AccordionSummary, Typography, AccordionDetails } from "@mui/material";
import { toast } from "react-toastify";
import QueryTable from "../../../DatabaseTable";
import { LoomToolTip } from "../../../../util/helper";
import { ChevronDown, ChevronUp, Pencil, Plus, Settings, Trash, X, MousePointerClick, ArrowBigLeft, Copy } from "lucide-react";
import { copy, forceArr, getUniqueValues, objectOperations, useLocalAndServerCommitState, useLocalAndServerState } from "../../../../util/storage";
import { DataGrid, useGridApiContext } from "@mui/x-data-grid";
import ClosableWrapper from "../../../ClosableWrapper/ClosableWrapper";
import SingleLineOfCode from "../../../../util/SingleLineOfCode/SingleLineOfCode";

// Tables
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import { AllCommunityModule, ModuleRegistry } from 'ag-grid-community'; 
import { BidSheetSession } from "./Session";
import { ExpandMore } from "@mui/icons-material";
import { Table } from "./Table";
import { tryStringify, typeConvert } from "./util";

// Register all Community features
ModuleRegistry.registerModules([AllCommunityModule]);

// export const typeConvert = {string: 'Text', bool: 'Checkbox', number: 'Decimal Number', integer: 'Whole Number', percent: 'Percent', money: 'Money'};


const GridExample = () => {
    // Row Data: The data to be displayed.
    const [rowData, setRowData] = useState([
        { make: "Tesla", model: "Model Y", price: 64950, electric: true },
        { make: "Ford", model: "F-Series", price: 33850, electric: false },
        { make: "Toyota", model: "Corolla", price: 29600, electric: false },
    ]);

    const CustomButtonComponent = (props) => {
        return <button onClick={() => window.alert('clicked') }>Push Me!</button>;
    };

    // Column Definitions: Defines the columns to be displayed.
    const [colDefs, setColDefs] = useState([
        { field: "make", filter: true, floatingFilter: true, editable: true, cellEditor: 'agSelectCellEditor', cellEditorParams: { values: ['Tesla', 'Ford', 'Toyota'], }, },
        { field: "model" },
        { field: "price", valueFormatter: p => '£' + p.value.toLocaleString() },
        { headerName: "Make & Model", valueGetter: p => p.make + ' ' + p.model},
        { field: "electric" },
        { field: "button", cellRenderer: CustomButtonComponent },
    ]);

    const rowSelection = useMemo(() => { 
        return {
            mode: 'multiRow',
        };
    }, []);

    return (
        <div
            // define a height because the Data Grid will fill the size of the parent container
            style={{ height: 500 }}
        >
            <AgGridReact
                rowData={rowData}
                columnDefs={colDefs}
                rowSelection={rowSelection}
            />
        </div>
    )
}

const hs = 'Product	Entity	Operation	Campaign_ID	Ad_Group_ID	Portfolio_ID	Ad_ID	Keyword_ID	Product_Targeting_ID	Campaign_Name	Ad_Group_Name	Campaign_Name_Informational_only	Ad_Group_Name_Informational_only	Portfolio_Name_Informational_only	Start_Date	End_Date	Targeting_Type	State	Campaign_State_Informational_only	Ad_Group_State_Informational_only	Daily_Budget	SKU	ASIN_Informational_only	Eligibility_Status_Informational_only	Reason_for_Ineligibility_Informational_only	Ad_Group_Default_Bid	Ad_Group_Default_Bid_Informational_only	Bid	Keyword_Text	Match_Type	Bidding_Strategy	Placement	Percentage	Product_Targeting_Expression	Resolved_Product_Targeting_Expression_Informational_only	Impressions	Clicks	Click_through_Rate	Spend	Sales	Orders	Units	Conversion_Rate	ACOS	CPC	ROAS'.split('\t');
let t = 'Sponsored Products	Product Targeting		361575363527099	544867298749862				284222548148650			Catch All - SP A - 112223 - Dwn - JR	Catch All - SP A - 112223 - Dwn - JR					enabled	enabled	enabled							0.17	0.45						loose-match	loose-match	617,505	1,974	0.32%	$852.46	$2,059.37	190	195	10.00%	41.39%	0.43	2.42';
t = t.split('\t');

const cleanNumberString = s => {
    s = s.replace(/,/g, '');
    s = s.replace(/%/g, '');
    s = s.replace(/[^0-9.]/g, '');
    return parseFloat(s);
}

const fixCell = cell => {
    if(typeof cell !== 'string') return '';
    if(cell.includes('\n') && cell.startsWith('"') && cell.endsWith('"')) cell = cell.slice(1, -1);
    return cell; 
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
    <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
    >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
    );
}



const getRow = (row) => {
    row = row.split('\t');
    const r = {};
    hs.forEach((h, i) => {
        r[h] = row[i];
    });
    row = r;
    return {
        'Possible Bid': cleanNumberString(r.Bid),
        'Bid': cleanNumberString(r.Bid),
        'Entity': row.Entity === 'Product Targeting' ? 'Target' : 'Keyword',
        ACoS: cleanNumberString(row.ACOS) / 100,//41.39 / 100,
        Orders: cleanNumberString(row.Orders),//190,
        Impressions: cleanNumberString(row.Impressions),//617505,
        CPC: cleanNumberString(row.CPC),//0.43,
        Clicks: cleanNumberString(row.Clicks),//1974,
        Spend: cleanNumberString(row.Spend),//852.46,
        Sales: cleanNumberString(row.Sales),//2,059.37,
        'Match_Type___Target_Type': row['Product_Targeting_Expression'].toLowerCase().includes('asin') ? 'asin' : row['Match_Type'].toLowerCase(),
    };
    return r;
}

const row = {
    'Possible Bid': 0.45,
    Bid: 0.45,
    'Entity': 'Target',
    ACoS: 41.39 / 100,
    Orders: 190,
    Impressions: 617505,
    CPC: 0.43,
    Clicks: 1974,
    Spend: 852.46,
    'Match_Type___Target_Type': 'auto',
};


const identityHeaders = ['Entity ID', 'Entity', 'ASIN Count Threshold (ASIN only)'];
const configHeaders = ['{ Override Bid }', '{ Do Not Adjust }', 'Target ACoS', 'Bid Ceiling', 'Bid Floor', '"Break Even Bid\nACoS"', "Don't Use Break Even Bid", '"Break Even Bid\nOrder Threshold"', '"Break Even Bid\nClick Threshold"', 'High ACoS Order Threshold', '"ACoS Boost\n(If > Order Threshold)"', '$ Below CPC if Above Order Threshold', '% Below CPC if Bleow Order Threshold', 'Apply Order Thresholds to All Match Types', '% below Target ACoS', 'Low ACoS Order Threshold', '"ACoS Boost\n(If > Order Threshold)"', '"Bid Ceiling Increase\n(if < Order Threshold)"', 'Apply Order Thresholds to All Match Types', '"DON\'T\nRaise Low Impression Keywords"', 'Low Impresion Bid Increase', 'Low Impression Threshold', 'Low Impression CTR Threshold', 'Low Impression Click Threshold', 'DON\'T\nUse Cut off Click Setting', 'Cut-off Click Threshold', 'Cut-off Click Min Spend', 'Cut-off Click Bid', 'Click Threshold 1', 'Threshold 1\n(% below CPC)', 'Clcik Threshold 2\n(Set to CPC'];

const rowToConfig = row => {
    // return {
    //     overrideBid: null,
    //     dontAdjust: false,
    //     targetACoS: .30,
    //     bidCeiling: 5.00,
    //     bidFloor: .02,
    //     breakEven: {
    //         ACoS: 1.00,
    //         dontUse: false,
    //         clicksThreshold: 25,
    //         orderThreshold: 2,
    //     },
    //     highACoS: {
    //         orderThreshold: 3,
    //         ACoSBoost: .05,
    //         // $ Below CPC if Above Order Threshold,
    //         percentageBelowCPC: .20,
    //         applyAllTypes: false,
    //     },
    //     lowACoS: {
    //         belowTarget: .05,
    //         orderThreshold: 3,
    //         ACoSBoost: 0.05,
    //         bidCeilingIncrease: 0.10,
    //         applyAllTypes: false,
    //     },
    //     lowImpression: {
    //         dontUse: false,
    //         bidIncrease: 0.05,
    //         impressionsThreshold: 1000,
    //         ctrThreshold: 0.01,
    //         clicksThreshold: 2,
    //     },
    //     cutOff: {
    //         dontUse: false,
    //         clicksThreshold: 25,
    //         minSpend: 5.00,
    //         bid: 0.17,
    //         clicksThreshold1: 12,
    //         threshold1: 0.10,
    //         clicksThreshold2: 8,
    //     },
    // }
    const r = {
        overrideBid: row['{ Override Bid }'] || null,
        dontAdjust: row['{ Do Not Adjust }'] || false,
        targetACoS: row['Target ACoS'],
        bidCeiling: row['Bid Ceiling'],
        bidFloor: row['Bid Floor'],
        breakEven: {
            ACoS: row['"Break Even Bid\nACoS"'],
            dontUse: row['"Break Even Bid\nACoS"'] === null,
            clicksThreshold: row['"Break Even Bid\nClick Threshold"'],
            orderThreshold: row['"Break Even Bid\nOrder Threshold"'],
        },
        highACoS: {
            orderThreshold: row['High ACoS Order Threshold'],
            ACoSBoost: row['"ACoS Boost\n(If > Order Threshold)"'],
            // $ Below CPC if Above Order Threshold,
            percentageBelowCPC: row['$ Below CPC if Above Order Threshold'],
            applyAllTypes: row['Apply Order Thresholds to All Match Types'],
        },
        lowACoS: {
            belowTarget: row['% below Target ACoS'],
            orderThreshold: row['Low ACoS Order Threshold'],
            ACoSBoost: row['"ACoS Boost\n(If > Order Threshold)"'],
            bidCeilingIncrease: row['"Bid Ceiling Increase\n(if < Order Threshold)"'],
            applyAllTypes: row['Apply Order Thresholds to All Match Types'],
        },
        lowImpression: {
            dontUse: row['"DON\'T\nRaise Low Impression Keywords"'],
            bidIncrease: row['Low Impression Bid Increase'],
            impressionsThreshold: row['Low Impression Threshold'],
            ctrThreshold: row['Low Impression CTR Threshold'],
            clicksThreshold: row['Low Impression Click Threshold'],
        },
        cutOff: {
            dontUse: row['"DON\'T\nUse Cut off Click Setting"'],
            clicksThreshold: row['Cut-off Click Threshold'],
            minSpend: row['Cut-off Click Min Spend'],
            bid: row['Cut-off Click Bid'],
            clicksThreshold1: row['Click Threshold 1'],
            threshold1: row['Threshold 1\n(% below CPC)'],
            clicksThreshold2: row['Clcik Threshold 2\n(Set to CPC)'],
        }
    };
    return r;
}


const getRowConfig = (row, configTable) => rowToConfig(_getRowConfig(row, configTable));


const _getRowConfig = (row, configTable) => {
    const {['ASINs']: asins, ['# of ASINs']: numOfAsins, ['Entity']: entity, ['Campaign ID']: campaignId, ['Ad Group ID']: adGroupId, ['Keyword ID']: keywordId, ['Product Targeting ID']: targetId, ['Targeting ID']: targetingId} = row;
    const asinRows = configTable.filter(r=>r['Entity'] === 'ASIN');
    for (let i = 0; i < asinRows.length; i++) {
        const {['Entity ID']: asin, ['ASIN Count Threshold (ASIN only)']: count} = asinRows[i];
        if(asins.includes(asin) && count <= numOfAsins) return asinRows[i];
    }
    const campaignRows = configTable.filter(r=>r['Entity'] === 'Campaign');
    for (let i = 0; i < campaignRows.length; i++) {
        const {['Entity ID']: id} = campaignRows[i];
        if(id === campaignId) return campaignRows[i];
    }
    const adGroupRows = configTable.filter(r=>r['Entity'] === 'Ad Group');
    for (let i = 0; i < adGroupRows.length; i++) {
        const {['Entity ID']: id} = adGroupRows[i];
        if(id === adGroupId) return adGroupRows[i];
    }
    const targetRows = configTable.filter(r=>r['Entity'] === 'Target');
    for (let i = 0; i < targetRows.length; i++) {
        const {['Entity ID']: id} = targetRows[i];
        if([targetId, targetingId, keywordId].includes(id)) return targetRows[i];
    }
    return configTable[0];
}

const c = {
    overrideBid: null,
    dontAdjust: false,
    targetACoS: .30,
    bidCeiling: 5.00,
    bidFloor: .02,
    breakEven: {
        ACoS: 1.00,
        dontUse: false,
        clicksThreshold: 25,
        orderThreshold: 2,
    },
    highACoS: {
        orderThreshold: 3,
        ACoSBoost: .05,
        // $ Below CPC if Above Order Threshold,
        percentageBelowCPC: .20,
        applyAllTypes: false,
    },
    lowACoS: {
        belowTarget: .05,
        orderThreshold: 3,
        ACoSBoost: 0.05,
        bidCeilingIncrease: 0.10,
        applyAllTypes: false,
    },
    lowImpression: {
        dontUse: false,
        bidIncrease: 0.05,
        impressionsThreshold: 1000,
        ctrThreshold: 0.01,
        clicksThreshold: 2,
    },
    cutOff: {
        dontUse: false,
        clicksThreshold: 25,
        minSpend: 5.00,
        bid: 0.17,
        clicksThreshold1: 12,
        threshold1: 0.10,
        clicksThreshold2: 8,
    },
};


const getType = row => {
    const config = c;
    const {breakEven} = config;
    const {lowACoS} = config;
    const {highACoS} = config;
    const {cutOff} = config;
    const {lowImpression} = config;
    const calculatedBid = row["Possible Bid"];
    
    // =iferror(IFS(B2="", "", 
    if(row["Entity"] !== 'Keyword' && row["Entity"] !== 'Target') return '';
    
    // ifs(D2="KW", index('🧮'!$R:$R,Match(B2, '🧮'!$C:$C, 0)),D2 ="AG",index('🧮'!$R:$R,Match(L2, '🧮'!$C:$C, 0)) , D2 ="Cam",index('🧮'!$R:$R,Match(K2, '🧮'!$C:$C, 0)), D2="Port", index('🧮'!$R:$R,Match(A2, '🧮'!$C:$C, 0)), D2="Act", False)=True, "No_adj",
    if(config.dontAdjust) return ''

    // '🧮'!$Q$3<>"", '🧮'!$Q$3,
    if(config.overrideBid) return config.overrideBid;

    // and(AY2>='🧮'!$V$3, AV2<='🧮'!$X$3, AR2>='🧮'!$Y$3, '🧮'!$W$3=False), "BE_bid",
    if(row.ACoS >= breakEven.ACoS && row.Orders <= breakEven.orderThreshold && row.Clicks >= breakEven.clicksThreshold && !breakEven.dontUse) return "BE_bid";

    // and(sum(AY2+'🧮'!$AE$3)<=E2,AY2>0,if('🧮'!$AI$3=False, or(AK2="exact", AK2="phrase", isnumber(search("asin=",AP2))), B2<>""),AV2>='🧮'!$AF$3), "LA_hp",
    const type = row['Match_Type___Target_Type'];  // Match_Type || Resolved_Product_Targeting_Expression_Informational_only;
    if((lowACoS.belowTarget + row.ACoS) <= config.targetACoS && row.ACoS > 0 && (lowACoS.applyAllTypes || ['exact', 'phrase', 'asin'].includes(type)) && row.Orders>=lowACoS.orderThreshold) return "LA_hp";

    // and(sum(AY2+'🧮'!$AE$3)<=E2, AY2>0), "LA_lp",
    if((lowACoS.belowTarget + row.ACoS) <= config.targetACoS && row.ACoS > 0) return "LA_lp";

    // and(C2>=AZ2,AY2>E2,if('🧮'!$AD$3=False, or(AK2="exact", AK2="phrase", isnumber(search("asin=",AP2))), B2<>""),AV2>='🧮'!$Z$3), "HA_hp",
    if(calculatedBid >= row.CPC && row.ACoS > config.targetACoS && (highACoS.applyAllTypes || (['exact', 'phrase', 'asin'].includes(type))) && row.Orders >= highACoS.orderThreshold) return "HA_hp";

    // and(C2>=AZ2,AY2>E2), "HA_lp",
    if(calculatedBid >= row.CPC && row.ACoS > config.targetACoS && (!highACoS.applyAllTypes || row.Orders >= highACoS.orderThreshold)) return "HA_lp";

    // and(C2>=AZ2,AV2=0,'🧮'!$AO$3=False, AR2>='🧮'!$AP$3, AT2>='🧮'!$AQ$3), "CL_Coff",
    if(calculatedBid >= row.CPC && row.Orders === 0 && !cutOff.dontUse && row.Clicks >= cutOff.clicksThreshold && row.Spend >= cutOff.minSpend) return "CL_Coff";

    // and(C2>=AZ2,AV2=0,AR2>='🧮'!$AS$3), "CL_t1", 
    if(calculatedBid >= row.CPC && row.Orders === 0 && row.Clicks >= cutOff.clicksThreshold1) return "CL_t1";

    // and(C2>=AZ2,AV2=0,AR2>='🧮'!$AU$3), "CL_t2", 
    if(calculatedBid >= row.CPC && row.Orders === 0 && row.Clicks >= cutOff.clicksThreshold2) return "CL_t2";

    // and(AV2=0,AR2 =0, AQ2<='🧮'!$AL$3, '🧮'!$AJ$3=False), "IM_nc", 
    if(row.Orders === 0 && row.Clicks === 0 && row.Impressions <= lowImpression.impressionsThreshold && !lowImpression.dontUse) return "IM_nc";

    // and(AV2=0, AQ2<='🧮'!$AL$3,'🧮'!$AJ$3=False, AR2<='🧮'!$AN$3,AS2>='🧮'!$AM$3), "IM_wc"), "None")
    if(row.Orders === 0 && row.Impressions <= lowImpression.impressionsThreshold && !lowImpression.dontUse && row.Clicks <= lowImpression.clicksThreshold && (row.Clicks / row.Impressions) >= lowImpression.ctrThreshold) return "IM_wc";

    return "None";
};



const getBid = (row, type) => {
    const config = c;
    const {breakEven} = config;
    const {lowACoS} = config;
    const {highACoS} = config;
    const {cutOff} = config;
    const {lowImpression} = config;

    const bid = row.Bid;  
    

    if(row["Entity"] !== 'Keyword' && row["Entity"] !== 'Target') return '';

    if(type === 'IM_nc' || type === 'IM_wc') return Math.min(bid + lowImpression.bidIncrease, config.bidCeiling);

    if(type === 'LA_hp') return Math.min(((1.0 - row.ACoS + (lowACoS.ACoSBoost + config.targetACoS)) * bid), config.bidCeiling);

    if(type === 'LA_lp') return Math.min(((1.0 - row.ACoS + config.targetACoS) * bid), config.bidCeiling, lowACoS.bidCeilingIncrease + bid);

    if(typeof type === 'number') return type;

    if(type === 'BE_bid') return (row.Sales * config.targetACoS) / row.Clicks;

    if(type === 'CL_Coff') return cutOff.bid;

    if(type === 'CL_t1') return Math.max(((1.0 - cutOff.threshold1) * (bid < row.CPC ? bid : row.CPC)), config.bidFloor);

    if(type === 'CL_t2') return Math.max(Math.min(row.CPC, (bid - 0.02)), config.bidFloor);

    if(type === 'HA_hp') return Math.min(Math.max(((1.0 - row.Orders + (highACoS.ACoSBoost + config.targetACoS)) * bid), Math.min(bid, row.CPC) - highACoS.percentageBelowCPC, config.bidFloor), bid);

    if(type === 'HA_lp') return Math.max(((1.0 - row.Orders + config.targetACoS) * bid), (Math.min(bid, row.CPC) * (1.0 - highACoS.percentageBelowCPC)), config.bidFloor);

    return '';
}



const s = `Sponsored Products	Product Targeting		361575363527099	544867298749862				284222548148650			Catch All - SP A - 112223 - Dwn - JR	Catch All - SP A - 112223 - Dwn - JR					enabled	enabled	enabled							0.17	0.45						loose-match	loose-match	617,505	1,974	0.32%	$852.46	$2,059.37	190	195	10.00%	41.39%	0.43	2.42
Sponsored Products	Product Targeting		361575363527099	544867298749862				556998549183088			Catch All - SP A - 112223 - Dwn - JR	Catch All - SP A - 112223 - Dwn - JR					enabled	enabled	enabled							0.17	0.35						substitutes	substitutes	324,550	804	0.25%	$266.17	$711.15	60	60	7.00%	37.43%	0.33	2.67
Sponsored Products	Product Targeting		361575363527099	544867298749862				531225725661063			Catch All - SP A - 112223 - Dwn - JR	Catch All - SP A - 112223 - Dwn - JR					enabled	enabled	enabled							0.17	0.68						close-match	close-match	1,138,801	2,519	0.22%	$1,626.13	$3,790.79	360	364	14.00%	42.90%	0.65	2.33
Sponsored Products	Product Targeting		361575363527099	544867298749862				399052716376331			Catch All - SP A - 112223 - Dwn - JR	Catch All - SP A - 112223 - Dwn - JR					enabled	enabled	enabled							0.17	0.66						complements	complements	141,707	67	0.05%	$39.89	$333.50	11	14	16.00%	11.96%	0.60	8.36
Sponsored Products	Keyword		187479077246448	180680859017963			457769478046608				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	0.85	rubber seals tumbler lid	Exact						21	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			391266512336855				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.55	yeti 30 oz lid replacement rings	Exact						0	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			317540397168273				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.74	yeti sliding lid	Exact						322	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			383340131319752				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.55	lid for 30 oz ozark cup	Exact						0	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			448640027650210				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.55	yeti rambler 30 oz lid rubber seal	Exact						0	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			454778118516077				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.55	gasket for insulated yeti containers	Exact						0	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			332326639811036				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.55	yeti brand lids 30 oz seal	Exact						0	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			543331212204752				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.55	yeti magslider lid ring 30 oz	Exact						0	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			302981561885207				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.74	replacement gasket for lid	Exact						112	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			308098290409594				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.74	built tumbler replacement lid 30 oz	Exact						44	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			306800719979336				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.55	yeti authorized gasket	Exact						0	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			318603711825748				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.55	reduce tumbler replacement lid seal	Exact						0	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			435660153365370				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.55	insulated tumbler replacement lid gasket	Exact						0	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			515595243724361				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.35	ozark trail tumbler lids 30 oz	Exact						0	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			289685285202885				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.61	c&berg bubba cup replacement gasket	Exact						405	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			361203759118136				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.55	silicone gaskets for tumbler lids	Exact						0	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			273044572969031				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.74	bubba cup replacement gasket 24 oz	Exact						38	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			517095931851142				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.55	yeti tumbler 30oz gasket	Exact						0	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			264594899396003				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.55	coffee tumbler replacement rubber seal	Exact						0	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			522350254094144				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.65	yeti lid 30 oz tumbler	Exact						628	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			167318057807339				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.55	yeti tumbler lid 30 oz replacement gasket	Exact						0	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			299994678838053				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.74	gasket seals for cups	Exact						6	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			361095759334900				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.58	ozark trail replacement lid gasket	Exact						4	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			374496314178513				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.55	rubber gasket for yeti chug top	Exact						0	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			449183136820977				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.71	yeti seals for 30 oz lids	Exact						0	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			377566460276011				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.55	rubber lid seal replacement tumbler cold-1	Exact						0	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			349607762326811				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.57	rubber gasket for yeti lids	Exact						160	2	1.25%	$2.23	$0.00	0	0	0.00%	0.00%	1.12	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			201091137486367				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.55	yeti lid 20 replacement lid	Exact						0	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			171789063335564				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.74	replacement rubber	Exact						33	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			494659294692983				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.68	gasket coffee mug	Exact						0	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			447152112529733				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.74	insulated tumbler ozark trail	Exact						84	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			294435646765939				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.55	tumbler travel mug seals	Exact						0	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			533806960640531				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.55	replacement seals for 30 oz tervis luds	Exact						0	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			476635174624494				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.55	smart cooler lids 30 oz	Exact						0	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			526520387610448				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.55	replacement sealing ring for stainless steel cup	Exact						0	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			416974449827894				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.55	authentic yeti gaskets	Exact						0	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			259117638035976				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.64	yeti lids 28 oz replacement lid	Exact						3	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			536156525149887				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.55	seal for 30 ounce yeti tumbler	Exact						0	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			559425434005215				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.69	contigo coffee mug seal	Exact						3	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			482530601248886				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.55	yeti coffee seal rubber ring	Exact						0	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			297381504703933				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.62	yeti 30 oz lid seal	Exact						14	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			178063431511987				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.69	replacement lid rings	Exact						0	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			428149551963391				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.55	yeti replacement ring 30 oz	Exact						0	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			524453446186716				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.55	yeti lid gasket 26	Exact						0	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			293945934498387				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.69	spill proof 30 oz tumbler lid	Exact						222	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			321757773262510				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.55	insulated travel tumblers plastic seal	Exact						0	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			109453603512928				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.74	4 inch seal ring	Exact						1	1	100.00%	$1.65	$0.00	0	0	0.00%	0.00%	1.65	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			346205579707811				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.55	contigo 30 lid rubber seal	Exact						0	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			314928223735279				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.55	contigo snapseal replacement lid two pack	Exact						0	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			154950709190248				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.55	yeti tumbler lid topfinano	Exact						0	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			352451221860992				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.55	replacement rubber lid seals for tervis	Exact						0	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			110482942498582				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.74	thermos ring replacement parts	Exact						2	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			288999981594864				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.55	h2x tumbler 30oz lid gasket	Exact						0	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			283554582081064				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.56	cup gaskets	Exact						62	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			347198459520183				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.58	yeti tumbler lid 30 oz gasket	Exact						0	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			434336963847043				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.10	rubber seal yeti	Exact						0	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			393772517232858				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.55	original yeti lid gasket replacement 30 oz	Exact						0	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			549217346165052				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.55	yeti rambler lid silicone seal	Exact						0	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			486283664515161				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.71	yeti tumbler lid ring	Exact						1	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			350123991739091				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.55	seal rings for thermal cups	Exact						0	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			313670183215304				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.74	yeti 30 oz tumbler replacement lid	Exact						15	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			562058750922583				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.55	yeti mug lid seals	Exact						0	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			532726511288784				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.74	seal for tumbler	Exact						76	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			447920306768175				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.55	yeti tumbler 30oz replacement rings	Exact						0	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			422843566907801				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.55	built tumbler gasket replacement	Exact						0	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			560665879450962				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.55	tervis 30 oz stainless lid gasket	Exact						0	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			478619952643835				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.65	30oz yeti lid seal	Exact						3	1	33.33%	$1.06	$9.45	1	1	100.00%	11.22%	1.06	8.92
Sponsored Products	Keyword		187479077246448	180680859017963			479415436684036				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.55	yeti 30 oz rambler rings	Exact						0	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			416195479673644				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.69	replacement seal yeti	Exact						103	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			295473838982804				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.74	rubber cup seals	Exact						21	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			483415010554731				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.55	rings for plastic 30 oz lids	Exact						0	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			338581876625825				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.55	yeti seal rambler lid	Exact						0	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			462409805076733				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.66	replacement seal insulated cup	Exact						85	1	1.18%	$1.43	$0.00	0	0	0.00%	0.00%	1.43	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			287879942252882				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.71	replacement gaskets for yeti cups	Exact						0	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			483973330008239				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.65	yeti rubber lid	Exact						0	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			429638683638095				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.55	yeti rubber 30oz seal	Exact						0	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			446344203196049				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.55	yetti 30 oz lid seal	Exact						0	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			512980699856661				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.14	yeti o ring	Exact						51	1	1.96%	$0.47	$9.45	1	1	100.00%	4.97%	0.47	20.11
Sponsored Products	Keyword		187479077246448	180680859017963			107155784723867				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.77	yeti cup rubber seal	Exact						0	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			464409707648482				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.55	ozark trail tumbler 30 oz seal	Exact						0	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			344363466760857				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	0.69	gasket	Exact						284	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			391109480551433				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.95	yeti 30 oz gasket	Exact						84	4	4.76%	$4.95	$28.35	3	3	75.00%	17.46%	1.24	5.73
Sponsored Products	Keyword		187479077246448	180680859017963			275100998711574				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.55	yeti cup gasket 10	Exact						0	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			90938994468389				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.65	thermos ring replacement	Exact						0	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			10268829900838				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.55	yeti o ring replacement 20 oz	Exact						0	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			339786744891631				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.74	30 oz tumbler lid gasket	Exact						83	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			473022123503183				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.58	30 oz tumbler replacement seals	Exact						0	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			327233268757975				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.55	replacement lid seal for built mug	Exact						0	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			325863225340866				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.50	yeti cup 30 oz. lid seal	Exact						0	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			479537955205412				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.55	yeti 30 oz star lis	Exact						0	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			409737898069760				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.55	30oz yeti tumbler lid rubber seal	Exact						0	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			475070714869703				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.55	stainless lids replacement	Exact						0	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			479110304546132				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.21	yeti tumbler gasket	Exact						188	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			398798124054065				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.55	30 ounce tumbler seal	Exact						0	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			33127207199944				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.55	yeti lid gasket replacement 20 oz	Exact						0	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			374009479397741				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.04	yeti lid rubber gasket	Exact						36	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			222537738691486				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.68	30 oz replacement rubber lid seals	Exact						42	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			353803285458082				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.61	yeti 30 oz tumbler lid magnet	Exact						8	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			537750988086818				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.55	tumbler replacement top seal	Exact						0	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			438465413725300				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.55	bamboo tumbler lid seal	Exact						0	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			283439839655639				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.58	rubber seals for yeti cup lids	Exact						392	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			473208158592068				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.75	yeti rambler 30oz lid rubber gasket replacement	Exact						57	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			324534561876489				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.17	tumbler lid gasket replacement	Exact						1,000	3	0.30%	$2.87	$9.45	1	1	33.00%	30.37%	0.96	3.29
Sponsored Products	Keyword		187479077246448	180680859017963			497276441780218				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	2.33	yeti rambler gasket replacement	Exact						111	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			551317013790771				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.61	yeti tumbler gasket replacement	Exact						1	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			330732367489112				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.55	portable coffee mug gasket replacement	Exact						0	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			445411618125996				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.55	mossy oak replacement rubber lid seals	Exact						0	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			469695598374144				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.79	yeti ring	Exact						63	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			484342902522342				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.55	trevi lid gasket	Exact						0	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			546706486995475				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.55	sealing lid for stainless steal cup	Exact						0	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			515650576374311				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.55	yeti mag slider lid seal	Exact						0	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			438406395695545				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.65	yeti cup gasket	Exact						321	1	0.31%	$1.53	$9.45	1	1	100.00%	16.19%	1.53	6.18
Sponsored Products	Keyword		187479077246448	180680859017963			297399820727608				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.64	replacement seals for yeti lids	Exact						14	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			542113332731968				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.55	reduce insulated tumbler lid gasket	Exact						0	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			287707387769508				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.55	travel mug gasket replacement	Exact						0	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			296643675832451				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.55	replacement gasket for 30 oz yukon lid	Exact						0	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			470770623267920				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.55	homitt tumbler lids	Exact						0	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			324846799558505				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.64	yeti lid ring	Exact						58	1	1.72%	$1.47	$9.45	1	1	100.00%	15.56%	1.47	6.43
Sponsored Products	Keyword		187479077246448	180680859017963			485342815411617				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.55	yeti tumbler 30oz lid gasket	Exact						0	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			307747862390654				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.62	yeti tumbler seals	Exact						39	1	2.56%	$1.62	$0.00	0	0	0.00%	0.00%	1.62	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			355670127993071				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.55	contigo mug seal replacement	Exact						0	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			416602165339754				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.55	plastic rings for tumbler lids	Exact						0	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			510458845721495				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.74	yeti colster lid replacement	Exact						34	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			313394515031177				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.69	yeti top seal	Exact						2	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			370754514451850				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.74	cup ring replacement	Exact						54	1	1.85%	$0.24	$0.00	0	0	0.00%	0.00%	0.24	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			384590681506177				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.61	yeti mug lid seal	Exact						10	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			402213218758563				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.55	ozark mountain lid gaskets	Exact						0	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00
Sponsored Products	Keyword		187479077246448	180680859017963			198720370769655				30 oz 4 black SR - SP E - Dwn - LG	30 oz 4 black SR - SP E - Dwn - LG	30 oz SR				enabled	enabled	enabled							0.80	1.61	replacement rubber lid seals o shaped	Exact						0	0	0.00%	$0.00	$0.00	0	0	0.00%	0.00%	0.00	0.00`.split('\n');



// s.forEach(line=>{
//     console.log(getType(getRow(line)), getBid(getRow(line), getType(getRow(line))));
// });

// console.log(
//     'sum', s.map((line, i)=>{
//         const v = getBid(getRow(line), getType(getRow(line)));
//         if(typeof v === 'number') return v;
//         const r = getRow(line);
//         console.log('bid not a number', v, r, i, getType(getRow(line)));
//         return 0;
//     }).reduce((a,b)=>a+b)
// );


const addLocalItems = (localArr, serverArr) => {
    const serverIds = serverArr.map(item2=>item2.id);
    // const localIds = localArr.map(item=>item.id);
    localArr.filter(item=>!serverIds.includes(item.id)).forEach(item=>{
        if(!item.deleted) {
            serverArr.push(item);
        }
    });
    localArr.filter(item=>serverIds.includes(item.id)).forEach(item=>{
        const serverItem = serverArr.find(item2=>item2.id===item.id);
        const serverItemIndex = serverArr.findIndex(item2=>item2.id===item.id);
        if(item.lastUpdated > serverItem.lastUpdated) {
            if(item.deleted) {
                // serverArr = serverArr.filter(item2=>item2.id!==item.id);
                const index = serverArr.findIndex(item2=>item2.id===item.id);
                if(index !== -1) {
                    serverArr.splice(index, 1);
                }
            } else {
                serverArr[serverItemIndex] = item;
            }
        } else {
            if(serverItem.deleted) {
                // serverArr = serverArr.filter(item2=>item2.id!==item.id);
                const index = serverArr.findIndex(item2=>item2.id===item.id);
                if(index !== -1) {
                    serverArr.splice(index, 1);
                }
            }
        }
    });
}

const commitMerge = (serverKey, keys, combine) => {
    const forceArr = arr => !Array.isArray(arr) ? [] : arr;
    return [serverKey, undefined, undefined, true, undefined, 1, (local, server)=>{
        keys.forEach(key=>{
            local[key] = forceArr(local[key]);
            server[key] = forceArr(server[key]);
            addLocalItems(local[key], server[key]);
            server[key] = server[key].filter(item=>!item.deleted);
        });
        if(combine) {
            const skipKeys = ['id', 'lastUpdated', 'updated', 'deleted', 'delete', ...keys];
            const uniqueKeys = getUniqueValues(Object.keys(local), Object.keys(server)).filter(key=>!skipKeys.includes(key));
            const best = (local.updated || 0) > (server.updated || 0) ? local : server;
            const other = (local.updated || 0) > (server.updated || 0) ? server : local;
            uniqueKeys.forEach(k=>{
                if(best[k]) server[k] = best[k];
                else server[k] = other[k];
            });
        }
        return server;
    }]
};

const fabStyle = {marginLeft: 15, marginTop: 2};

const allowedChars = '_qwertyuiopasdfghjklzxcvbnm' + 'qwertyuiopasdfghjklzxcvbnm'.toUpperCase();
const fixVarString = v => typeof v !== 'string' ? '' : v.replace(' ', '_').split('').filter(c=>allowedChars.includes(c)).join('');
            
const EditField = ({setConfig, valueKey, ...params}) => {
    const [value, setValue] = useState(params.row[valueKey ? valueKey : 'value'] || '');

    const { id, field, [[valueKey ? valueKey : 'value']]: v } = params;

    const apiRef = useGridApiContext();

    const handleChange = useCallback(
        async e=>{
            const allowedChars = '_qwertyuiopasdfghjklzxcvbnm' + 'qwertyuiopasdfghjklzxcvbnm'.toUpperCase();
            const fixValue = v => typeof v !== 'string' ? '' : v.replace(' ', '_').split('').filter(c=>allowedChars.includes(c)).join('');
            const val = fixValue(e.target.value);
            setValue(val);
            setConfig(prev=>{
                prev.configMap = forceArr(prev.configMap);
                // prev.configMap[index].value = fixValue(e.target.value);
                const item = prev.configMap.find(item=>item.id===params.row.id);
                // console.log('item', item, params?.row?.id, params?.row, params)
                if(item) {item[valueKey ? valueKey : 'value'] = val;}
                if(params.row) {params.row[valueKey ? valueKey : 'value'] = val;}
                return copy(prev);
            });

            await apiRef.current.setEditCellValue({ id, field, value: val }, e);
            // apiRef.current.stopCellEditMode({ id, field });
        },
        [apiRef, field, id],
    );

    return <TextField autoFocus label='Value' value={value} onChange={handleChange}/>
};


const EditHeader = ({row, i, setConfig}) => {
    const [editing, setEditing] = useState(false);

    if(!editing) return <div key={`row-values-${i}`}>
        <div style={{display: 'flex', alignItems: 'center'}}>
            <h2 style={{margin: 5}}>{row?.value || ''}</h2>
            <p>{'[' + row.headers.filter(h=>typeof h === 'string').join(',') + ']'}</p>
            {/* <Button sx={{margin: 5}} onClick={()=>{setEditing(true)}}>Edit</Button> */}
            <Fab size='small' style={fabStyle} onClick={()=>setEditing(true)}><Pencil/></Fab>
            <Fab size='small' style={fabStyle} onClick={()=>{
                setConfig(prev=>{
                    prev.tableMap = forceArr(prev.tableMap);
                    up(prev.tableMap, item=>item.id===row.id);
                    return copy(prev);
                });
            }}><ChevronUp/></Fab>
            <Fab size='small' style={fabStyle} onClick={()=>{
                setConfig(prev=>{
                    prev.tableMap = forceArr(prev.tableMap);
                    down(prev.tableMap, item=>item.id===row.id);
                    return copy(prev);
                });
            }}><ChevronDown/></Fab>
            <Fab size='small' style={fabStyle} onClick={()=>{
                setConfig(prev=>{
                    prev.tableMap = forceArr(prev.tableMap);
                    // prev.tableMap.splice(i, 1);
                    const index = prev.tableMap.findIndex(item=>item.id===row.id);
                    if(index !== -1) prev.tableMap.splice(index, 1);
                    return copy(prev);
                })
            }}><Trash/></Fab>
        </div>
        <Divider sx={{margin: 1}} variant="middle"/>
    </div>;

    return <div key={`row-values-${i}`}>
        <Fab size='small' onClick={()=>setEditing(false)}><ArrowBigLeft/></Fab>
        <div style={{display: 'flex', alignItems: 'center'}}>
            <TextField label='Value Name' sx={{width: '95%', margin: 5}} value={row?.value || ''} onChange={e=>setConfig(prev=>{
                prev.tableMap = forceArr(prev.tableMap);
                // prev.tableMap[index].value = e.target.value;
                const item = prev.tableMap.find(item=>item.id===row.id);
                if(item) item.value = textToVariableName(e.target.value || '');
                return copy(prev);
            })}/>
            <Divider orientation="vertical" flexItem />
            <div style={{marginRight: 10}}>
                <h2> Possible Headers </h2>
                {row.headers.map((header, j)=>{
                    return <ClosableWrapper 
                                onClose={()=>{
                                    setConfig(prev=>{
                                        prev.tableMap = forceArr(prev.tableMap);
                                        // prev.tableMap[index].headers.splice(j, 1);
                                        const item = prev.tableMap.find(item=>item.id===row.id);
                                        if(item) item.headers.splice(j, 1);
                                        return copy(prev);
                                    })
                                }}
                            
                                bottomLeft={()=>{
                                    // Up
                                    setConfig(prev=>{
                                        prev.tableMap = forceArr(prev.tableMap);
                                        // prev.tableMap = up(prev.tableMap, item=>item.id===row.id);
                                        const item = prev.tableMap.find(item=>item.id===row.id);
                                        if(item) up(item.headers, h=>h===header);
                                        return copy(prev);
                                    })
                                }}
                                bottomRight={()=>{
                                    // Down
                                    setConfig(prev=>{
                                        prev.tableMap = forceArr(prev.tableMap);
                                        // prev.tableMap = down(prev.tableMap, item=>item.id===row.id);
                                        const item = prev.tableMap.find(item=>item.id===row.id);
                                        if(item) down(item.headers, h=>h===header);
                                        return copy(prev);
                                    })
                                }}
                                bottomLeftContent={<>&#8679;</>}
                                bottomRightContent={<>&#8681;</>}
                            >
                                <TextField key={`header-${i}-${j}`} label='Possible Header' style={{marginRight: 10, margin: 5}} value={header} onChange={e=>setConfig(prev=>{
                                    prev.tableMap = forceArr(prev.tableMap);
                                    // prev.tableMap[index].value = e.target.value;
                                    const item = prev.tableMap.find(item=>item.id===row.id);
                                    if(item) item.headers[j] = e.target.value || '';
                                    return copy(prev);
                                })}/>
                            </ClosableWrapper>
                })}
                <Fab size='small' onClick={()=>{
                    setConfig(prev=>{
                        prev.tableMap = forceArr(prev.tableMap);
                        // prev.tableMap[index].headers.push('');
                        const item = prev.tableMap.find(item=>item.id===row.id);
                        if(item) item.headers.push('');
                        return copy(prev);
                    })
                }}><Plus/></Fab>
            </div>
        </div>
        <Autocomplete 
            disablePortal
            options={Object.keys(typeConvert).map(k=>k)}
            value={row?.type || 'number'}
            getOptionLabel={option=>typeConvert[option || 'number']}
            onChange={(e, newValue) => {
                setConfig(prev=>{
                    prev.tableMap = forceArr(prev.tableMap);
                    
                    const item = prev.tableMap.find(item=>item.id===row.id);
                    if(item) item.type = newValue;
                    return copy(prev);
                });
            }}
            // sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="Type" />}
        />
        <SingleLineOfCode placeholder='Transformation [Optional]' value={row?.transformation || ''} onChange={code=>{
            setConfig(prev=>{
                prev.tableMap = forceArr(prev.tableMap);
                // prev.tableMap[index].transformation = code;
                const item = prev.tableMap.find(item=>item.id===row.id);
                if(item) item.transformation = code;
                return copy(prev);
            });
        }}/>
        {/* <Button onClick={()=>{setEditing(false)}}>Close</Button> */}
        <Divider sx={{margin: 2}} variant="middle"/>
        </div>
};

const up = (arr, find) => {
    const index = arr.findIndex(find);
    if (index !== -1 && index > 0) {
        const temp = arr[index];
        arr[index] = arr[index - 1];
        arr[index - 1] = temp;
        return arr;
    }
    return arr;
}
const down = (arr, find) => {
    const index = arr.findIndex(find);
    if (index !== -1 && index < arr.length - 1) {
        const temp = arr[index];
        arr[index] = arr[index + 1];
        arr[index + 1] = temp;
        return arr;
    }
    return arr;
}

const EditOperations = ({config, setConfig, settings, setSettings}) => {
    const [edit, setEdit] = useState(null);
    const [variableName, setVariableName] = useState('');
    const [tab, setTab] = useState(0);

    const handleTabChange = (event, newValue) => {
        setTab(newValue);
    };

    const handleEditChange = (k, v) => {
        setEdit(prev=>{
            prev[k] = v;
            return {...prev};
        });
        setConfig(prev=>{
            prev.operations = forceArr(prev.operations);
            const item = prev.operations.find(item=>item.id===edit.id);
            if(item) item[k] = v;
            return copy(prev);
        });
        setSettings(prev=>{
            prev.operations = forceArr(prev.operations);
            const item = prev.operations.find(item=>item.id===edit.id);
            if(item) item[k] = v;
            return copy(prev);
        });
    }

    if(edit?.id) return <>
        <Fab size='small' onClick={()=>setEdit(null)}><ArrowBigLeft/></Fab>
        <br/>
        <TextField style={{marginTop: 15}} label='Name' value={edit?.name} onChange={e=>handleEditChange('name', e.target.value || '')}/>
        <SingleLineOfCode placeholder='Condition' value={edit?.condition} onChange={code=>handleEditChange('condition', code || '')}/>
        <SingleLineOfCode placeholder='Value' value={edit?.value} onChange={code=>handleEditChange('value', code || '')}/>
        {/* <pre>{JSON.stringify(edit)}</pre> */}
        <br/>
        <TextField style={{marginTop: 15}} label='Header to Change' value={edit?.header || ''} onChange={e=>handleEditChange('header', e.target.value || '')}/>
        <FormGroup>
            <FormControlLabel control={<Checkbox size="small" checked={typeof edit?.addToOperation === 'boolean' ? edit?.addToOperation : true} onChange={e=>handleEditChange('addToOperation', typeof e.target.checked === 'boolean' ? e.target.checked : true)}/>} label='add "Update" to Operation'/>
        </FormGroup>
        <br/>
        <h3 style={{marginTop: 15}}> Available Values: </h3>
        <Autocomplete 
            disablePortal
            options={forceArr(config?.configMap.map(r=>r?.value || '')).concat(forceArr(config?.tableMap).map(r=>r?.value || '')).filter(v=>v!=='' && v && typeof v === 'string')}
            value={variableName}
            getOptionLabel={option=>option}
            onChange={(event, newValue) => {
                if(newValue) setVariableName(newValue)
                else setVariableName('');
            }}
            sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="Values" />}
        />
        <Fab size='small' onClick={()=>{
            navigator.clipboard.writeText(variableName);
        }}><Copy/></Fab>
    </>

    return <>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={tab} onChange={handleTabChange} aria-label="basic tabs example">
                <Tab label="Selected Operations" {...a11yProps(0)} />
                <Tab label="All Operations" {...a11yProps(1)} />
            </Tabs>
        </Box>
        <CustomTabPanel value={tab} index={0}>
            {!Array.isArray(config?.operations) ? null : config.operations.map((operation, i)=>{
                return <div key={`operation-${i}`}>
                    <div style={{display: 'flex', alignItems: 'center', flexDirection: 'row'}}>
                        <h3>{operation?.name || '[No Name]'}</h3>
                        <Fab size='small' style={fabStyle} onClick={()=>setEdit(operation)}><Pencil/></Fab>
                        <Fab size='small' style={fabStyle} onClick={()=>{
                            setConfig(prev=>{
                                prev.operations = forceArr(prev.operations);
                                up(prev.operations, item=>item.id===operation.id);
                                return copy(prev);
                            });
                        }}><ChevronUp/></Fab>
                        <Fab size='small' style={fabStyle} onClick={()=>{
                            setConfig(prev=>{
                                prev.operations = forceArr(prev.operations);
                                down(prev.operations, item=>item.id===operation.id);
                                return copy(prev);
                            });
                        }}><ChevronDown/></Fab>
                        <Fab size='small' style={fabStyle} onClick={()=>{
                            setConfig(prev=>{
                                prev.operations = forceArr(prev.operations);
                                // prev.operations.splice(i, 1);
                                const index = prev.operations.findIndex(item=>item.id===operation.id);
                                if(index !== -1) prev.operations.splice(index, 1);
                                return copy(prev);
                            })
                        }}><Trash/></Fab>
                    </div>
                    <Divider sx={{margin: 2}} variant="middle"/>
                </div>
            })}
        </CustomTabPanel>
        <CustomTabPanel value={tab} index={1}>
            {!Array.isArray(settings?.operations) ? null : settings.operations.filter(op=>{
                const ops = forceArr(config?.operations);
                return !ops.find(op2=>op2.id===op.id);
            }).map((operation, i)=>{
                return <div key={`operation-${i}`}>
                    <div style={{display: 'flex', alignItems: 'center', flexDirection: 'row'}}>
                        <h3>{operation?.name || '[No Name]'}</h3>
                        <Fab size='small' style={fabStyle} onClick={()=>setEdit(operation)}><Pencil/></Fab>
                        <Fab size='small' style={fabStyle} onClick={()=>{
                            setSettings(prev=>{
                                prev.operations = forceArr(prev.operations);
                                const index = prev.operations.findIndex(item=>item.id===operation.id);
                                if(index !== -1) prev.operations.splice(index, 1);
                                return copy(prev);
                            })
                        }}><Trash/></Fab>
                        <Fab size='small' style={fabStyle} onClick={()=>{
                            setConfig(prev=>{
                                prev.operations = forceArr(prev.operations);
                                prev.operations.push(operation);
                                return copy(prev);
                            })
                        }}><MousePointerClick/></Fab>
                    </div>
                    <Divider sx={{margin: 2}} variant="middle"/>
                </div>
            })}
            <Fab style={fabStyle} size="small" onClick={()=>{
                setSettings(prev=>{
                    prev.operations = forceArr(prev.operations);
                    prev.operations.push(objectOperations.multiCreate({condition: '', value: '', name: 'New Operation', header: 'Bid', addToOperation: true}));
                    return copy(prev);
                })
            }}><Plus/></Fab>
        </CustomTabPanel>
    </>
}


const textToVariableName = txt => {
    const allowedChars = '_qwertyuiopasdfghjklzxcvbnm' + 'qwertyuiopasdfghjklzxcvbnm'.toUpperCase();
    return typeof txt !== 'string' ? '' : txt.replace(' ', '_').split('').filter(c=>allowedChars.includes(c)).join('');
};

const EditConfigType = ({setConfig, ...params}) => {
    const [value, setValue] = useState(params.row.type || 'number');

    const { id, value: v, field } = params;

    const apiRef = useGridApiContext();

    const handleChange = useCallback(
        async (e, newValue)=>{
            if(newValue) {
                setConfig(prev=>{
                    prev.configMap = forceArr(prev.configMap);
                    
                    const item = prev.configMap.find(item=>item.id===params.row.id);
                    
                    if(item) {item.type = newValue;}
                    if(params.row) {params.row.type = newValue;}
                    return copy(prev);
                });
                setValue(newValue)

                await apiRef.current.setEditCellValue({ id, field, value: newValue }, e);
                apiRef.current.stopCellEditMode({ id, field });
            }
        },
        [apiRef, field, id],
    );

    // return <TextField autoFocus label='Value' value={value} onChange={handleChange}/>

    return <Autocomplete 
        disablePortal
        options={Object.keys(typeConvert).map(k=>k)}
        value={value || 'number'}
        getOptionLabel={option=>typeConvert[option || 'number']}
        onChange={handleChange}
        // sx={{ width: 300 }}
        renderInput={(params) => <TextField {...params} label="Type" />}
    />
}

const forceNumber = (s, whole) => {
    console.log('forceNumber', whole, s);
    if(s === '') return s;
    if(s === '0') return s;
    try {
        while(s.split('.').length > 2) s = s.replace('.', '');
        const n = parseFloat(s.split('').filter(c=>('123456789' + (whole ? '' : '.')).includes(c)).join(''));
        console.log(n, s.split('').filter(c=>('123456789' + (whole ? '' : '.')).includes(c)).join(''))
        if(isNaN(n)) return '';
        return s;
    } catch(e) {
        console.log(e);
        return '';
    }
};

const forceBool = s => {
    s = `${s}`.toLowerCase();
    if(s.includes('true')) return 'true';
    if(s.includes('false')) return 'false';
    return '';
}

const ConfigEdit = forwardRef(({configId, settings, setSettings}, ref) => {
    const [config, setConfig, commitConfig, loadingConfig] = useLocalAndServerCommitState({
        id: configId,
        name: 'New Configuration',
        template: '',
        templateSheetName: '',
        headersRow: 2,
        usePossibleBid: true,
        useSuggestedBids: true,
        includeAsins: true,
        getEntityId: true,
        configMap: [],
        tableMap: [],
        operations: [],
        lastUpdated: Date.now(),
        updated: Date.now(),
        deleted: false
    }, ...commitMerge(`bid-sheet-config-${configId}`, ['configMap', 'tableMap', 'operations'], true));

    const [checkConfigResult, setCheckConfigResult] = useState(null);

    const [tab, setTab] = useState(0);

    const handleTabChange = (event, newValue) => {
        setTab(newValue);
    };

    useImperativeHandle(ref, () => ({
        save: commitConfig
    }), [commitConfig]);

    const checkConfig = async () => {
        const promise = async () => {
            const r = await fetch('/check-config', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    config,
                    // settings
                })
            });
            const rj = await r.json();
            if(Array.isArray(rj?.result?.missing)) {
                return setCheckConfigResult(rj?.result?.missing);
            }
            throw new Error('Failed to check config');
        }
        toast.promise(promise(), {
            pending: 'Checking Config',
            success: 'Config Checked',
            error: 'Failed to check config'
        });
    }

    if(!configId) return null;

    return <>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={tab} onChange={handleTabChange} aria-label="basic tabs example">
            <Tab label="Config Headers" {...a11yProps(0)} />
            <Tab label="Row Settings" {...a11yProps(1)} />
            <Tab label="Operations" {...a11yProps(2)} />
            </Tabs>
        </Box>
        <CustomTabPanel value={tab} index={0}>
            <button className='btn' onClick={()=>{
                const getCellName = txt => {
                    let [from, to] = [txt, ''];
                    const allowedChars = '_qwertyuiopasdfghjklzxcvbnm' + 'qwertyuiopasdfghjklzxcvbnm'.toUpperCase();
                    txt = txt.replaceAll('\n', ' ').replaceAll('-', ' ').toLowerCase();
                    while(txt.length && !allowedChars.includes(txt[0])) txt = txt.slice(1);
                    while(txt.length && !allowedChars.includes(txt[txt.length-1])) txt = txt.slice(0, -1);
                    while(txt.includes('  ')) txt = txt.replace('  ', ' ');
                    txt = txt.replaceAll(' ', '_').split('').filter(c=>allowedChars.includes(c)).join('').toLowerCase();
                    while(txt.includes('__')) txt = txt.replace('__', '_');
                    to = txt;
                    console.log('from', from, 'to', to);
                    return txt;
                }
                navigator.clipboard.readText().then(text=>{
                    const cells = text.split('\t');
                    setConfig(prev=>{
                        prev.configMap = forceArr(prev.configMap).concat(cells.map(fixCell).map(cell=>objectOperations.multiCreate({header: cell, value: getCellName(cell), type: typeConvert['number'], defaultValue: ''})))
                        console.log('configMap', prev.configMap)
                        return copy(prev);
                    });
                })
                .catch(err => {
                    console.error('Failed to read clipboard contents: ', err);
                    toast.error('Failed to read clipboard contents.');
                });
            }}>Paste Cells</button>
            <Accordion style={{margin: 15}}>
                <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    <Typography component="span">Default Headers</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Table rawColumns table={[
                        {id: 1, header: 'Entity Level', value: 'entity_level', type: 'Text', defaultValue: 'Account'}, 
                        {id: 2, header: 'Entity ID', value: 'entity_id', type: 'Text', defaultValue: '-'}, 
                        {id: 3, header: 'ASIN Threshold', value: 'asin_threshold', type: 'Whole Number', defaultValue: 3}, 
                        {id: 4, header: 'ASIN Exact', value: 'asin_exact', type: 'Checkbox', defaultValue: 'false'}
                    ]} columns={[
                        { field: 'header', headerName: 'Header', },
                        { field: 'value', headerName: 'Value'},
                        { field: 'type', headerName: 'Type'},
                        { field: 'defaultValue', headerName: 'Default Value'},
                    ]}/>
                </AccordionDetails>
            </Accordion>
            {!config?.configMap ? null : <Table
                rawColumns
                columns={[
                    { field: 'header', header: 'Header', editable: true, },//forceNumber
                    { field: 'value', header: 'Value', editable: true, valueParser: params => fixVarString(params.newValue) },
                    { field: 'type', header: 'Type', editable: true, cellEditor: 'agSelectCellEditor', cellEditorParams: { values: Object.values(typeConvert), },},
                    { field: 'defaultValue', header: 'Account Default Value', editable: true, valueParser: params => (params.data.type === typeConvert.string ? `${params.newValue}` : params.data.type === typeConvert.number ? forceNumber(params.newValue, false) : params.data.type === typeConvert.integer ? forceNumber(params.newValue, true) : forceBool(params.newValue)), },
                    // {field: 'defaultValue', headerName: 'Default Value', width: 250, valueGetter: (value, item) => item, renderCell: (params) => <EditField valueKey='defaultValue' setConfig={setConfig} {...params}/>},
                    // { field: 'delete', headerName: 'Delete', width: 150, renderCell: (params) => <Fab size='small' onClick={()=>{
                    //     setConfig(prev=>{
                    //         prev.configMap = forceArr(prev.configMap);
                    //         // prev.configMap.splice(index, 1);
                    //         const index = prev.configMap.findIndex(item=>item.id===params.row.id);
                    //         if(index !== -1) {
                    //             // const newConfigMap = [...prev.configMap];
                    //             // newConfigMap.splice(index, 1);
                    //             // prev.configMap = newConfigMap;
                    //             // prev.configMap.splice(index, 1);
                    //             prev.configMap[index].deleted = true;
                    //         }
                    //         return copy(prev);
                    //     })
                    // }}><X/></Fab> },
                ]}
                table={forceArr(config?.configMap).filter(r=>r.deleted === undefined || !r.deleted).map(r=>{
                    if(!r?.type) r.type = 'number';
                    if(!r?.defaultValue) r.defaultValue = '';
                    return r;
                })}
                onChange={({index, data})=>{
                    setConfig(prev=>{
                        prev.configMap = forceArr(prev.configMap);
                        const index = prev.configMap.findIndex(item=>item.id===data.id);
                        if(index !== -1) prev.configMap[index] = data;
                        return copy(prev);
                    });
                }}
            />}
            
            {/* <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    <Typography component="span">Default Headers</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Paper sx={{ height: 400, width: '100%' }}>
                        <DataGrid rows={[
                            {id: 1, header: 'Entity Level', value: 'entity_level', type: 'Text', defaultValue: 'Account'}, 
                            {id: 2, header: 'Entity ID', value: 'entity_id', type: 'Text', defaultValue: ''}, 
                            {id: 3, header: 'ASIN Threshold', value: 'asin_threshold', type: 'Whole Number', defaultValue: 3}, 
                            {id: 4, header: 'ASIN Exact', value: 'asin_exact', type: 'Checkbox', defaultValue: ''}
                        ]} columns={[
                            { field: 'header', headerName: 'Header', width: 200, valueGetter: (value, item) => (typeof item.header !== 'string' ? '[Not Set]' : JSON.stringify(item.header)), },
                            { field: 'name', headerName: 'Paste Header', width: 150, valueGetter: (value, item) => item, renderCell: (params) => <br/>},
                            { field: 'value', headerName: 'Value Name', width: 200},
                            { field: 'type', headerName: 'Data Type', width: 250},
                            { field: 'delete', headerName: 'Delete', width: 150, renderCell: (params) => <br/>},
                        ]}/>
                    </Paper>
                </AccordionDetails>
            </Accordion> */}
            {/* <Paper sx={{ height: 400, width: '100%' }}>
                <DataGrid rows={forceArr(config?.configMap).filter(r=>r.deleted === undefined || !r.deleted)} columns={[
                    { field: 'header', headerName: 'Header', width: 200, valueGetter: (value, item) => (typeof item.header !== 'string' ? '[Not Set]' : JSON.stringify(item.header)), },
                    { field: 'name', headerName: 'Paste Header', width: 150, valueGetter: (value, item) => item, renderCell: (params) => <Button variant='contained' onClick={()=>{
                        navigator.clipboard.readText().then(text=>{
                            setConfig(prev=>{
                                prev.configMap = forceArr(prev.configMap);
                                // prev.configMap[index].header = text;
                                const item = prev.configMap.find(item=>item.id===params.row.id);
                                if(item) item.header = fixCell(text);
                                return copy(prev);
                            });
                        })
                        .catch(err => {
                            console.error('Failed to read clipboard contents: ', err);
                            toast.error('Failed to read clipboard contents.');
                        });
                    }}>Paste</Button>},
                    { field: 'value', headerName: 'Value Name', width: 200, valueGetter: (value, item) => (item?.value || ''), editable: true, renderEditCell: (params) => <EditField valueKey='value' setConfig={setConfig} {...params}/> },
                    { field: 'type', headerName: 'Data Type', width: 250, valueGetter: (value, item) => item, renderCell: (params) => <EditConfigType setConfig={setConfig} {...params}/>},
                    // {field: 'defaultValue', headerName: 'Default Value', width: 250, valueGetter: (value, item) => item, renderCell: (params) => <EditField valueKey='defaultValue' setConfig={setConfig} {...params}/>},
                    { field: 'delete', headerName: 'Delete', width: 150, renderCell: (params) => <Fab size='small' onClick={()=>{
                        setConfig(prev=>{
                            prev.configMap = forceArr(prev.configMap);
                            // prev.configMap.splice(index, 1);
                            const index = prev.configMap.findIndex(item=>item.id===params.row.id);
                            if(index !== -1) {
                                // const newConfigMap = [...prev.configMap];
                                // newConfigMap.splice(index, 1);
                                // prev.configMap = newConfigMap;
                                // prev.configMap.splice(index, 1);
                                prev.configMap[index].deleted = true;
                            }
                            return copy(prev);
                        })
                    }}><X/></Fab> },
                ]}/>
            </Paper> */}
            {/* <Fab sx={{margin: 5}} size="small" onClick={()=>{
                setConfig(prev=>{
                    prev.configMap = forceArr(prev.configMap);
                    prev.configMap.push(objectOperations.multiCreate({header: null, value: null, type: 'number', defaultValue: ''}));
                    return copy(prev);
                })
            }}><Plus/></Fab>
            <br/>
            <TextField style={{marginTop: 15}} label='Template' value={config?.template || ''} onChange={e=>{
                setConfig(prev=>{
                    prev.template = e.target.value || '';
                    return copy(prev);
                });
            }}/>
            <TextField style={{marginTop: 15}} label='Template' value={config?.templateSheetName || ''} onChange={e=>{
                setConfig(prev=>{
                    prev.templateSheetName = e.target.value || '';
                    return copy(prev);
                });
            }}/>

            <TextField 
                style={{margin: 5, marginTop: 15}}
                label='Row # of Headers' 
                type="number"
                // variant="standard"
                slotProps={{
                    inputLabel: {
                        shrink: true,
                    },
                }}
                value={Math.max(1, typeof config?.headersRow === 'number' ? config?.headersRow : 1)} onChange={e=>{
                setConfig(prev=>{
                    let n;
                    try {
                        n = parseInt(e.target.value);
                    } catch (e) {n = 1;}
                    prev.headersRow = Math.max(1, typeof n !== 'number' ? 1 : n);
                    return copy(prev);
                });
            }}/>
            <button className='btn' onClick={checkConfig}>Check Config</button> */}
            {Array.isArray(checkConfigResult) ? checkConfigResult.length === 0 ? <h3>All Good</h3> : <div>
                <h3>Missing Headers:</h3>
                <ul>
                    {checkConfigResult.map((header, i)=>{
                        return <li key={`missing-header-${i}`}>{JSON.stringify(header)}</li>
                    })}
                </ul>
            </div> : null}
        </CustomTabPanel>
        <CustomTabPanel value={tab} index={1}>
            <FormGroup>
                {/* <FormControlLabel control={<Checkbox size="small" checked={typeof config?.useSuggestedBids === 'boolean' ? config?.useSuggestedBids : true} onChange={e=>setConfig(prev=>({...prev, useSuggestedBids: typeof e.target.checked === 'boolean' ? e.target.checked : true}))}/>} label='Use Suggested Bids'/> */}
                <FormControlLabel control={<Checkbox size="small" checked={typeof config?.usePossibleBid === 'boolean' ? config?.usePossibleBid : true} onChange={e=>setConfig(prev=>({...prev, usePossibleBid: typeof e.target.checked === 'boolean' ? e.target.checked : true}))}/>} label='Calculate Possible Bid'/>
                <FormControlLabel control={<Checkbox size="small" checked={typeof config?.includeAsins === 'boolean' ? config?.includeAsins : true} onChange={e=>setConfig(prev=>({...prev, includeAsins: typeof e.target.checked === 'boolean' ? e.target.checked : true}))}/>} label='Calculate ASINs'/>
                <FormControlLabel control={<Checkbox size="small" checked={typeof config?.getEntityId === 'boolean' ? config?.getEntityId : true} onChange={e=>setConfig(prev=>({...prev, getEntityId: typeof e.target.checked === 'boolean' ? e.target.checked : true}))}/>} label='Entity ID Column'/>
            </FormGroup>
            <Divider sx={{marginBottom: 7, marginTop: 2}} variant='fullWidth'/>
            {!Array.isArray(config?.tableMap) ? null : config.tableMap.map((row, i)=><EditHeader i={i} row={row} setConfig={setConfig}/>)}
            <Fab sx={{margin: 5}} size="small" onClick={()=>{
                setConfig(prev=>{
                    prev.tableMap = forceArr(prev.tableMap);
                    prev.tableMap.push(objectOperations.multiCreate({headers: [], value: '', transformation: '', type: 'number'}));
                    return copy(prev);
                })
            }}><Plus/></Fab>
        </CustomTabPanel>
        <CustomTabPanel value={tab} index={2}>
            <EditOperations config={config} setConfig={setConfig} settings={settings} setSettings={setSettings}/>
        </CustomTabPanel>
        {/* <pre>
            {tryStringify(config)}
        </pre> */}
    </>
})

const BidSheet = ({user, profile, accountName}) => {
    const [sessions, setSessions, commitSessions, loadingSessions] = useLocalAndServerCommitState([], `bid-sheet-sessions-${profile}`, undefined, undefined, true, undefined, 1, (local, server)=>{
        local = forceArr(local);
        server = forceArr(server);
        addLocalItems(local, server)
        return server;  // .filter(item=>!item.deleted);
    });
    const [spreadsheetId, setSpreadsheetId] = useLocalState('', 'bid-sheet-spreadsheet-id');
    const [sheet, setSheet] = useSessionState('', 'bid-sheet-sheet');
    const [sheets, setSheets] = useSessionState([], 'bid-sheet-sheets');

    const [selectedConfig, setSelectedConfig] = useState(null);//, `bid-sheet-selected-config`)

    const [settings, setSettings, commit, loading] = useLocalAndServerCommitState({
        open: false,
        operations: [],
        configurations: [],
    }, ...commitMerge('bid-sheet-settings', ['operations', 'configurations']));

    const configRef = useRef();

    // useEffect(() => {
    //     const t = setTimeout(()=>commitConfig(), 3000);
    //     return ()=>clearTimeout(t);
    // }, [config]);

    
    // useEffect(() => {
    //     const t = setTimeout(()=>commit(), 3000);
    //     return ()=>clearTimeout(t);
    // }, [settings]);
    
    const newConfiguation = () => {
        setSettings(prev=>({
            ...prev, 
            configurations: [
                ...prev.configurations, 
                {id: newID(), name: 'New Configuration', deleted: false, lastUpdated: Date.now()}
            ]
        }));
    }

    const [info, setInfo] = useState({
        url: null,
        open: false,
        sessionId: null,
    });

    const textFieldStyle = {marginBottom: 15}

    const fetchConfig = (forceNew) => {
        fetch(`/bid-sheet-open-adj-sheet`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                profile,
                account_name: accountName,
                force_new_sheet: forceNew
            })
        }).then(res=>res.json()).then(data=>{
            if(data?.url) setInfo(prev=>({...prev, url: data?.url}))
        });
    }

    const openConfigLink = () => {
        window.open(info.url, '_blank');
    }

    const openGoogleSheet = row => {
        if(row?.spreadsheet_url) {
        try {
            window.open(row?.spreadsheet_url, '_blank');
        } catch(e) {
            toast.error('Invalid spreadsheet url');
        }
        } else {
            toast.error('Invalid fetch id');
        }
    }

    useEffect(() => {
        setInfo(prev=>({...prev, url: null}));
        if(!profile) return;
        fetchConfig(false);
    }, [profile]);


    const getSheets = async (sheetUrl, controller) => {
        const promise = async () => {
            try {
                console.log('urrl', sheetUrl)
                // Make the POST request to FastAPI
                const response = await fetch('/google-get-sheets', {
                    method: 'POST',
                    headers: {
                    'Content-Type': 'application/json'
                    },
                    credentials: 'include',
                    body: JSON.stringify({spreadsheet_url: sheetUrl}),
                //   signal: controller?.signal
                });
            
                if (response.ok) {
                    const data = await response.json();
                    console.log('data', data);
                    if(Array.isArray(data?.sheets)) {
                    setSheets(data.sheets);
                    }
                } else {
                    console.error(response.statusText);
                    throw new Error('Fail.');
                    // setUploadStatus("File upload failed.");
                }
            } catch (error) {
                console.error("Error:", error);
                // setUploadStatus("Error uploading file.");
                throw error;
            }
        }
    
        toast.promise(promise(), {
            pending: 'Getting Sheets...',
            success: 'Sheets Fetched',
            error: 'Sheets Fetch Failed'
        });
    };

    const applyBids = async () => {
        const promise = async () => {
            try {
                // Make the POST request to FastAPI
                const response = await fetch('/bid-sheet-insert-bids', {
                    method: 'POST',
                    headers: {
                    'Content-Type': 'application/json'
                    },
                    credentials: 'include',
                    body: JSON.stringify({
                        profile,
                        config_spreadsheet_id: info.url,
                        spreadsheet_url: spreadsheetId,
                        sheet_name: sheet,
                    }),
                //   signal: controller?.signal
                });

                if (response.ok) {
                    const data = await response.json();
                    console.log('data', data);
                    if(Array.isArray(data?.sheets)) {
                    setSheets(data.sheets);
                    }
                } else {
                    console.error(response.statusText);
                    throw new Error('Fail.');
                    // setUploadStatus("File upload failed.");
                }
            } catch (error) {
                console.error("Error:", error);
                // setUploadStatus("Error uploading file.");
                throw error;
            }
        }

        toast.promise(promise(), {
            pending: 'Applying Bids...',
            success: 'Bids Applied',
            error: 'Bids Apply Failed'
        });
    };

    const createSession = async () => {
        // toast('Not yet supported');
        if(!selectedConfig?.id) {
            toast.error('No configuration selected');
            return;
        }
        const configToSend = {configId: selectedConfig?.id, profile};
        const promise = async () => {
            await fetch('/create-bid-sheet-session', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(configToSend)
            }).then(res=>res.json()).then(data=>{
                if(data?.sessionId) {
                    setInfo(prev=>({...prev, sessionId: data?.sessionId}));
                }
            });
        };
        toast.promise(promise(), {
            pending: 'Creating Session...',
            success: 'Session Created',
            error: 'Session Creation Failed'
        });
    }

    const ConfigSelect = ({}) => <Autocomplete 
        disablePortal
        options={settings.configurations}
        value={selectedConfig}
        getOptionLabel={option=>option?.name || ''}
        onChange={(event, newValue) => {
            console.log('new value', newValue);
            if(newValue) setSelectedConfig(newValue)
            else setSelectedConfig(null);
        }}
        sx={{ width: 300 }}
        renderInput={(params) => <TextField {...params} label="Configuration"/>}
    />;

    const ConfigSection = ({}) => (
        <>
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 15}}>
                {
                    !settings.configurations.map(c=>c?.id).includes(selectedConfig?.id) 
                    ? <h2> No Configuration Selected</h2> 
                    : <button className='btn' onClick={()=>{
                        const promise = async () => {
                            await commit();
                            await configRef.current.save();
                        }
                        toast.promise(promise(), {
                            pending: 'Saving...',
                            success: 'Saved',
                            error: 'Save Failed'
                        });
                    }}>
                        Save
                    </button>
                }
            </div>

        {
            !settings.configurations.map(c=>c?.id).includes(selectedConfig?.id) 
            ? null 
            : <>
            <TextField label='Configuration Name' value={selectedConfig?.name || ''} onChange={e=>{
                setSettings(prev=>{
                    prev.configurations = forceArr(prev.configurations);
                    const index = prev.configurations.findIndex(c=>c?.id===selectedConfig?.id);
                    if(index !== -1) prev.configurations[index].name = e.target.value;
                    return copy(prev);
                });
                setSelectedConfig(prev=>({...prev, name: e.target.value}))
            }}/>
            <ConfigEdit ref={configRef} configId={selectedConfig?.id} settings={settings} setSettings={setSettings}/>
            
            </>
        }
        </>
    );

    const Prefix = () => {
        return <div style={{display: 'flex', flexDirection: 'row'}}>
            <h2 style={{marginRight: 15}}> Bid Sheet </h2>
            <LoomToolTip 
                title='In depth use' 
                loomLink='https://www.loom.com/share/3f2d543a548542bca8993cca3aaa9c0f' 
                loomEmbededLink='https://www.loom.com/embed/3f2d543a548542bca8993cca3aaa9c0f?sid=7311eefa-524c-43c9-a692-bea5214f56e6'
            />
            <Fab sx={{margin: 1, alignSelf: 'center'}} size='small' color="primary" aria-label="dark-mode" onClick={()=>{
                // setSettings(prev=>({...prev, open: !prev?.open}))
                setInfo(prev=>({...prev, open: !prev?.open}))
            }}>
                {info?.open ? <X/> : <Settings/>}
            </Fab>
        </div>;
    };

    const SessionSelect = () => <Autocomplete 
        disablePortal
        options={sessions.sort((a, b)=>b.updated - a.updated)}
        value={!info.sessionId ? null : (sessions.find(s=>s?.id===info.sessionId) || null)}
        getOptionLabel={option=>option?.name || ''}
        onChange={(event, newValue) => {
            if(newValue?.id) setInfo(prev=>({...prev, sessionId: newValue.id}))
            else setSelectedConfig(null);
        }}
        sx={{ width: 300 }}
        renderInput={(params) => <TextField {...params} label="Session"/>}
    />;

    if(!profile || !accountName) return <div>
        <h1>No Account Selected</h1>
    </div>

    if(!user?.email) return <div>
        <h1>No User Logged In</h1>
    </div>

    if(typeof info?.sessionId === 'string') return <>
        <Prefix/>
        <BidSheetSession sessionId={info.sessionId} setSessions={setSessions} close={()=>setInfo(prev=>({...prev, sessionId: null}))}/>
    </>;

    return <>
        <Prefix/>
        {
            info?.open 
            ? <ConfigSection/> 
            : <>
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <ConfigSelect/>
                    <Fab sx={{margin: 5}} size="small" onClick={()=>{
                        setSettings(prev=>{
                            prev.configurations = forceArr(prev.configurations);
                            objectOperations.append(prev.configurations, 'name', 'New Configuration');
                            return copy(prev);
                        })
                    }}>
                        <Plus/>
                    </Fab>
                </div>
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <SessionSelect/>
                    {!selectedConfig ? null : <Fab sx={{margin: 5}} size="small" onClick={createSession}><Plus/></Fab>}
                </div>
            </>
        }
    </>;

    return <>
        <Prefix/>
        {/* <pre>{JSON.stringify(user, undefined, 2)}</pre> */}
        {info?.open 
        ? <ConfigSection/> : 
        !info?.url 
            ? <CircularProgress /> 
            : <>
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <ConfigSelect/>
                    <Fab sx={{margin: 5}} size="small" onClick={()=>{
                        setSettings(prev=>{
                            prev.configurations = forceArr(prev.configurations);
                            objectOperations.append(prev.configurations, 'name', 'New Configuration');
                            return copy(prev);
                        })
                    }}><Plus/></Fab>
                </div>
                <button className='btn' onClick={openConfigLink} style={{marginBottom: 25}}>
                    Open Bid Sheet Config
                </button>
                
                <br/>
                <TextField label='Spreadsheet Url' value={spreadsheetId} onChange={e=>setSpreadsheetId(e.target.value || '')} style={textFieldStyle}/>
                <br/>
                <Button onClick={()=>getSheets(spreadsheetId)}>Get Sheets</Button>
                <br/>
                {sheets.length > 0 && <Autocomplete
                    disablePortal
                    options={sheets}
                    value={sheet}
                    onChange={(event, newValue) => {
                        if(newValue) setSheet(newValue)
                        else setSheet('');
                    }}
                    sx={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} label="Sheets" />}
                />}
                <br/>
                <Button style={{marginBottom: 15}} onClick={applyBids}>Insert Bids</Button>
                <br/>
                <QueryTable
                    queryId='bid-sheet-inserted-bids'
                    profile={profile}
                    noDates
                    skip={['spreadsheet_url']}
                    extra={[
                        {
                            field: 'open', headerName: 'Sheet Link', width: 150, 
                            renderCell: params=>(<button className='btn' onClick={()=>openGoogleSheet(params.row)}> Open </button>)
                        }
                    ]}
                />
            </>
        }
    </>
}


export default BidSheet;


