import { useState, useEffect } from 'react';

import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-markup';
import 'prismjs/components/prism-python';
import 'prismjs/themes/prism.css';

import './editor.css';
import { isObject } from '../util';


export const SingleLineOfCode = ({value, onChange, placeholder, style}) => {
    const [focused, SetFocus] = useState(false);
    const [code, setCode] = useState(value || '');

    const handleChange = code => {
        code = forceString(code).replaceAll('\n', ' ');
        setCode(code);
        if (onChange) onChange(code);
    }

    const forceString = v => {
        if (typeof v === 'string') return v;
        return '';
    }
    // https://getcssscan.com/css-box-shadow-examples
    const shadows = [
        'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
        'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px',
        'rgba(0, 0, 0, 0.16) 0px 1px 4px',
        'rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px',
    ]
    return <div style={{boxShadow: focused ? shadows[0] : shadows[2], ...(isObject(style) ? style : {})}}>
        <Editor
            textareaClassName='editor'
            value={code}
            onValueChange={handleChange}
            highlight={code => highlight(code, languages.python)}
            padding={10}
            style={{
                fontFamily: '"Fira code", "Fira Mono", monospace',
                fontSize: 12,
            }}
            placeholder={placeholder}
            onFocus={e => {console.log('focus');SetFocus(true)}}
            onBlur={e => {console.log('off focus');SetFocus(false)}}
        />
    </div>
};

export const CodeDisplay = ({value, style}) => {
    const forceString = v => {
        if (typeof v === 'string') return v;
        return '';
    }
    // https://getcssscan.com/css-box-shadow-examples
    const shadows = [
        'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
        'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px',
        'rgba(0, 0, 0, 0.16) 0px 1px 4px',
        'rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px',
    ]
    return <div style={{boxShadow: shadows[0], ...(isObject(style) ? style : {})}}>
        <Editor
            textareaClassName='editor'
            // disabled
            readOnly
            value={forceString(value)}
            // onValueChange={handleChange}
            highlight={code => highlight(code, languages.python)}
            padding={10}
            style={{
                fontFamily: '"Fira code", "Fira Mono", monospace',
                fontSize: 12,
            }}
        />
    </div>
};

export default SingleLineOfCode;





